import { getUser, hasAuthenticationToken } from "../api/AuthApi";
import { MeetingAction } from "../data/MeetinAction";
import { MeetingStatus } from "../data/MeetingStatus";
import { StatUserCount } from "../data/StatUserCount";
import { User } from "../data/User";

export interface SLinkState {
  user: User | null;
  meetingStatus: MeetingStatus | null;
  statUserCount: StatUserCount | null;
  youTubeVideoId: string;
  socketConnectionId: string;
  myObjection: MeetingAction | null;
  myAskToSpeak: MeetingAction | null;
  myPointOfOrder: MeetingAction | null;
  mySuggestAmendment: MeetingAction | null;
  // possibly not used below here
  role: UserRoles;
  itemStatus: BusinessItemStatus;
  meetingActions: MeetingAction[];
  connectionState: WebSocketState;
}

export enum WebSocketState {
  Disconnected = "Disconnected",
  Connecting = "Connecting",
  Connected = "Connected",
}

export enum BusinessItemStatus {
  None = "None",
  Introduction = "Introduction",
  Discussion = "Discussion",
  ItemVote = "ItemVote",
  PointOfOrder = "PointOfOrder",
  Amendment = "Amendment",
  AmendmentVote = "AmendmentVote",
  MotionToVote = "MotionToVote",
}

export enum UserRoles {
  SuperAdmin = "SuperAdmin",
  Presenter = "Presenter",
  Admin = "Admin",
  OrganisationAdmin = "Organisation Admin",
  Organiser = "Organiser",
  Participant = "Participant",
  Observer = "Observer",
  None = "None",
}

export const initialSlState: SLinkState = {
  // loggedIn: hasAuthenticationToken(),
  user: null,
  socketConnectionId: "",
  role: UserRoles.None,
  itemStatus: BusinessItemStatus.None,
  meetingStatus: null,
  statUserCount: null,
  meetingActions: [],
  connectionState: WebSocketState.Disconnected,
  youTubeVideoId: "",
  myAskToSpeak: null,
  myObjection: null,
  myPointOfOrder: null,
  mySuggestAmendment: null,
};
