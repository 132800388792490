export interface Item {
  id: string;
  subId: string;
  code: string;
  status: string;
  type: string;
  heading: string;
  votes: number;
  voteCounts: VoteCount[];
  text: string;
  originalText: string;
  diffMode: string;
  startTime: string;
  endTime: string;
  voteRequestedTime: string;
  voteClosedTime: string;
  votingHouse: string;
  order: number;
  nextAmendmentId: string;
  previousAmendmentId: string;
  moverUserId: string;
  seconderUserId: string;
}
export interface VoteCount {
  house: string;
  total: number;
  votesFor: number;
  votesAgainst: number;
  votesAbstain: number;
  usersAtTime: number;
}

export enum ItemStatuses {
  New = "New",
  Voting = "Voting",
  Voted = "Voted",
  Accepted = "Accepted",
  Abandoned = "Abandoned",
}

export enum ItemTypes {
  OriginalMotion = "Original Motion",
  MotionAsAmended = "Motion as Amended",
  AmendmentPreview = "Preview Amendment",
  Amendment = "Amendment",
  LegislationAmendment = "Legislation Amendment",
  AcceptLegislationVote = "Accept Legislation Vote",
}
