import React, { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { SLinkContext } from "../state/context";

function LoggedOutLayout(props: any) {
  const { state } = useContext(SLinkContext);

  if (state.user) {
    return <Navigate to="/dashboard" />;
  }

  return (
    <>
      <main className="container-fluid mt-5">
        <Outlet />
        <ToastContainer autoClose={10000} />
      </main>
    </>
  );
}

export default LoggedOutLayout;
