import { ItemTypes } from "../../data/ItemOfBusiness";
import { MeetingStatus } from "../../data/MeetingStatus";
import { markdownDiff } from "../diff/markdownDiff";

export function GetItemMarkdown(meetingStatus: MeetingStatus | null, showPreview: boolean): string {
  let item = meetingStatus?.currentItem;
  //console.log("get item markdown ****");
  if (item != null) {
    // if its a legislation item
    if (meetingStatus?.currentItem?.type === ItemTypes.LegislationAmendment) {
      return markdownDiff(item.originalText, item.text, item.diffMode);
    }
    // if there is a preview show it
    if (meetingStatus?.previewAmendment && showPreview) {
      //console.log("get item markdown 'preview' " + meetingStatus.previewAmendment.text);
      return markdownDiff(item.text, meetingStatus.previewAmendment.text, item.diffMode);
    }
    if (item.type === ItemTypes.OriginalMotion) {
      //console.log("get item markdown 'original motion'");
      return markdownDiff(item.text, item.text);
    }
    // if this is a motion as amended don't show previous version
    if (item.type === ItemTypes.MotionAsAmended) {
      //console.log("get item markdown 'motion as amended'");
      return markdownDiff(item.text, item.text);
    }
    // otherwise show current version and previous version if there is one (which there should be)
    let previous = meetingStatus?.previousAmendmentItem;
    //console.log("get item markdown 'previous' " + previous?.text);
    //console.log("get item markdown 'next'     " + item.text);
    return markdownDiff(previous?.text ?? item.text, item.text, item.diffMode);
  }
  return "";
}
