import React, { Dispatch, SyntheticEvent, useState } from "react";
import PropTypes from "prop-types";
import { User } from "../../data/User";
import TextInput from "../../common/TextInput";
import "./userDetails.css";
import { stringify } from "querystring";
import { updateMyDetails } from "../../api/UserActions";
import { toast } from "react-toastify";
import { SLinkState } from "../../state/state";
import { logoutAction, myUserUpdatedAction, SLinkActions } from "../../state/actions";
import ContainerCard from "../../common/CardContainer";
import { ApiResponse, ApiStatus } from "../../api/ApiUtilities";
import { CheckPassword } from "./passwordFunctions";
import CheckboxInput from "../../common/CheckboxInput";

// UserDetails.propTypes = {
//   user:
// };

interface UserDetailsProps {
  user: User | null;
  dispatch: Dispatch<SLinkActions>;
}

function UserDetails({ user, dispatch }: UserDetailsProps) {
  const [saving, setSaving] = useState(false);
  const [title, setTitle] = useState(user?.title);
  const [preferred, setPreferredName] = useState(user?.preferredName);
  const [firstname, setFirstname] = useState(user?.firstname);
  const [lastname, setLastname] = useState(user?.lastname);
  const [isInPerson, setIsInPerson] = useState(user?.isInPerson ?? false);
  const [parish, setParish] = useState(user?.parish);
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const [errors, setErrors] = useState({} as Errors);
  const [mobilePhone, setMobilePhone] = useState(user?.mobilePhone ?? "");

  if (!user) {
    return <div>Error, no user</div>;
  }

  interface Errors {
    isInPerson?: string;
    title?: string;
    preferred?: string;
    firstname?: string;
    lastname?: string;
    mobilePhone?: string;
    parish?: string;
    password1?: string;
    password2?: string;
    onSave?: string;
  }

  function formIsValid(): boolean {
    const errors = {} as Errors;
    if (!preferred) {
      errors.preferred = "Preferred name is required";
    }
    if (!firstname) {
      errors.firstname = "Firstname is required";
    }
    if (!lastname) {
      errors.firstname = "Lastname is required";
    }
    if (!mobilePhone) {
      errors.mobilePhone = "Mobile phone is required";
    }
    if (password1 && password1.length < 8) {
      errors.password1 = "Password is too short, it must be at least 8 characters.";
    }
    if (password1 && password2 && password1 !== password2) {
      errors.password2 = "Passwords do not match. Try again.";
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  }

  function saveUserDetails(event: SyntheticEvent) {
    console.log("saveUserDetails");
    event.preventDefault();
    if (!formIsValid()) {
      console.log("form not valid " + JSON.stringify(errors));
      return;
    }
    setSaving(true);
    console.log("Updating...");
    var model = {
      ...user,
      title: title,
      preferredName: preferred,
      firstname: firstname,
      lastname: lastname,
      mobilePhone: mobilePhone,
      parish: parish,
      newPassword: password1,
      isInPerson: isInPerson,
    } as User;
    updateMyDetails(model)
      .then((response: ApiResponse<User>) => {
        if (response.status === ApiStatus.Ok && response.data != null) {
          dispatch(myUserUpdatedAction(response.data));
        } else if (response.status === ApiStatus.Unauthorised) {
          dispatch(logoutAction());
        }
        toast.success("Updated successfully");
      })
      .catch((error: Error) => {
        toast.error(`Error updating user details: ${error.message}`);
        setErrors({ ...errors, onSave: error.message });
      })
      .finally(() => {
        setSaving(false);
      });
    setSaving(false);
  }

  return (
    <ContainerCard heading="My Details">
      <div className="px-4">
        <form onSubmit={saveUserDetails}>
          <div className="row mb-2">
            <div className="col">Email / Username</div>
            <div className="col">{user.email}</div>
          </div>
          <div className="row mb-2">
            <div className="col">Roles</div>
            <div className="col">{user.userRoles}</div>
          </div>
          <div className="row mb-2">
            <div className="col">House</div>
            <div className="col">{user.house}</div>
          </div>
          <div className="row mb-2">
            <div className="col">Id</div>
            <div className="col">{user.alternateId}</div>
          </div>
          <div className="row">
            <CheckboxInput
              name="IsInPerson"
              label="Are you present in person for the meeting today?"
              helpText="Please check this box if you are in person to indicate your attendance or not in person"
              checked={isInPerson}
              error={errors.isInPerson}
              onChange={setIsInPerson}
            />
          </div>
          <div className="row">
            <TextInput name="Title" label="Title" type="text" helpText="Please include your title" placeholder="" value={title} error={errors.title} onChange={setTitle} />
          </div>
          <div className="row mb-2">
            <TextInput
              name="PreferredName"
              label="Preferred First Name"
              type="text"
              helpText="Please include your preferred name, this will be shown to other participants"
              placeholder=""
              value={preferred}
              error={errors.preferred}
              onChange={setPreferredName}
            />
          </div>
          <div className="row mb-2">
            <TextInput
              name="Firstname"
              label="Firstname"
              type="text"
              helpText="Please include your full first name"
              placeholder=""
              value={firstname}
              error={errors.firstname}
              onChange={setFirstname}
            />
          </div>
          <div className="row mb-2">
            <TextInput
              name="Lastname"
              label="Lastname"
              type="text"
              helpText="Please include your full last name, this will be shown to other participants"
              placeholder=""
              value={lastname}
              error={errors.lastname}
              onChange={setLastname}
            />
          </div>
          <div className="row mb-2">
            <TextInput
              name="MobilePhone"
              label="Mobile Phone Number"
              type="tel"
              pattern="[+]?[\d]{8,10}"
              helpText="Please supply your mobile phone number in case we need to contact you."
              placeholder="0400123456"
              value={mobilePhone}
              error={errors.mobilePhone}
              onChange={setMobilePhone}
            />
          </div>
          <div className="row mb-2">
            <TextInput name="Parish" label="Parish" type="text" placeholder="" value={parish} error={errors.parish} onChange={setParish} />
          </div>
          <div className="row mb-2">
            <TextInput
              name="New Password"
              label="New Password"
              type="password"
              helpText="Only enter if you want to update your password, otherwise leave blank to leave existing password."
              value={password1}
              error={errors.password1}
              onChange={setPassword1}
            />
          </div>
          <div className="row mb-2">
            <TextInput
              name="Repeat New Password"
              label="Repeat New Password"
              type="password"
              helpText="Only enter if you want to update your password, otherwise leave blank to leave existing password."
              value={password2}
              error={errors.password2}
              onChange={setPassword2}
            />
          </div>
          {errors.onSave && (
            <div className="row">
              <div className="alert alert-danger">{errors.onSave}</div>
            </div>
          )}
          <div className="d-flex my-2">
            <div className="px-2 ms-auto">
              <button type="submit" disabled={saving} className="btn btn-primary mx-2">
                {saving ? "Saving..." : "Save"}
              </button>
            </div>
          </div>
        </form>
      </div>
    </ContainerCard>
  );
}

export default UserDetails;
