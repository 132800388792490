import React from "react";
import { SLinkActions } from "./actions";
import { initialSlState, SLinkState } from "./state";

export const SLinkContext = React.createContext<{
  state: SLinkState;
  dispatch: React.Dispatch<SLinkActions>;
}>({
  state: initialSlState,
  dispatch: () => undefined,
});
