import React from "react";
import PropTypes from "prop-types";

export interface SelectOption {
  value: string;
  text: string;
}
interface SelectInputProps {
  name: string;
  label: string;
  onChange: (value: string) => void;
  defaultOption?: string;
  value: string | undefined;
  error: string | undefined;
  options: SelectOption[];
  //helpText?: string | undefined;
}

const SelectInput = ({ name, label, onChange, defaultOption, value, error, options }: SelectInputProps) => {
  return (
    <div className="form-group">
      <label htmlFor={name}>{label}</label>
      <div className="field">
        {/* Note, value is set here rather than on the option - docs: https://facebook.github.io/react/docs/forms.html */}
        <select name={name} value={value} onChange={(e) => onChange(e.target.value)} className="form-control">
          {defaultOption ? <option value="">{defaultOption}</option> : <></>}
          {options.map((option) => {
            return (
              <option key={option.value} value={option.value}>
                {option.text}
              </option>
            );
          })}
        </select>
        {error && <div className="alert alert-danger">{error}</div>}
      </div>
    </div>
  );
};

SelectInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  defaultOption: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  error: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object),
};

export default SelectInput;
