import React, { SyntheticEvent, useState } from "react";
import { callDeleteUser } from "../../api/UserActions";
import ContainerCard from "../../common/CardContainer";
import CheckboxInput from "../../common/CheckboxInput";
import MultiSelectInput, { MultiSelectOption } from "../../common/MultiSelectInput";
import SelectInput, { SelectOption } from "../../common/SelectInput";
import TextInput from "../../common/TextInput";
import { User } from "../../data/User";
import { UserRoles } from "../../state/state";

export interface UserEditProps {
  user: User;
  onSave: (username: string, preferred: string, firstname: string, lastname: string, phone: string, house: string, parish: string, userRoles: string, enabled: boolean) => Promise<string>;
  onCancel: () => void;
  onDelete: () => Promise<string>;
}

function UserEdit({ user, onSave, onCancel, onDelete }: UserEditProps) {
  const [enabled, setEnabled] = useState(user?.enabled);
  const [preferred, setPreferredName] = useState(user?.preferredName);
  const [firstname, setFirstname] = useState(user.firstname);
  const [lastname, setLastname] = useState(user.lastname);
  const [email, setEmail] = useState(user.email);
  const [phone, setPhone] = useState(user.mobilePhone);
  const [house, setHouse] = useState(user.house);
  const [parish, setParish] = useState(user?.parish);
  const [userRoles, setUserRoles] = useState(user.userRoles);
  const [errors, setErrors] = useState({} as Errors);
  const [saving, setSaving] = useState(false);

  interface Errors {
    enabled?: string;
    preferred?: string;
    firstname?: string;
    lastname?: string;
    email?: string;
    house?: string;
    parish?: string;
    userRoles?: string;
    phone?: string;
    onSave?: string;
  }

  function userHouseList(): SelectOption[] {
    return [
      { value: "", text: "" },
      { value: "Lay", text: "Lay" },
      { value: "Clergy", text: "Clergy" },
      { value: "Bishops", text: "Bishops" },
    ];
  }
  function userRolesList(): MultiSelectOption[] {
    let list = [
      { value: UserRoles.Participant, text: UserRoles.Participant },
      { value: UserRoles.Organiser, text: UserRoles.Organiser },
      { value: UserRoles.Admin, text: UserRoles.Admin },
    ];
    // TODO: check logged in users role here and only add these 2 if a super admin
    list.push({ value: UserRoles.Presenter, text: UserRoles.Presenter });
    list.push({ value: UserRoles.SuperAdmin, text: UserRoles.SuperAdmin });
    return list;
  }

  function cancel(event: SyntheticEvent) {
    setSaving(true);
    event.preventDefault();
    onCancel();
  }

  function deleteUser(event: SyntheticEvent) {
    setSaving(true);
    event?.preventDefault();
    onDelete()
      .then((msg) => {
        if (msg) {
          setErrors({ onSave: msg });
        }
      })
      .finally(() => {
        setSaving(false);
      });
  }

  function save(event: SyntheticEvent) {
    setSaving(true);
    // TODO: add client side validation
    setErrors({});
    event.preventDefault();
    onSave(email, preferred, firstname, lastname, phone, house, parish, userRoles, enabled)
      .then((msg) => {
        setErrors({ onSave: msg });
      })
      .catch((e: Error) => {
        setErrors({ onSave: e.message });
      })
      .finally(() => {
        setSaving(false);
      });
  }

  return (
    <ContainerCard heading={user.userId ? "Edit User" : "New User"}>
      <form style={{"margin":"auto","width" : "96%"}} onSubmit={save}>
        <div className="row mb-2">
          <div className="col">Username</div>
          <div className="col">{user.username}</div>
        </div>
        <div className="row mb-2">
          <div className="col">Id (properties)</div>
          <div className="col">
            {user.alternateId}&nbsp;&nbsp;({user.property1}&nbsp;{user.property2}&nbsp;{user.property3}&nbsp;{user.property4})
          </div>
        </div>
        <br></br>
        <div className="row">
          <CheckboxInput name="Enabled" label="User Enabled" checked={enabled} error={errors.enabled} onChange={setEnabled} />
        </div>
        <div className="row">
          <TextInput name="Prefered" label="Preferred First Name" type="text" placeholder="" value={preferred} error={errors.preferred} onChange={setPreferredName} />
        </div>
        <div className="row">
          <TextInput name="Firstname" label="First Name" type="text" placeholder="" value={firstname} error={errors.firstname} onChange={setFirstname} />
        </div>
        <div className="row">
          <TextInput name="Lastname" label="Last Name" type="text" placeholder="" value={lastname} error={errors.lastname} onChange={setLastname} />
        </div>
        <div className="row">
          <TextInput name="Email" label="Email" type="email" placeholder="" value={email} error={errors.email} onChange={setEmail} />
        </div>
        <div className="row">
          <TextInput name="Phone" label="Mobile Phone number" type="phone" placeholder="" value={phone} error={errors.phone} onChange={setPhone} />
        </div>
        <div className="row">
          <SelectInput name="House" label="House" value={house} defaultOption="" error={errors.house} onChange={setHouse} options={userHouseList()} />
        </div>
        <div className="row">
          <TextInput name="Parish" label="Parish" type="text" placeholder="" value={parish} error={errors.parish} onChange={setParish} />
        </div>
        <div className="row">
          <MultiSelectInput name="UserRoles" label="User Roles" value={userRoles} error={errors.userRoles} onChange={setUserRoles} options={userRolesList()} />
        </div>
        {errors.onSave && (
          <div className="row mt-2 mx-1">
            <div className="alert alert-danger">{errors.onSave}</div>
          </div>
        )}
        <div className="d-flex my-2">
          <div className="px-2 ms-auto">
            <button type="button" disabled={saving || !user.userId} className="btn btn-danger me-5" onClick={deleteUser}>
              Delete User
            </button>
            <button type="button" disabled={saving} className="btn btn-secondary mx-2" onClick={cancel}>
              Cancel
            </button>
            <button type="submit" disabled={saving} className="btn btn-primary mx-2">
              {saving ? "Saving..." : "Save"}
            </button>
          </div>
        </div>
      </form>
    </ContainerCard>
  );
}

export default UserEdit;
