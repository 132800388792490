import React, { useContext, useEffect, useState } from "react";
import { submitVote } from "../../api/MeetingApi";
import { MeetingStatuses } from "../../data/MeetingStatus";
import { SLinkContext } from "../../state/context";
import { toast } from "react-toastify";
import { Button, Modal } from "react-bootstrap";
import { cursorTo } from "readline";
import { Item } from "../../data/ItemOfBusiness";
import { ApiStatus } from "../../api/ApiUtilities";
import { logoutAction } from "../../state/actions";
import { UserHouses } from "../../data/User";

interface VotingButtonsProps {
  item: Item | null;
  meetingStatus: string;
}

function VotingButtons({ item, meetingStatus }: VotingButtonsProps) {
  const { state, dispatch } = useContext(SLinkContext);
  const DefaultStatusText = "Please Vote: ";
  const [submited, setSubmited] = useState(false);
  const [submiting, setSubmiting] = useState(false);
  const [statusText, setStatusText] = useState(DefaultStatusText);
  const [vote, setVote] = useState("");
  const [showVoteConfirmation, setShowVoteConfirmation] = useState(false);
  const [startSeconds, setStartSeconds] = useState(0);
  const [time, setTime] = useState("");
  const [userHasHouse, setUserHasHouse] = useState(state.user?.house !== "");
  const [userIsClergyBishop, setUserIsClergyBishop] = useState(state.user?.house === UserHouses.Clergy || state.user?.house === UserHouses.Bishops);
  const [userIsLay, setUserIsLay] = useState(state.user?.house === UserHouses.Lay);
  const [canVote, setCanVote] = useState(false);

  //console.log("render seconds: " + startSeconds);
  //console.log(`Date ${item?.voteRequestedTime} seconds ${Math.abs(Math.floor((new Date(item?.voteRequestedTime ?? new Date()).getTime() - new Date().getTime()) / 1000))}`);

  useEffect(() => {
    console.warn("Use Effect 1");
    setSubmited(false);
    setSubmiting(false);
    setStatusText(DefaultStatusText);
    setVote("");
    setShowVoteConfirmation(false);
    setTime("");
    setStartSeconds(Math.abs(Math.floor((new Date(item?.voteRequestedTime ?? new Date()).getTime() - new Date().getTime()) / 1000)));
    setCanVote(
      state.user?.house !== "" &&
        (state.meetingStatus?.currentItem?.votingHouse === "" ||
          (state.meetingStatus?.currentItem?.votingHouse === UserHouses.Lay && state.user?.house === UserHouses.Lay) ||
          (state.meetingStatus?.currentItem?.votingHouse === UserHouses.Clergy && (state.user?.house === UserHouses.Clergy || state.user?.house === UserHouses.Bishops)))
    );
  }, [item?.subId, item?.voteRequestedTime, meetingStatus, state.meetingStatus?.currentItem?.votingHouse, state.user?.house]);

  useEffect(() => {
    let id: NodeJS.Timer;
    console.log("count");
    let addSecs = 0;
    id = setInterval(() => {
      var secs = startSeconds + addSecs;
      //console.log("seconds: " + secs);
      addSecs++;
      setTime(Math.floor(secs / 60) + ":" + (secs % 60).toString().padStart(2, "0"));
    }, 1000);
    return () => clearInterval(id);
  }, [meetingStatus, startSeconds]);

  function clickVote(response: string) {
    setVote(response);
    setShowVoteConfirmation(true);
  }
  function cancelVote() {
    setVote("");
    setShowVoteConfirmation(false);
  }
  function confirmVote() {
    setSubmiting(true);
    submitVote(vote)
      .then((response) => {
        if (response.status === ApiStatus.Unauthorised) {
          dispatch(logoutAction());
        }
        if (response.status === ApiStatus.Ok) {
          setSubmited(true);
          toast.success("Vote submitted successfully");
          setStatusText("Voted");
        } else {
          toast.error(`Error submitting vote, please try again (${response.message} - ${response.data})`);
        }
      })
      .catch((error: Error) => {
        toast.error(`Vote was not able to be submitted (${error.message})`);
        setStatusText("Voting failed");
      })
      .finally(() => {
        setSubmiting(false);
        setShowVoteConfirmation(false);
      });
  }

  if (meetingStatus !== MeetingStatuses.Voting) {
    return <></>;
  }

  return (
    <>
      <div className="px-3 py-1 bg-warning sticky-top">
        <div className="d-flex">
          <h5 className="me-auto mt-1 fs-3">
            Vote: {item?.heading} ({item?.type}) {meetingStatus === MeetingStatuses.Voting ? time : ""}
          </h5>
          {canVote ? (
            <h5 className="mt-1 px-2 fs-3">{statusText}</h5>
          ) : (
            <h5 className="me-auto mt-1 fs-3">{userHasHouse ? <>Voting only enabled for {state.meetingStatus?.currentItem?.votingHouse}</> : <>You are unable to vote</>}</h5>
          )}

          {!submited && canVote ? (
            <>
              <div className="mt-1 px-2" title={state.user?.house === "" ? "You are unable to vote." : "Please Vote"}>
                <button type="button" className="btn btn-success" disabled={submiting || state.user?.house === ""} onClick={() => clickVote("For")}>
                  For
                </button>
              </div>
              <div className="mt-1 px-2">
                <button type="button" className="btn btn-danger" disabled={submiting || state.user?.house === ""} onClick={() => clickVote("Against")}>
                  Against
                </button>
              </div>
              <div className="mt-1 px-2">
                <button type="button" className="btn btn-secondary" disabled={submiting || state.user?.house === ""} onClick={() => clickVote("Abstain")}>
                  Abstain
                </button>
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
      {showVoteConfirmation ? (
        <Modal show={showVoteConfirmation} onHide={cancelVote} centered>
          <Modal.Header>
            <Modal.Title>Vote Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>{item?.heading}</p>
            <p>Are you sure you wish to vote {vote}?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={cancelVote} disabled={submiting}>
              Cancel
            </Button>
            <Button variant="primary" onClick={confirmVote} disabled={submiting}>
              {submiting ? "Please wait..." : "Submit " + vote}
            </Button>
          </Modal.Footer>
        </Modal>
      ) : (
        <></>
      )}
    </>
  );
}

export default VotingButtons;
