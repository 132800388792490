import React from "react";
import { MeetingAction } from "../../data/MeetinAction";
import { User } from "../../data/User";

interface UserNameDisplayProps {
  user?: User;
  action?: MeetingAction;
}

function UserNameDisplay({ user, action }: UserNameDisplayProps) {
  if (user) {
    return (
      <>
        {user.house ? user.house[0] + " - " : ""}
        {user.preferredName ? user.preferredName : user.firstname} {user.lastname}
      </>
    );
  }
  if (action) {
    return (
      <>
        {action.house ? action.house[0] + " - " : ""}
        {action.preferredName ? action.preferredName : action.firstname} {action.lastname}
      </>
    );
  }
  return <>Name Missing</>;
}

export default UserNameDisplay;
