export interface MeetingAction {
  id: string;
  subId: string;
  type: string;
  status: string;
  speakingStatus: string;
  obsGuestLink: string;
  preferredName: string;
  firstname: string;
  lastname: string;
  title: string;
  parish: string;
  userId: string;
  house: string;
  forAgainst: string;
  text: string;
}

export enum MeetingActionTypes {
  Objection = "Objection",
  PointOfOrder = "PointOfOrder",
  AskToSpeak = "AskToSpeak",
  SuggestAmendment = "SuggestAmendment",
}

export enum MeetingActionStatuses {
  New = "New",
  Closed = "Closed",
}

export enum MeetingActionSpeakingStatuses {
  NotApplicable = "",
  InQueue = "In Queue",
  InQuestionPool = "In Question Pool",
  Completed = "Completed",
  AbandonedByUser = "Abandoned By User",
  AbandonedByOrganiser = "Abandoned By Organiser",
}
