import React from "react";
import PropTypes from "prop-types";
import { Session } from "../../data/Session";
import { FormatHelper } from "../../api/FormatHelper";

export interface SessionListProps {
  list: Session[];
  onEdit: (session: Session) => void;
}

function SessionList({ list, onEdit }: SessionListProps) {
  return (
    <table className="table">
      <thead>
        <tr>
          <th>ID - Name</th>
          <th>Status</th>
          <th>Enable Participant View/Buttons</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {list.map((session) => {
          return (
            <tr key={session.subId}>
              <td>
                {session.subId} - {session.name}
              </td>
              {/* <td>{FormatHelper.getDateString(session.dateTimeStart)}</td> */}
              <td>{session.status}</td>
              <td>
                {session.enableParticipantView ? "Yes" : "No"}/{session.enableParticipantButtons ? "Yes" : "No"}
              </td>
              <td>
                <button onClick={() => onEdit(session)}>Edit</button>
              </td>
            </tr>
          );
        })}
        {list.length === 0 ? (
          <tr>
            <td colSpan={3}>No Sessions found</td>
          </tr>
        ) : (
          <></>
        )}
      </tbody>
    </table>
  );
}

SessionList.propTypes = {
  list: PropTypes.array.isRequired,
};

export default SessionList;
