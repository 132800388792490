export const keySetIsInPerson = "SetIsInPerson";
export const keyIsInPerson = "IsInPerson";

export function GetHasSessionInPersonValueBeenSet(): boolean {
  return sessionStorage.getItem(keySetIsInPerson) === "true";
}

export function GetSessionInPersonValue(): boolean {
  var setIsInPerson = sessionStorage.getItem(keySetIsInPerson) === "true";
  var isInPerson = sessionStorage.getItem(keyIsInPerson) === "true";
  return setIsInPerson ? isInPerson : false;
}
export function SetSessionInPersonValue(isInPerson: boolean) {
  sessionStorage.setItem(keyIsInPerson, isInPerson ? "true" : "false");
  sessionStorage.setItem(keySetIsInPerson, true ? "true" : "false");
}

export function ClearSession() {
  sessionStorage.clear();
}
