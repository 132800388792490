import { stringify } from "querystring";
import React, { useEffect, useState } from "react";
import { callApi, uploadFile } from "../../api/ApiUtilities";
import { callDeleteUser, getUsers, saveUser } from "../../api/UserActions";
import ContainerCard from "../../common/CardContainer";
import ErrorDisplay from "../../common/ErrorDisplay";
import { User } from "../../data/User";
import { UserRoles } from "../../state/state";
import UserEdit from "./UserEdit";
import UserList from "./UserList";

const UserAdmin = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [users, setUsers] = useState([] as User[]);
  const [user, setUser] = useState(null as User | null);
  const [selectedFile, setSelectedFile] = useState(null as File | null);
  const [fileIsSelected, setFileIsSelected] = useState(false);
  const [importingUsers, setImportingUsers] = useState(false);
  const [reloadUsers, setReloadUsers] = useState(0);

  useEffect(() => {
    getUsers()
      .then((list) => {
        setUsers(list);
      })
      .catch((error: Error) => {
        setError(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [reloadUsers]);

  function onEdit(user: User) {
    setUser(null);
    setUser(user);
  }

  function onNew() {
    setUser({} as User);
  }

  function onCancel() {
    setUser(null);
  }

  async function onSave(email: string, preferred: string, firstname: string, lastname: string, phone: string, house: string, parish: string, userRoles: string, enabled: boolean): Promise<string> {
    if (!user) {
      return "No user to save";
    }
    console.log(`Saving user id: ${user.userId} username: ${email} firstname: ${firstname} lastname: ${lastname} `);
    var userToSave = {
      ...user,
      email: email,
      enabled: enabled,
      preferredName: preferred,
      firstname: firstname,
      lastname: lastname,
      mobilePhone: phone,
      house: house,
      parish: parish,
      userRoles: userRoles,
    } as User;
    try {
      var response = await saveUser(userToSave);
      console.log("Save User Response: " + response.status + " " + response.message);
      if (response.status === 200) {
        var newUser = response.data as User;
        if (newUser) {
          var newList = users.map((item) => {
            return item.userId === userToSave.userId ? newUser : item;
          });
          if (!userToSave.userId && response.data) {
            newList.push(response.data);
          }
          if (newList !== null) {
            setUsers(newList);
          }
        }
        setUser(null);
        return "";
      } else {
        return response.message;
      }
    } catch (error) {
      return error ? JSON.stringify(error) : "An Unknown API error occured.";
    }
  }

  async function deleteUser(): Promise<string> {
    if (!user || !user.userId) {
      return "No user to delete";
    }
    try {
      var r = await callDeleteUser(user);
      if (r.status === 200) {
        var newList = users.filter((u) => u.userId !== user.userId);
        setUser(null);
        setUsers(newList);
        return "";
      }
      return r.message;
    } catch (e: any) {
      let error = e as Error;
      return error.message;
    }
  }

  function downloadUsers() {
    // get user string
    let csvContent = "Action,House,Previous Email,Email,Title,Preferred Name,First Name,Last Name,Connected to,Mobile Phone,Roles,Enabled,AlternateId,Property1,Property2,Property3,Property4\r\n";
    users.forEach((u: User) => {
      if (u) {
        if (u?.userRoles.includes(UserRoles.SuperAdmin) || u?.userRoles.includes(UserRoles.Presenter)) {
          return;
        }
        csvContent += `Update,"${u?.house}","${u?.email}","${u?.email}","${u?.title}","${u?.preferredName}","${u?.firstname}","${u?.lastname}","${u?.parish}","${u?.mobilePhone}","${u?.userRoles}",${
          u?.enabled ? "true" : "false"
        },"${u?.alternateId}","${u?.property1}","${u?.property2}","${u?.property3}","${u?.property4}"\r\n`;
      }
    });
    console.log(csvContent);
    var filename = `${new Date().toISOString()}-Users.csv`;
    var blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    var link = document.createElement("a");
    var url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", filename);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    //var encodedUri = encodeURI(csvOutput);
    // console.log(encodedUri);
    // link.setAttribute("href", encodedUri);
    // link.setAttribute("download", filename);
    // document.body.appendChild(link); // Required for FF
    // link.click(); // This will download the data file named "my_data.csv".
  }

  function setFileChange(event: any) {
    const evTarget = event.target as HTMLInputElement;
    if (evTarget && evTarget.files) {
      const file = evTarget.files[0];
      console.log("setting file");
      setSelectedFile(file);
      setFileIsSelected(true);
    }
  }
  function sleep(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
  async function handleFileUpload() {
    setImportingUsers(true);
    console.log("Importing users...");
    const formData = new FormData();
    formData.append("file", selectedFile as Blob, selectedFile?.name);
    var response = await uploadFile<string>("/api/useradmin/import", "POST", formData);
    console.log("response s " + response.status);
    console.log("response m " + response.message);
    console.log("response d " + response.data);
    setError(response.message + " - " + response.data);
    // call api
    console.log("Imported users");
    // sleep(2000).then(() => {
    setFileIsSelected(false);
    setSelectedFile(null);
    setImportingUsers(false);
    setReloadUsers(reloadUsers + 1);
    //});
  }

  return (
    <>
      {user ? (
        <UserEdit user={user} onSave={onSave} onCancel={onCancel} onDelete={deleteUser} />
      ) : (
        <>
          <ContainerCard heading="User Admin">
            <div className="p-2">
              <ErrorDisplay error={error} />
              <div className="mt-3 mb-2">
                <button onClick={onNew} className="btn btn-secondary">
                  New User
                </button>
              </div>
              <UserList list={users} onEdit={onEdit} />
              <div className="mt-3 mb-2">
                <button onClick={onNew} className="btn btn-secondary">
                  New User
                </button>
              </div>
            </div>
          </ContainerCard>
          <ContainerCard heading="User Import">
            <div className="m-2">
              <h5>Import users</h5>
              <input type="file" name="file" onChange={setFileChange} />
              <button disabled={!fileIsSelected || importingUsers} onClick={handleFileUpload}>
                {importingUsers ? "Importing Users..." : "Import Users"}
              </button>
            </div>
            <div>
              <p>CSV Import file details</p>
              <p>Action,House,Previous Email,Email,Title,Preferred Name,First Name,Last Name,Connected to,Mobile Phone,Roles,Enabled,AlternateId,Property1,Property2,Property3,Property4</p>
              <p>
                Rules:
                <ul>
                  <li>The header row is required.</li>
                  <li>Required fields: Email,Roles</li>
                  <li>Any fields omitted will not be imported</li>
                  <li>Actions can be: new|delete|update|disable</li>
                  <li>Roles: Participant|Organiser|Admin|Presenter</li>
                  <li>You can specifiy multiple roles as a quote enclosed csv string, or pipe separated string value</li>
                  <li>If action is omitted, 'update' is assumed, it will not create new users</li>
                  <li>Users with existing 'SuperAdmin' will not be imported or overwritten. SuperAdmin will also not be set by the import.</li>
                  <li>Enabled defaults to true if field is omitted, must be 'true' or 'enabled', any other value will be considered disabled.</li>
                </ul>
              </p>
            </div>
            <div className="m-2">
              <button onClick={downloadUsers} className="btn btn-secondary">
                Download Users
              </button>
            </div>
          </ContainerCard>
        </>
      )}
    </>
  );
};

export default UserAdmin;
