import React, { SyntheticEvent, useContext, useState } from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import { Link, NavLink, Navigate } from "react-router-dom";
import { SLinkContext } from "../state/context";
import { CallLogout } from "../api/AuthApi";
import userEvent from "@testing-library/user-event";
import { UserRoles, WebSocketState } from "../state/state";
import "./HeaderNavbar.css";
import { Button, Modal } from "react-bootstrap";

const HeaderNavbar = () => {
  const { state, dispatch } = useContext(SLinkContext);
  const [userRoles, setUserRoles] = useState(state.user?.userRoles.split(","));
  const [showLogoutPrompt, setShowLogoutPrompt] = useState(false);

  function isParticipant() {}
  function isOrganiser() {
    if (userRoles) {
      let roles = state.user?.userRoles.split(",");
      if (userRoles.includes(UserRoles.Organiser)) {
        return true;
      }
      if (userRoles.includes(UserRoles.Admin)) {
        return true;
      }
      if (userRoles.includes(UserRoles.SuperAdmin)) {
        return true;
      }
      if (userRoles.includes(UserRoles.Presenter)) {
        return true;
      }
    }
    return false;
  }
  function isSuperAdmin() {
    if (state.user?.userRoles) {
      let roles = state.user.userRoles.split(",");
      if (roles.includes(UserRoles.SuperAdmin)) {
        return true;
      }
    }
    return false;
  }

  if (!state.user) {
    return <Navigate to="/logout" />;
  }

  function logoutClick(event: SyntheticEvent) {
    event.preventDefault();
    setShowLogoutPrompt(true);
  }
  function ConfirmLogout(event: SyntheticEvent) {
    event.preventDefault();
    CallLogout(dispatch);
  }
  function CancelLogout(event: SyntheticEvent) {
    event.preventDefault();
    setShowLogoutPrompt(false);
  }

  return (
    <>
      <Navbar bg="light" expand="md" collapseOnSelect className="border-bottom">
        <Navbar.Brand className="navbar-brand ms-3" href="/">
          <img src="/imgs/ADoM-logo-216-colour.png" width="102" height="58" alt="Anglican Diocese of Melbourne Logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbar" label="Toggle Navigation"></Navbar.Toggle>

        <Navbar.Collapse className="collapse navbar-collapse justify-content-end" id="navbar">
          <Nav className="me-auto">
            <NavLink to="/dashboard" className="nav-link">
              {/* activeClassName="active"  */}
              Home
            </NavLink>
            <NavLink to="/dashboard/my-details" className="nav-link">
              My Details
            </NavLink>
            {userRoles?.includes(UserRoles.Participant) ? (
              <NavLink to="/dashboard/meeting" className="nav-link">
                Meeting
              </NavLink>
            ) : (
              <></>
            )}
            {userRoles?.includes(UserRoles.Organiser) ? (
              <>
                <NavLink to="/dashboard/meetingadmin" className="nav-link">
                  Meeting Control
                </NavLink>
                <NavLink to="/admin/items" className="nav-link">
                  Items of Business
                </NavLink>
              </>
            ) : (
              <></>
            )}
            {userRoles?.includes(UserRoles.Admin) ? (
              <>
                <NavLink to="/admin/users" className="nav-link">
                  User Admin
                </NavLink>
                <NavLink to="/admin/sessions" className="nav-link">
                  Session Admin
                </NavLink>
              </>
            ) : (
              <></>
            )}
            {userRoles?.includes(UserRoles.Presenter) ? (
              <>
                <NavLink to="/admin/video-settings" className="nav-link">
                  Video Settings
                </NavLink>
              </>
            ) : (
              <></>
            )}
            {userRoles?.includes(UserRoles.SuperAdmin) ? (
              <>
                <NavLink to="/admin/system" className="nav-link">
                  System
                </NavLink>
              </>
            ) : (
              <></>
            )}
            <span title={`Realtime connection state: ${state.connectionState}`}>
              {state.connectionState === WebSocketState.Connected ? (
                <img src="/imgs/wifigreen.png" className="m-2" width="25" height="25" alt="Connected icon" title={`Realtime connection state: ${state.connectionState}`} />
              ) : (
                <img src="/imgs/wifired.png" className="m-2" width="25" height="25" alt="Disconnected icon" title={`Realtime connection state: ${state.connectionState}`} />
              )}
              {/* <span className="text-muted">{state.connectionState}</span> */}
            </span>
            <NavLink to="/logout" onClick={logoutClick} className="nav-link">
              Logout
            </NavLink>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      {showLogoutPrompt ? (
        <Modal show={showLogoutPrompt} onHide={() => CancelLogout}>
          {/* // <onHide={CancelLogout}> */}
          <Modal.Header>
            <Modal.Title>Logout</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Are you sure you want to logout?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" className="me-auto" onClick={CancelLogout}>
              Cancel
            </Button>
            <Button variant="primary" className="ms-auto" onClick={ConfirmLogout}>
              Logout
            </Button>
          </Modal.Footer>
        </Modal>
      ) : (
        <></>
      )}
    </>
  );
};

export default HeaderNavbar;
