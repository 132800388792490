import React, { useContext, useEffect, useRef, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import HeaderNavbar from "../common/HeaderNavbar";
import { SLinkContext } from "../state/context";
import { SendSocketMessage, SocketIdentity, SocketMessageTypes } from "../components/signalr/SocketMessage";
import {
  meetingActionClosed,
  meetingActionRaised,
  meetingActionUpdated,
  updateLiveStreamAction,
  updateMeetingStatusAction,
  updateStatUserCountAction,
  updateWebSocketIdentityAction,
  updateWebSocketStateAction,
} from "../state/actions";
import VotingButtons from "../components/meetingview/VotingButtons";
import { WebSocketState } from "../state/state";
import { getCookieValue } from "../api/Utilities";
import LivestreamView from "../components/meetingview/LivestreamView";
import { LiveStreamDetails } from "../data/LiveStreamDetails";
import { StatUserCount } from "../data/StatUserCount";
import { callApi } from "../api/ApiUtilities";
// TODO: remove: import SystemSettings from "../views/SystemSettings";

function LoggedInLayout(props: any) {
  const { state, dispatch } = useContext(SLinkContext);
  //const [socket, setSocket] = useState(null as WebSocket | null);
  const ws = useRef(null as WebSocket | null);
  const [wsDisconects, setWsDisconects] = useState(0);
  const [showState, setShowState] = useState(false);
  const [hostname, setHostname] = useState(window.location.hostname);
  const [wsApi, setWsApi] = useState("");

  function getWsApi() {
    fetch("/api/system/wsapiurl", {
      method: "GET",
    }).then((response) => {
      if (response.status === 200) {
        response.text().then((s) => setWsApi(s));
      } else {
        getWsApi();
      }
    });
  }
  useEffect(() => {
    getWsApi();
  }, []);

  useEffect(() => {
    dispatch(updateWebSocketStateAction(WebSocketState.Connecting));
    if (!wsApi) {
      console.warn("No WS Api URL found");
      return;
    }
    console.info("WS Connecting");
    // TODO: remove hardcoded websocket api URL
    //ws.current = new WebSocket(process.env.REACT_APP_WS_API_URL ?? "");
    ws.current = new WebSocket(wsApi);
    ws.current.onopen = (ev: Event) => {
      dispatch(updateWebSocketStateAction(WebSocketState.Connected));
      console.info("###### WS opened " + JSON.stringify(ev));
      const authId = getCookieValue("SLinkPC");
      var idData = {
        securityToken: authId,
        userId: state.user?.userId,
      } as SocketIdentity;
      var message = {
        message: "sendmessage",
        type: SocketMessageTypes.Identity,
        data: idData,
      } as SendSocketMessage<SocketIdentity>;
      console.log("Sending identity message", message);
      ws.current?.send(JSON.stringify(message));
    };
    ws.current.onclose = (ev: CloseEvent) => {
      dispatch(updateWebSocketStateAction(WebSocketState.Disconnected));
      setWsDisconects(wsDisconects + 1);
      console.error("###### WS closed " + JSON.stringify(ev));
    };
    return () => {
      if (ws.current) {
        ws.current.close();
      }
    };
  }, [wsApi, wsDisconects, dispatch]);

  useEffect(() => {
    if (!wsApi) {
      console.warn("No WS Api URL found 2");
      return;
    }
    console.warn("socket resubscribe null check  ####----####----####----####----####");
    if (ws && ws.current) {
      console.warn("socket resubscribe now ####----####----####----####----####");
      ws.current.onmessage = (e) => {
        try {
          console.warn("web socket message recieved");
          const message = JSON.parse(e.data);
          console.warn(`web socket message ${message.type} : `, message);
          // dispatch relavant message based on the type of the incoming web socket message
          if (message.type === SocketMessageTypes.MeetingStatusUpdate) {
            dispatch(updateMeetingStatusAction(message.data));
          } else if (message.type === SocketMessageTypes.LiveStreamUpdate) {
            dispatch(updateLiveStreamAction(message.data as LiveStreamDetails));
          } else if (message.type === SocketMessageTypes.UserCountUpdate) {
            dispatch(updateStatUserCountAction(message.data as StatUserCount));
          } else if (message.type === SocketMessageTypes.MeetingActionRaised) {
            dispatch(meetingActionRaised(message.data));
          } else if (message.type === SocketMessageTypes.MeetingActionClosed) {
            dispatch(meetingActionClosed(message.data));
          } else if (message.type === SocketMessageTypes.MeetingActionUpdated) {
            dispatch(meetingActionUpdated(message.data));
          } else if (message.type === SocketMessageTypes.SocketIdentityIn) {
            dispatch(updateWebSocketIdentityAction(message.data));
          } else if (message.type === SocketMessageTypes.Pong) {
            console.log("Pong");
          } else {
            console.warn("web socket message of unknown type " + message.type);
          }
        } catch (error) {
          console.error("ERROR: web socket message parsing error : ", error);
        }
      };
    }
  }, [ws, wsDisconects, wsApi]);

  function CallPing() {
    console.log("ws ready state " + ws.current?.readyState);
    if (ws.current?.readyState !== ws.current?.OPEN) {
      return;
    }
    const authId = getCookieValue("SLinkPC");
    var idData = {
      securityToken: authId,
      userId: state.user?.userId,
    } as SocketIdentity;
    var message = {
      message: "pingpong",
      type: SocketMessageTypes.Identity,
      data: idData,
    } as SendSocketMessage<SocketIdentity>;
    console.log("Sending ping message", message);
    ws.current?.send(JSON.stringify(message));
  }
  // ping pong timer effect
  useEffect(() => {
    const id = setInterval(() => {
      CallPing();
    }, 60000);
    return () => clearInterval(id);
  }, [ws, wsDisconects]);

  if (!state.user) {
    return <Navigate to="/login" />;
  }

  return (
    <>
      <HeaderNavbar />
      <VotingButtons item={state.meetingStatus?.currentItem ?? null} meetingStatus={state.meetingStatus?.status ?? ""} />
      <LivestreamView />

      {/* <div>
        <button
          onClick={(e) => {
            e.preventDefault();
            if (ws.current) {
              ws.current.close();
            }
          }}
        >
          Close connection
        </button>
        <button
          onClick={(e) => {
            e.preventDefault();
            setWsDisconects(wsDisconects + 1);
          }}
        >
          Incrment
        </button>
      </div> */}

      <main className="container-fluid py-2">
        <Outlet />
        <ToastContainer autoClose={5000} />
      </main>
      {showState ? (
        <table className="table">
          <tbody>
            <tr>
              <td>Meeting status:</td>
              <td className="text-muted">{JSON.stringify(state.meetingStatus?.status)}</td>
            </tr>
            <tr>
              <td>Previous Item: </td>
              <td className="text-muted">{JSON.stringify(state.meetingStatus?.previousAmendmentItem)}</td>
            </tr>
            <tr>
              <td>Current Item: ({state.meetingStatus?.currentItem?.order})</td>
              <td className="text-muted">{JSON.stringify(state.meetingStatus?.currentItem)}</td>
            </tr>
            <tr>
              <td>Amendment Item: </td>
              <td className="text-muted">{JSON.stringify(state.meetingStatus?.currentAmendment)}</td>
            </tr>
            <tr>
              <td>Preview Item: </td>
              <td className="text-muted">{JSON.stringify(state.meetingStatus?.previewAmendment)}</td>
            </tr>
          </tbody>
        </table>
      ) : (
        <></>
      )}
      {/* <Footer /> */}
    </>
  );
}

export default LoggedInLayout;
