import MDEditor from "@uiw/react-md-editor";
import React, { SyntheticEvent, useState } from "react";
import ContainerCard from "../../common/CardContainer";
import SelectInput, { SelectOption } from "../../common/SelectInput";
import TextInput from "../../common/TextInput";
import { Item, ItemTypes } from "../../data/ItemOfBusiness";
import { markdownDiff } from "../diff/markdownDiff";
import { GetItemMarkdown } from "../meetingcommon/MeetingFunctions";

export interface ItemEditProps {
  item: Item;
  isAdmin: boolean;
  onSave: (heading: string, text: string, code: number, order: number, type: string, originalText: string, diffMode: string, moverUserId: string, seconderUserId: string) => Promise<string>;
  onCancel: () => void;
  onDelete: () => Promise<string>;
}
function ItemEdit({ item, isAdmin, onSave, onCancel, onDelete }: ItemEditProps) {
  const [errors, setErrors] = useState({} as Errors);
  const [saving, setSaving] = useState(false);
  const [heading, setHeading] = useState(item.heading);
  const [text, setText] = useState(item.text);
  const [originalText, setOriginalText] = useState(item.originalText);
  const [diffMode, setDiffMode] = useState(item.diffMode);
  const [code, setCode] = useState(parseFloat(item.code));
  const [order, setOrder] = useState(parseFloat(item.code));
  const [type, setType] = useState(item.type);
  const [moverUserId, setMoverUserId] = useState(item.moverUserId);
  const [seconderUserId, setSeconderUserId] = useState(item.seconderUserId);
  function getNumber(code: string): number {
    return parseFloat(code);
  }
  function itemTypeList(): SelectOption[] {
    return [
      { value: item.type, text: item.type },
      { value: "Original Motion", text: "Original Motion" },
      { value: "Legislation Amendment", text: "Legislation" },
      { value: "Accept Legislation Vote", text: "Final Legislation Vote" },
    ];
  }
  function diffModeList(): SelectOption[] {
    return [
      { value: "Line", text: "Line" },
      { value: "Word", text: "Word" },
      { value: "Sentence", text: "Sentence" },
      { value: "WordWithSpaces", text: "WordWithSpaces" },
      { value: "Line", text: "Line" },
    ];
  }
  function formatText(text: string, setFunction: (text: string) => void) {
    console.log(`Format Text: ${text}`);
    //text.replace(/[\r\n]/g, "  \r\n").replace(/([ ]{2}[\r\n]{2})/g, "\r\n\r\n")
    text = text
      .replace(/[\r]?[\n][\r]?[\n]/g, "||||")
      .replace(/[\r]?[\n]/g, "  \r\n")
      .replace(/[|]{4}/g, "\r\n\r\n");
    setFunction(text);
  }

  console.log("Render Items Edit" + item.id + "-" + item.subId + "-" + item.heading);

  interface Errors {
    heading?: string;
    originalText?: string;
    text?: string;
    onSave?: string;
    code?: string;
    order?: string;
    type?: string;
    moverUserId?: string;
    seconderUserId?: string;
  }

  function cancel(event: SyntheticEvent) {
    setSaving(true);
    event.preventDefault();
    onCancel();
  }

  function deleteItem(event: SyntheticEvent) {
    setSaving(true);
    event.preventDefault();
    onDelete()
      .then((error) => {
        setErrors({ onSave: JSON.stringify(error) });
      })
      .finally(() => {
        setSaving(false);
      });
  }

  function save(event: SyntheticEvent) {
    setSaving(true);
    event.preventDefault();
    onSave(heading, text, code, order, type, originalText, diffMode, moverUserId, seconderUserId)
      .then((error) => {
        setErrors({ onSave: JSON.stringify(error) });
      })
      .finally(() => {
        setSaving(false);
      });
  }

  return (
    <form onSubmit={save}>
      <div className="row">
        <div className="col-8">
          <TextInput name="Heading" label="Heading" type="text" placeholder="" value={heading} error={errors.heading} onChange={setHeading} />
        </div>
        <div className="col-2">
          <SelectInput name="DiffMode" label="Diff Mode" value={diffMode} error={errors.type} onChange={setDiffMode} options={diffModeList()} />
        </div>
        <div className="col-2">
          <SelectInput name="Type" label="Type" value={type} defaultOption="" error={errors.type} onChange={setType} options={itemTypeList()} />
        </div>
      </div>
      {type === ItemTypes.LegislationAmendment ? (
        <div className="row mt-2 p-2">
          <div>
            <MDEditor className="mb-2" value={originalText} onChange={(value) => setOriginalText(value ? value : "")} preview="edit" height={500}></MDEditor>
          </div>
        </div>
      ) : (
        <></>
      )}
      <div className="row mt-2 p-2">
        <div className="text-sm">Note: use 2 spaces at the end of a line to make a line break. Use 2 line breaks (enter) to create a paragraph break.</div>
        <div>
          <MDEditor className="mb-2" value={text} onChange={(value) => setText(value ? value : "")} preview="edit" height={500}></MDEditor>
        </div>
      </div>
      {errors.onSave && (
        <div className="row mt-2 mx-1">
          <div className="alert alert-danger">{errors.onSave}</div>
        </div>
      )}
      <div className="d-flex my-2">
        <div className="px-2 me-auto row">
          <div className="col">
            <TextInput name="Code" label="Code" type="number" placeholder="" value={code.toString()} error={errors.code} onChange={(value) => setCode(getNumber(value))} />
          </div>
          {isAdmin ? (
            <div className="col">
              <TextInput name="Order" label="Order" type="number" placeholder="" value={order.toString()} error={errors.order} onChange={(value) => setOrder(getNumber(value))} />
            </div>
          ) : (
            <></>
          )}
          <div className="col-4">
            <TextInput name="MoverUserId" label="Mover User Id" type="text" placeholder="" value={moverUserId} error={errors.moverUserId} onChange={setMoverUserId} />
          </div>
          <div className="col-4">
            <TextInput name="SeconderUserId" label="Seconder User Id" type="text" placeholder="" value={seconderUserId} error={errors.seconderUserId} onChange={setSeconderUserId} />
          </div>
        </div>
        <div className="px-2 ms-auto">
          <button type="button" disabled={saving} className="btn btn-danger mx-2" onClick={deleteItem}>
            Delete
          </button>
          <button type="button" disabled={saving} className="btn btn-secondary mx-2" onClick={cancel}>
            Cancel
          </button>
          <button type="submit" disabled={saving} className="btn btn-primary mx-2">
            {saving ? "Saving..." : "Save"}
          </button>
        </div>
      </div>
      <div className="d-flex m-2 p-2 border">
        <MDEditor.Markdown source={markdownDiff(originalText ?? text, text, item.diffMode)} />
        {/* <MDEditor.Markdown source={GetItemMarkdown(state.meetingStatus, false)} /> */}
      </div>
    </form>
  );
}
// {showItemView() && <MDEditor.Markdown source={itemOfBusinessText} />}
// {showItemDiffView() && <MDEditor.Markdown source={diffMarkdownText} />}

export default ItemEdit;
