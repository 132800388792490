export interface User {
  userId: string;
  userRoles: string;
  username: string;
  title: string;
  preferredName: string;
  firstname: string;
  lastname: string;
  email: string;
  mobilePhone: string;
  house: string;
  parish: string;
  newPassword: string;
  isInPerson: boolean;
  enabled: boolean;
  alternateId: string;
  property1: string;
  property2: string;
  property3: string;
  property4: string;
}

export enum UserHouses {
  // expected ok/successful response
  Lay = "Lay",
  Clergy = "Clergy",
  Bishops = "Bishops",
}
