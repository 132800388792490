import { Item } from "./ItemOfBusiness";

export interface MeetingStatus {
  id: string;
  subId: string;
  name: string;
  status: string;
  enableParticipantView: boolean;
  enableParticipantButtons: boolean;
  askIsInPerson: boolean;
  previousAmendmentItem: Item | null;
  currentItem: Item | null;
  currentAmendment: Item | null;
  previewAmendment: Item | null;
  lastUpdate: Date | null;
  version: number;
}

export enum MeetingStatuses {
  Setup = "Setup",
  InSession = "In Session",
  Voting = "Voting",
  Completed = "Completed",
}
