import MDEditor from "@uiw/react-md-editor";
import React, { useContext, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import {
  CallAbandonVotedItem,
  CallAcceptVotedItem,
  CallCheckMeetingStatus,
  CallCheckVoteCount,
  CallReopenAmendItem,
  CallReopenSubmotionItem,
  CallRevoteVotedItem,
  cancelVote,
  finaliseVote,
  getMeetingStatus,
  getUserCount,
  previewItemUpdate,
  publishItemUpdate,
  putToVote,
  putToVoteContinue,
  setMeetingNextItem,
  setMeetingPreviousItem,
} from "../../api/MeetingApi";
import ErrorDisplay from "../../common/ErrorDisplay";
import LoadingSpinner from "../../common/LoadingSpinner";
import { Item, ItemStatuses, ItemTypes } from "../../data/ItemOfBusiness";
import { updateMeetingStatusAction, updateStatUserCountAction } from "../../state/actions";
import { SLinkContext } from "../../state/context";
import MeetingItemNavigation from "../meetingview/MeetingItemNavigation";
import MeetingActionList from "./MeetingActionList";
import "../meetingview/diffstyles.css";
import VoteDisplay from "./VoteDisplay";
import BusinessItemCard from "../meetingcommon/BusinessItemCard";
import { GetItemMarkdown } from "../meetingcommon/MeetingFunctions";
import { toast } from "react-toastify";
import { UserRoles } from "../../state/state";
import { Button, Modal } from "react-bootstrap";

function MeetingAdminView() {
  const { state, dispatch } = useContext(SLinkContext);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [editingItem, setEditingItem] = useState(null as Item | null);
  const [editingText, setEditingText] = useState("");
  const [editingSubMotion, setEditingSubMotion] = useState(false);
  const [popupVoteTypeQuestion, setPopupVoteTypeQuestion] = useState(false);

  useEffect(() => {
    getMeetingStatus()
      .then((status) => {
        dispatch(updateMeetingStatusAction(status));
      })
      .catch((error: Error) => {
        setError(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [dispatch]);

  useEffect(() => {
    if (state.statUserCount === null) {
      getUserCount()
        .then((count) => {
          dispatch(updateStatUserCountAction(count));
        })
        .catch((error: Error) => {
          console.log("Error getting StatUserCount " + error.message);
        });
    }
  });

  // refresh state timer
  useEffect(() => {
    function CallRefresh() {
      CallCheckMeetingStatus()
        .then((status) => {
          let isNewVersion = state.meetingStatus ? state.meetingStatus.version < status.version : true;
          dispatch(updateMeetingStatusAction(status));
          console.log(`Meeting status refreshed (v${status.version} - ${status.lastUpdate} new ${isNewVersion})`);
        })
        .catch((error: Error) => {
          console.error(`Error on refreshing state '${error.message}'`);
        });
    }
    function CallRefreshLive() {
      CallCheckVoteCount()
        .then((status) => {
          let isNewVersion = state.meetingStatus ? state.meetingStatus.version < status.version : true;
          dispatch(updateMeetingStatusAction(status));
          console.log(`Meeting status voting refreshed (v${status.version} - ${status.lastUpdate} new ${isNewVersion})`);
        })
        .catch((error: Error) => {
          console.error(`Error on refreshing state '${error.message}'`);
        });
    }
    let id: NodeJS.Timer;
    if (state.meetingStatus?.currentItem?.status === ItemStatuses.Voting) {
      id = setInterval(() => {
        CallRefreshLive();
      }, 5000);
    } else {
      id = setInterval(() => {
        CallRefresh();
      }, 20000);
    }
    return () => clearInterval(id);
  }, [state.meetingStatus, dispatch]);

  function NextItem() {
    setLoading(true);
    setError("");
    setMeetingNextItem()
      .then((status) => {
        dispatch(updateMeetingStatusAction(status));
      })
      .catch((error: Error) => {
        setError(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }
  function PreviousItem() {
    setLoading(true);
    setError("");
    setMeetingPreviousItem()
      .then((status) => {
        dispatch(updateMeetingStatusAction(status));
      })
      .catch((error: Error) => {
        setError(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }
  function EditItem() {
    let item = state.meetingStatus?.currentItem;
    let amendment = state.meetingStatus?.previewAmendment ?? state.meetingStatus?.currentAmendment;
    if (item) {
      setEditingItem(item);
      setEditingText(amendment ? amendment?.text : item.text);
      setEditMode(true);
    }
  }
  function CancelEditItem() {
    setEditingItem(null);
    setEditingSubMotion(false);
    setEditMode(false);
  }
  function PreviewEditItem() {
    setLoading(true);
    setError("");
    var updatingItem = { ...editingItem, text: editingText } as Item;
    previewItemUpdate(updatingItem)
      .then((status) => {
        // do stuff here
        setEditingText("");
        setEditingItem(null);
        setEditMode(false);
        toast.success("Successfully previewed new version");
        dispatch(updateMeetingStatusAction(status));
      })
      .catch((error: Error) => {
        setError(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }
  function PublishEditItem() {
    setLoading(true);
    setError("");
    let item = editMode ? editingItem : state.meetingStatus?.previewAmendment;
    let text = editMode ? editingText : state.meetingStatus?.previewAmendment?.text;
    var updatingItem = { ...item, text: text } as Item;
    publishItemUpdate(updatingItem)
      .then((status) => {
        // do stuff here
        setEditingText("");
        setEditingItem(null);
        setEditMode(false);
        toast.success("Successfully published new version");
        dispatch(updateMeetingStatusAction(status));
      })
      .catch((error: Error) => {
        setError(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }
  function PutToVoteTypePopup() {
    setPopupVoteTypeQuestion(true);
  }
  function CancelVoteTypeQuestion() {
    setPopupVoteTypeQuestion(false);
  }
  function PutToVoteSequential() {
    PutToVote("Sequential");
    setPopupVoteTypeQuestion(false);
  }
  function PutToVoteSynchronous() {
    PutToVote("Synchronous");
    setPopupVoteTypeQuestion(false);
  }
  function PutToVote(type: string) {
    setLoading(true);
    setError("");
    putToVote(type)
      .then((status) => {
        dispatch(updateMeetingStatusAction(status));
      })
      .catch((error: Error) => {
        setError(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }
  function PutToVoteContinue() {
    setLoading(true);
    setError("");
    putToVoteContinue()
      .then((status) => {
        dispatch(updateMeetingStatusAction(status));
      })
      .catch((error: Error) => {
        setError(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }
  function CancelVote() {
    setLoading(true);
    setError("");
    cancelVote()
      .then((status) => {
        dispatch(updateMeetingStatusAction(status));
      })
      .catch((error: Error) => {
        setError(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }
  function FinaliseVote() {
    setLoading(true);
    setError("");
    finaliseVote()
      .then((response) => {})
      .catch((error: Error) => {
        setError(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }
  function AcceptItem() {
    setLoading(true);
    setError("");
    CallAcceptVotedItem()
      .then((response) => {})
      .catch((error: Error) => {
        setError(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }
  function AbandonItem() {
    setLoading(true);
    setError("");
    CallAbandonVotedItem()
      .then((response) => {})
      .catch((error: Error) => {
        setError(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }
  function RevoteItem() {
    setLoading(true);
    setError("");
    CallRevoteVotedItem()
      .then((response) => {})
      .catch((error: Error) => {
        setError(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function ReopenAmendItem() {
    setLoading(true);
    setError("");
    var item = { ...editingItem } as Item;
    CallReopenAmendItem(item)
      .then((response) => {})
      .catch((error: Error) => {
        setError(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }
  function ReopenSubMotionItem() {
    var item = state.meetingStatus?.currentItem;
    if (item) {
      setEditMode(true);
      setEditingText("");
      setEditingItem(item);
      setEditingSubMotion(true);
    }
  }

  function ReopenSubMotionItemConfirm() {
    setLoading(true);
    setError("");
    var item = { ...editingItem, text: editingText } as Item;
    setEditMode(true);
    setEditingText("");
    setEditingItem(item);
    CallReopenSubmotionItem(item)
      .then((response) => {
        setEditingItem(null);
        setEditingSubMotion(false);
        setEditMode(false);
      })
      .catch((error: Error) => {
        setError(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function GetDisplayItem(): Item | null {
    return state.meetingStatus?.currentItem ?? null;
    //return state.meetingStatus?.previewAmendment ?? state.meetingStatus?.currentAmendment ?? state.meetingStatus?.currentItem ?? null;
  }
  function hasPreviewAmendment() {
    return state.meetingStatus?.previewAmendment != null;
  }

  if (!loading && state.meetingStatus === null) {
    return <Navigate to="/dashboard" />;
  }

  return (
    <div>
      <MeetingItemNavigation onNext={NextItem} onPrevious={PreviousItem} loading={loading} state={state} dispatch={dispatch} />
      <div className="row align-items-start">
        <div className="col-md-8 px-1">
          {loading ? <LoadingSpinner /> : <></>}
          <ErrorDisplay error={error} />

          <BusinessItemCard item={GetDisplayItem()}>
            <div className="container mt-2 px-1 pe-1">
              <div className="col">
                {(editMode && !editingSubMotion) || hasPreviewAmendment() ? (
                  <button type="button" className="btn btn-danger mx-2" onClick={PublishEditItem} title="Publish amendment to all attendees">
                    Publish
                  </button>
                ) : (
                  <></>
                )}
                {editMode ? (
                  <>
                    {editingSubMotion ? (
                      <button type="button" className="btn btn-success mx-2" onClick={ReopenSubMotionItemConfirm} title="Create sub motion">
                        Create Sub Motion
                      </button>
                    ) : (
                      <button type="button" className="btn btn-success mx-2" onClick={PreviewEditItem} title="Preview amendment to Organiser users only">
                        Preview
                      </button>
                    )}
                    <button type="button" className="btn btn-warning mx-2" onClick={CancelEditItem} title="Cancel the amendment and go back to the original text">
                      Cancel
                    </button>
                  </>
                ) : (
                  <></>
                )}
                {GetDisplayItem()?.status === ItemStatuses.New &&
                !editMode &&
                GetDisplayItem()?.type !== ItemTypes.LegislationAmendment &&
                GetDisplayItem()?.type !== ItemTypes.AcceptLegislationVote ? (
                  <button type="button" className="btn btn-primary mx-2" onClick={EditItem}>
                    Amendment
                  </button>
                ) : (
                  <></>
                )}
                {state.user?.userRoles.includes(UserRoles.Admin) ? (
                  <>
                    {GetDisplayItem()?.status === ItemStatuses.New && !editMode && !hasPreviewAmendment() ? (
                      <button type="button" className="btn btn-warning mx-2" onClick={PutToVoteTypePopup}>
                        Put to Vote
                      </button>
                    ) : (
                      <></>
                    )}
                    {GetDisplayItem()?.status === ItemStatuses.Voting ? (
                      <>
                        <button type="button" className="btn btn-danger mx-2" onClick={CancelVote}>
                          Cancel Vote
                        </button>
                        <button type="button" className="btn btn-success mx-2" onClick={FinaliseVote}>
                          Finalise Vote
                        </button>
                      </>
                    ) : (
                      <></>
                    )}
                    {GetDisplayItem()?.status === ItemStatuses.Voted ? (
                      <>
                        {state.meetingStatus?.currentItem?.votingHouse === "Clergy" ? (
                          <button type="button" className="btn btn-primary mx-2" onClick={PutToVoteContinue}>
                            Continue Vote {GetDisplayItem()?.type}
                          </button>
                        ) : (
                          <></>
                        )}
                        <button type="button" className="btn btn-success mx-2" onClick={AcceptItem}>
                          Accept {GetDisplayItem()?.type}
                        </button>
                        <button type="button" className="btn btn-danger mx-2" onClick={AbandonItem}>
                          Abandon {GetDisplayItem()?.type}
                        </button>
                        <button type="button" className="btn btn-primary mx-2" onClick={RevoteItem}>
                          Revote {GetDisplayItem()?.type}
                        </button>
                      </>
                    ) : (
                      <></>
                    )}
                    {(GetDisplayItem()?.status === ItemStatuses.Abandoned || GetDisplayItem()?.status === ItemStatuses.Accepted) &&
                    GetDisplayItem()?.nextAmendmentId === "" &&
                    GetDisplayItem()?.type !== ItemTypes.LegislationAmendment &&
                    !editMode ? (
                      <>
                        <button type="button" className="btn btn-dark mx-2" onClick={ReopenAmendItem}>
                          Reopen New Amendment
                        </button>
                        <button type="button" className="btn btn-dark mx-2" onClick={ReopenSubMotionItem}>
                          Reopen Create Sub Motion
                        </button>
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <></>
                )}
                {state.meetingStatus?.currentItem?.status === ItemStatuses.Voting ? (
                  <span className="p-2">
                    Votes received: {state.meetingStatus?.currentItem?.votes}/
                    {(state.statUserCount?.usersBishops ?? 0) + (state.statUserCount?.usersClergy ?? 0) + (state.statUserCount?.usersLay ?? 0)}
                  </span>
                ) : (
                  <></>
                )}
              </div>
              <div className="row">
                {state.meetingStatus?.currentItem?.voteCounts && state.meetingStatus.currentItem.voteCounts.length > 0 && !editMode ? (
                  <div className="col-lg m-2">
                    <VoteDisplay item={GetDisplayItem()} />
                  </div>
                ) : (
                  <></>
                )}
                <div className="col-lg m-2">
                  {editMode ? (
                    <MDEditor className="mb-2" value={editingText} onChange={(value) => setEditingText(value ? value : "")} preview="edit" height={500}></MDEditor>
                  ) : (
                    <MDEditor.Markdown source={GetItemMarkdown(state.meetingStatus, true)} />
                  )}
                </div>
              </div>
            </div>
          </BusinessItemCard>
        </div>
        <div className="col-md-4 px-1">
          <MeetingActionList setError={setError} />
        </div>
      </div>
      <div>&nbsp;</div>
      <div>&nbsp;</div>
      {popupVoteTypeQuestion ? (
        <Modal show={popupVoteTypeQuestion} onBackdropClick={() => CancelVoteTypeQuestion()}>
          <Modal.Header>
            <Modal.Title>Put to Vote</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>What vote type do you want?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={() => PutToVoteSynchronous()}>
              Concurrent (Normal)
            </Button>
            <Button variant="primary" onClick={() => PutToVoteSequential()}>
              Sequential
            </Button>
          </Modal.Footer>
        </Modal>
      ) : (
        <></>
      )}
    </div>
  );
}

export default MeetingAdminView;
