import React from "react";
import PropTypes from "prop-types";

interface IProps {
  message: string;
}

function LoginErrorDisplay({ message }: IProps) {
  if (message) {
    return (
      <>
        <div className="checkbox mb-3" style={{ color: "blue" }}>
          {message}
        </div>
      </>
    );
  } else {
    return <></>;
  }
}

LoginErrorDisplay.propTypes = {
  message: PropTypes.string.isRequired,
};

export default LoginErrorDisplay;
