import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { CallGetVideoSettings, CallSaveVideoSettings } from "../../api/VideoApi";
import ContainerCard from "../../common/CardContainer";
import ErrorDisplay from "../../common/ErrorDisplay";
import LoadingSpinner from "../../common/LoadingSpinner";
import { VideoSettings } from "../../data/VideoSettings";
import VideoSettingsEdit from "./VideoSettingsEdit";

const VideoSettingsAdmin = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [settings, setSettings] = useState(null as VideoSettings | null);

  useEffect(() => {
    CallGetVideoSettings()
      .then((result) => {
        setSettings(result);
      })
      .catch((error) => {
        console.log("Error " + JSON.stringify(error));
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  function onCancel() {
    var data = { ...settings } as VideoSettings;
    setSettings(data);
  }

  async function onSave(liveStreamType: string, youtubeVideoId: string, obsGuestLink: string, obsSessionPassword: string, obsSceneLink: string, obsControlRoomLink: string): Promise<string> {
    if (!settings) {
      return "No settings object to save";
    }
    var settingsToSave = {
      ...settings,
      liveStreamType: liveStreamType,
      youtubeVideoId: youtubeVideoId,
      obsGuestLink: obsGuestLink,
      obsSessionPassword: obsSessionPassword,
      obsSceneLink: obsSceneLink,
      obsControlRoomLink: obsControlRoomLink,
    } as VideoSettings;
    try {
      var returnItem = await CallSaveVideoSettings(settingsToSave);
      console.log("Returned data: " + returnItem);
      setSettings(returnItem);
      toast.success("Successfully saved video settings");
      return "";
    } catch (error) {
      toast.error("Failed to save video settings");
      return error ? JSON.stringify(error) : "An Unknown API error occured.";
    }
  }

  return (
    <>
      <ContainerCard heading="Video Settings">
        <ErrorDisplay error={error} />
        {!loading && settings ? (
          <VideoSettingsEdit settings={settings} onSave={onSave} onCancel={onCancel} />
        ) : (
          <>
            <LoadingSpinner />
          </>
        )}
      </ContainerCard>
    </>
  );
};

export default VideoSettingsAdmin;
