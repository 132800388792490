import React, { useContext } from "react";
import UserDetails from "../components/mydetails/UserDetails";
import { SLinkContext } from "../state/context";

function MyDetailsView(props: any) {
  const { state, dispatch } = useContext(SLinkContext);

  if (!state.user) {
    return <>Loading...</>;
  }

  return (
    <div>
      <UserDetails user={state.user} dispatch={dispatch} />
    </div>
  );
}

export default MyDetailsView;
