import React, { SyntheticEvent, useContext, useEffect, useState } from "react";
//import PropTypes from "prop-types";
import "./LoginView.css";
import { NavLink, Navigate, useLocation } from "react-router-dom";
import { SLinkContext } from "../state/context";
import { login } from "../api/AuthApi";
import LoginError from "../components/login/LoginError";
import { loginAction } from "../state/actions";
import { ApiStatus, callApi } from "../api/ApiUtilities";
import { User } from "../data/User";
import LoginMessage from "../components/login/LoginMessage";

function LoginView() {
  const { state, dispatch } = useContext(SLinkContext);
  const [submiting, setSubmiting] = useState(false);
  const [error, setError] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [videoLink, setVideoLink] = useState("");
  let params = new URLSearchParams(window.location.search);
  let queryStringMsg = params.get("message") ?? "";

  useEffect(() => {
    callApi<string>("/api/auth/videolink", "GET").then((response) => {
      if (response.data) {
        setVideoLink(response.data);
      }
    });
  }, []);

  async function onSubmit(event: SyntheticEvent) {
    setSubmiting(true);
    event.preventDefault();
    setError("");

    var response = await callApi<User>("/api/auth", "POST", { username: username, password: password });
    if (response.status === ApiStatus.Ok) {
      dispatch(loginAction(response.data as User));
    } else {
      setSubmiting(false);
      setError(response.message);
    }
  }

  if (state.user) {
    return <Navigate to="/dashboard" />;
  }

  return (
    <div className="container pt-3">
      <main className="form-signin mx-auto" style={{ maxWidth: "340px" }}>
        <form onSubmit={onSubmit}>
          <div className="d-flex">
            <img className="mb-4 mx-auto" src="/imgs/ADoM-logo-216-colour.png" width="102" height="58" alt="Anglican Diocese of Melbourne Logo" />
          </div>

          <LoginMessage message={queryStringMsg} />

          <div className="form-floating">
            <input type="email" className="form-control" id="floatingInput" placeholder="name@example.com" value={username} onChange={(e) => setUsername(e.target.value)} />
            <label htmlFor="floatingInput">Email address</label>
          </div>
          <div className="form-floating">
            <input type="password" className="form-control" id="floatingPassword" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
            <label htmlFor="floatingPassword">Password</label>
          </div>

          <LoginError error={error} />

          <div className="checkbox mb-3">
            {/* <label>
            <input type="checkbox" value="remember-me" /> Remember me
          </label> */}
          </div>
          <button disabled={submiting} className="w-100 btn btn-lg btn-primary" type="submit">
            {submiting ? "Signing in..." : "Sign in"}
          </button>
          <p className="mt-2 mb-2 text-muted">
            <NavLink to="/login/forgot">Forgot password</NavLink>
          </p>
        </form>

        {videoLink ? (
          <div className="mt-5">
            Lay and Clergy Representatives registered to attend online for medical/disability reasons must log in above to participate in the meeting. All other interested parties can view the livestream{" "}
            <a href={videoLink}>here</a>.
          {/*
          <div className="mt-5">
            Lay and Clergy Representatives and registered Observers must log in above to participate in the meeting. Visitors and other interested parties can view the livestream{" "}
            <a href={videoLink}>here</a>.
          */}
          </div>
        ) : (
          <div className="mt-5">During a meeting of synod a link to the livestream will be available here for visitors and other interested parties.</div>
        )}
      </main>
    </div>
  );
}

export default LoginView;
