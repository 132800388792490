import { MeetingStatus } from "../data/MeetingStatus";
import { ApiResponse, ApiStatus, callApi } from "./ApiUtilities";

// TODO: remove hardcoded api value
let statusApiHost = "https://localhost:44353";
statusApiHost = "";

export async function getStatusMeetingStatus(): Promise<MeetingStatus> {
  const response = await callApi<MeetingStatus>(`${statusApiHost}/statusapi/meetingstatus`, "GET");
  console.log(`Get Sessions response ${response.status}`);
  return checkResponse<MeetingStatus>(response);
}

// TODO: make this function more generic and move to ApiUtilities
export function checkResponse<Type>(response: ApiResponse<Type>): Type {
  if (response.status === ApiStatus.Ok && response.data != null && response.data) {
    return response.data as Type;
  } else if (response.status === ApiStatus.Unauthorised) {
    throw Error(`Not logged in, try refreshing page (${response.message})`);
  } else if (response.status === ApiStatus.Forbidden) {
    throw Error(`Permission denied (${response.message})`);
  } else if (response.status === ApiStatus.BadRequest) {
    throw Error(response.message);
  } else if (response.status === ApiStatus.NotFound) {
    // MIGHT NEED THIS BACK: || !response.data) {
    throw Error("There is no active meeting, please check back later");
  } else {
    throw Error("An Unknown API error occured. Response " + response.status + " - " + response.message);
  }
}
