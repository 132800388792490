import React from "react";
import { MeetingActionSpeakingStatuses } from "../../data/MeetinAction";

interface ObsInstructionsProps {
  speakingStatus: string;
}

function ObsInstructions({ speakingStatus }: ObsInstructionsProps) {
  if (speakingStatus === MeetingActionSpeakingStatuses.InQueue) {
    return (
      <>
        <p>
          You are now in the <span className="text-primary fw-bold">waiting room</span>.
        </p>
        <p>
          When you've reached the top five in the queue, you will be automatically transferred to a video conference in your browser. There will be a brief interruption during this. After you have
          said your piece, you will be returned to watching the live stream automatically, though the technician may keep you there for a time in case the meeting chair asks you to clarify anything.
        </p>
        <p>When video connection is initiated ensure you click “allow” on the camera and audio prompts in your browser.</p>
        <p>Ensure your volume is up. The technician may speak to you before you are live.</p>
      </>
    );
  }
  if (speakingStatus === MeetingActionSpeakingStatuses.InQuestionPool) {
    return (
      <>
        <p>
          You are now in the <span className="text-danger fw-bold">question pool</span>.
        </p>
        <p>While your image is only in the smaller preview, you are NOT live.</p>
        <p>The chair knows you’re there and will introduce you, and you will be live from that point.</p>
        <p>After you have spoken, the operator will automatically remove you from the question pool.</p>
      </>
    );
  }
  if (speakingStatus === MeetingActionSpeakingStatuses.Completed) {
    return <p>Thankyou for participating.</p>;
  }
  return <></>;
}

export default ObsInstructions;
