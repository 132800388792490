import React from "react";
import PropTypes from "prop-types";

export interface MultiSelectOption {
  value: string;
  text: string;
}
interface MultiSelectInputProps {
  name: string;
  label: string;
  onChange: (value: string) => void;
  value: string;
  error: string | undefined;
  options: MultiSelectOption[];
  //helpText?: string | undefined;
}

const MultiSelectInput = ({ name, label, onChange, value, error, options }: MultiSelectInputProps) => {
  function handleChange(valueChanged: string, checked: boolean) {
    var existingVal = value ?? "";
    var list = existingVal.split(",");
    if (checked) {
      list.push(valueChanged);
    } else {
      list = list.filter((i) => i !== valueChanged && i !== "");
    }
    list = list.filter((i) => i !== "");
    onChange(list.join(","));
  }

  function containsValue(val: string): boolean {
    var existingVal = value ?? "";
    var list = existingVal.split(",");
    return list.includes(val);
  }

  return (
    <div className="form-group">
      <label htmlFor={name}>{label}</label>
      <div className="field">
        {options.map((item) => (
          <span key={item.value}>
            <input
              type="checkbox"
              value={item.value}
              name={item.value}
              onChange={(e) => {
                handleChange(e.target.value, e.target.checked);
              }}
              checked={containsValue(item.value)}
            />{" "}
            <label htmlFor={item.value}>{item.text}</label>
            <br />
          </span>
        ))}
        {error && <div className="alert alert-danger">{error}</div>}
      </div>
    </div>
  );
};

MultiSelectInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  error: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object),
};

export default MultiSelectInput;
