export const FormatHelper = {
  getDateFromFieldValue: (dateStr: string, original: string): string => {
    if (original && original.length >= 10) {
      return `${dateStr}${original.substr(10)}`;
    }
    return dateStr;
  },
  getDateFieldValue: (dateStr: string): string => {
    if (dateStr && dateStr.length >= 10) {
      return dateStr.substr(0, 10);
    }
    return "";
  },
  getDateNow: (): string => {
    // const d = new Date()
    // return d.getUTCDate();
    return new Date().toISOString();
  },
  getDateString: (dateStr: string): string => {
    //const options = { year: "numeric", month: "numeric", day: "numeric" };
    var date = new Date(dateStr);
    return date.toLocaleDateString("en-AU", { year: "numeric", month: "numeric", day: "numeric" });
  },
  /* 
  getDateTimeString: (dateStr: string): string => {
    //const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
    var date = new Date(dateStr);
    return date.toLocaleDateString(undefined, { year: "numeric", month: "numeric", day: "numeric" }) + " " + date.toLocaleTimeString();
  },
  getUtcNow: () => {
    // const d = new Date()
    // return d.getUTCDate();
    return Date.now();
  },
  getCurrencyString: (amount: number): string => {
    var dollars = amount / 100;
    return dollars.toLocaleString("en-AU", { style: "currency", currency: "AUD" });
  },/* */
};
