import React, { SyntheticEvent, useContext, useEffect, useState } from "react";
import { NavLink, Navigate, useLocation } from "react-router-dom";
import { ApiResponse, callApi } from "../api/ApiUtilities";
import LoginError from "../components/login/LoginError";
import { SLinkContext } from "../state/context";

function ResetPasswordView() {
  const { state, dispatch } = useContext(SLinkContext);
  const [submiting, setSubmiting] = useState(false);
  const [error, setError] = useState("");
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const [redirect, setRedirect] = useState(false);
  const [redirectInvalid, setRedirectInvalid] = useState(false);
  const [loading, setLoading] = useState(true);
  const [tokenValid, setTokenValid] = useState(false);
  let location = useLocation();
  console.log("location: " + location.pathname);

  useEffect(() => {
    const params = location.pathname.split("/");
    var token1 = params[3];
    var token2 = params[4];

    callApi<boolean>("/api/auth/checktoken", "POST", { token1: token1, token2: token2 })
      .catch((error: Error) => {
        setError(`Error checking token is valid, please refresh your screen and try again (${error.message})`);
      })
      .then((res: ApiResponse<boolean> | void) => {
        console.log("Check token response: " + JSON.stringify(res));
        if (res && res.data) {
          setLoading(false);
          setTokenValid(true);
        } else {
          setError(`Password reset token is invalid.`);
          setLoading(false);
          setRedirectInvalid(true);
        }
      });
  });

  function onSubmit(event: SyntheticEvent) {
    setSubmiting(true);
    event.preventDefault();
    setError("");
    if (password1 && password1.length < 8) {
      setError("Password is too short, it must be at least 8 characters long");
      setSubmiting(false);
      return;
    }
    if (password1 !== password2) {
      setError("Passwords dont match. Please try again.");
      setSubmiting(false);
      return;
    }
    const params = location.pathname.split("/");
    var token1 = params[3];
    var token2 = params[4];

    callApi<boolean>("/api/auth/reset", "POST", { token1: token1, token2: token2, newPassword: password1 })
      .catch((error: Error) => {
        setError(`Error setting your new password, refresh your screen and try again (${error.message})`);
      })
      .then((res: ApiResponse<boolean> | void) => {
        if (res && res.data) {
          setError("Success! You can now log in with your new password.");
          setRedirect(true);
        } else {
          setError("Password was not able to be reset");
        }
        setSubmiting(false);
      });
  }

  if (redirect) {
    return <Navigate to={encodeURI("/login?message=Success! You can now log in with your new password.")} />;
  }
  if (redirectInvalid) {
    return <Navigate to={encodeURI("/login?message=You have already created or reset your password, login below.")} />;
  }

  return (
    <div className="container pt-3">
      <main className="form-signin mx-auto" style={{ maxWidth: "340px" }}>
        {loading ? (
          <div>
            <div className="d-flex">
              <img className="mb-4 mx-auto" src="/imgs/ADoM-logo-216-colour.png" width="102" height="58" alt="Anglican Diocese of Melbourne Logo" />
            </div>
            <div className="checkbox mb-3">Loading...</div>

            <LoginError error={error} />
          </div>
        ) : (
          <form onSubmit={onSubmit}>
            <div className="d-flex">
              <img className="mb-4 mx-auto" src="/imgs/ADoM-logo-216-colour.png" width="102" height="58" alt="Anglican Diocese of Melbourne Logo" />
            </div>

            <div className="checkbox mb-3">Type your password twice to set or reset your password. Then you will be able to login with your username and newly set password.</div>
            <div className="form-floating">
              <input type="password" className="form-control" id="floatingPassword" placeholder="Password" value={password1} onChange={(e) => setPassword1(e.target.value)} />
              <label htmlFor="floatingPassword">Password</label>
            </div>
            <div className="form-floating">
              <input type="password" className="form-control" id="floatingPassword" placeholder="Password" value={password2} onChange={(e) => setPassword2(e.target.value)} />
              <label htmlFor="floatingPassword">Repeat Password</label>
            </div>

            <LoginError error={error} />

            <div className="checkbox mb-3">
              {/* <label>
            <input type="checkbox" value="remember-me" /> Remember me
          </label> */}
            </div>
            <button disabled={submiting} className="w-100 btn btn-lg btn-primary" type="submit">
              {submiting ? "Please wait..." : "Set new password"}
            </button>
            <p className="mt-2 mb-2 text-muted">
              <NavLink to="/login">Login</NavLink>
            </p>
          </form>
        )}
      </main>
    </div>
  );
}

export default ResetPasswordView;
