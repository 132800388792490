import React, { useEffect, useState } from "react";
import { FormatHelper } from "../../api/FormatHelper";
import { getSessions, saveSession } from "../../api/SessionApi";
import ContainerCard from "../../common/CardContainer";
import ErrorDisplay from "../../common/ErrorDisplay";
import { Session, SessionStatuses } from "../../data/Session";
import SessionEdit from "./SessionEdit";
import SessionList from "./SessionList";

const SessionAdmin = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [sessions, setSessions] = useState([] as Session[]);
  const [session, setSession] = useState(null as Session | null);

  useEffect(() => {
    getSessions()
      .then((list) => {
        setSessions(list);
      })
      .catch((error) => {
        console.log("Error " + JSON.stringify(error));
        //setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  function onEdit(session: Session) {
    setSession(session);
  }

  function onNew() {
    setSession({
      name: "New Session",
      dateTimeStart: FormatHelper.getDateNow(),
      dateTimeEnd: FormatHelper.getDateNow(),
      id: "",
      subId: "",
      type: "new",
      status: SessionStatuses.Setup,
      enableParticipantView: false,
      enableParticipantButtons: true,
      askIsInPerson: false,
      currentItemId: "",
    });
  }

  async function onSave(name: string, status: string, enableParticipantView: boolean, enableParticipantButtons: boolean, askIsInPerson: boolean, dateStart: string, dateEnd: string): Promise<string> {
    if (!session) {
      return "No session to save";
    }
    console.log(`Saving session id: ${session.id} name: ${name} status: ${status} dates: ${dateStart} to: ${dateEnd} askIsInPerson: ${askIsInPerson}`);
    var sessionToSave = {
      ...session,
      name: name,
      status: status,
      enableParticipantView: enableParticipantView,
      enableParticipantButtons: enableParticipantButtons,
      askIsInPerson: askIsInPerson,
      dateTimeStart: dateStart,
      dateTimeEnd: dateEnd,
    } as Session;
    try {
      var returnItem = await saveSession(sessionToSave);
      console.log("Returned data: " + returnItem);
      var newList = sessions.map((item) => {
        return item.subId === returnItem.subId ? returnItem : item;
      });
      if (session.id === "") {
        newList.push(returnItem);
      }
      setSession(null);
      setSessions(newList);
      return "";
    } catch (e: any) {
      let error = e as Error;
      if (error) {
        return error.message;
      }
      return "An unknown error occured";
    }
  }

  function onCancel(): void {
    setSession(null);
  }

  return (
    <>
      <ContainerCard heading="Session Admin">
        <ErrorDisplay error={error} />
        <SessionList list={sessions} onEdit={onEdit} />
        <div className="mt-3 mb-2">
          <button onClick={onNew} className="btn btn-secondary">
            New Session
          </button>
        </div>
      </ContainerCard>
      {session ? <SessionEdit session={session} onSave={onSave} onCancel={onCancel} /> : <></>}
    </>
  );
};

export default SessionAdmin;
