import React, { SyntheticEvent, useContext, useState } from "react";
import { NavLink, Navigate } from "react-router-dom";
import { ApiResponse, ApiStatus, callApi } from "../api/ApiUtilities";
import LoginError from "../components/login/LoginError";
import { SLinkContext } from "../state/context";

function ForgotView() {
  const { state, dispatch } = useContext(SLinkContext);
  const [submiting, setSubmiting] = useState(false);
  const [error, setError] = useState("");
  const [username, setUsername] = useState("");

  function onSubmit(event: SyntheticEvent) {
    setSubmiting(true);
    event.preventDefault();
    setError("");

    callApi<string>("/api/auth/forgot", "POST", { username: username })
      .catch((error: Error) => {
        setError(`Error requeting forgot password, refresh your screen and try again (${error.message}`);
      })
      .then((res: ApiResponse<string> | void) => {
        // do stuff
        //dispatch(loginAction(response.data as User));
        if (res) {
          setError(res.message);
        }
        setSubmiting(false);
      });
  }

  if (state.user) {
    return <Navigate to="/dashboard" />;
  }

  return (
    <div className="container pt-3">
      <main className="form-signin mx-auto" style={{ maxWidth: "340px" }}>
        <form onSubmit={onSubmit}>
          <div className="d-flex">
            <img className="mb-4 mx-auto" src="/imgs/ADoM-logo-216-colour.png" width="102" height="58" alt="Anglican Diocese of Melbourne Logo" />
          </div>

          <div className="form-floating">
            <input type="email" className="form-control" id="floatingInput" placeholder="name@example.com" value={username} onChange={(e) => setUsername(e.target.value)} />
            <label htmlFor="floatingInput">Email address</label>
          </div>

          <LoginError error={error} />

          <div className="checkbox mb-3">
            {/* <label>
            <input type="checkbox" value="remember-me" /> Remember me
          </label> */}
          </div>
          <button disabled={submiting} className="w-100 btn btn-lg btn-primary" type="submit">
            {submiting ? "Please wait..." : "Recover password"}
          </button>
          <p className="mt-2 mb-2 text-muted">
            <NavLink to="/login">Login</NavLink>
          </p>
        </form>
      </main>
    </div>
  );
}

export default ForgotView;
