import React, { SyntheticEvent, useState } from "react";
import ContainerCard from "../../common/CardContainer";
import SelectInput, { SelectOption } from "../../common/SelectInput";
import TextInput from "../../common/TextInput";
import { VideoSettings } from "../../data/VideoSettings";

export interface VideoSettingsEditProps {
  settings: VideoSettings;
  onSave: (liveStreamType: string, youtubeVideoId: string, obsGuestLink: string, obsSessionPassword: string, obsSceneLink: string, obsControlRoomLink: string) => Promise<string>;
  onCancel: () => void;
}

function VideoSettingsEdit({ settings, onSave, onCancel }: VideoSettingsEditProps) {
  const [liveStreamType, setLiveStreamType] = useState(settings.liveStreamType);
  const [youtubeVideoId, setYoutubeVideoId] = useState(settings.youtubeVideoId);
  const [obsGuestLink, setObsGuestLink] = useState(settings.obsGuestLink);
  const [obsSessionPassword, setObsSessionPassword] = useState(settings.obsSessionPassword);
  const [obsSceneLink, setObsSceneLink] = useState(settings.obsSceneLink);
  const [obsControlRoomLink, setObsControlRoomLink] = useState(settings.obsControlRoomLink);
  const [errors, setErrors] = useState({} as Errors);
  const [saving, setSaving] = useState(false);

  interface Errors {
    liveStreamType?: string;
    youtubeVideoId?: string;
    obsGuestLink?: string;
    obsSessionPassword?: string;
    obsSceneLink?: string;
    obsControlRoomLink?: string;
    onSave?: string;
  }

  function LiveStreamTypes(): SelectOption[] {
    return [
      { value: "Disabled", text: "Disabled" },
      { value: "YouTube", text: "YouTube" },
    ];
  }

  function cancel(event: SyntheticEvent) {
    setSaving(true);
    event.preventDefault();
    onCancel();
    setSaving(false);
  }

  function replaceStringsClientSide(link: string): string {
    var newlink = link.replace("[password]", obsSessionPassword);
    newlink = newlink.replace("[userlabel]", "L - Surname, Firstname");
    return newlink;
  }

  function save(event: SyntheticEvent) {
    setSaving(true);
    // TODO: add client side validation
    setErrors({});
    event.preventDefault();
    onSave(liveStreamType, youtubeVideoId, obsGuestLink, obsSessionPassword, obsSceneLink, obsControlRoomLink)
      .catch((error) => {
        setErrors({ onSave: JSON.stringify(error) });
        // .then((error) => {
        //   setErrors({ onSave: JSON.stringify(error) });
      })
      .finally(() => {
        setSaving(false);
      });
  }

  return (
    <div className="px-4">
      <form onSubmit={save}>
        <div className="row">
          <SelectInput name="liveStreamType" label="Live Stream Type" value={liveStreamType} defaultOption="" error={errors.liveStreamType} onChange={setLiveStreamType} options={LiveStreamTypes()} />
        </div>
        <div className="row">
          <TextInput name="youtubeVideoId" label="YouTube Video Id" type="text" placeholder="video id" value={youtubeVideoId} error={errors.youtubeVideoId} onChange={setYoutubeVideoId} />
        </div>
        <div className="row">
          <TextInput name="obsGuestLink" label="OBS Ninja Guest Link" type="url" placeholder="https://link-here" value={obsGuestLink} error={errors.obsGuestLink} onChange={setObsGuestLink} />
        </div>
        <div className="row">
          <TextInput
            name="obsSessionPassword"
            label="OBS Ninja Session Password"
            type="text"
            placeholder=""
            value={obsSessionPassword}
            error={errors.obsSessionPassword}
            onChange={setObsSessionPassword}
          />
        </div>
        <div className="row">
          <TextInput name="obsSceneLink" label="OBS Ninja Scene Link" type="url" placeholder="" value={obsSceneLink} error={errors.obsSceneLink} onChange={setObsSceneLink} />
          <a href={replaceStringsClientSide(obsSceneLink)} title={replaceStringsClientSide(obsSceneLink)} className="mb-2" target="_blank">
            Open
          </a>
        </div>
        <div className="row">
          <TextInput
            name="obsControlRoomLink"
            label="OBS Ninja Control Room Link"
            type="url"
            placeholder=""
            value={obsControlRoomLink}
            error={errors.obsControlRoomLink}
            onChange={setObsControlRoomLink}
          />
          <a href={replaceStringsClientSide(obsControlRoomLink)} title={replaceStringsClientSide(obsControlRoomLink)} className="mb-2" target="_blank">
            Open
          </a>
        </div>
        <div className="row">
          <p>
            Available replacement tags:
            <ul>
              <li>[password] - replaced with the password listed above</li>
              <li>[pushid] - replaced with a unique push id for vdo.ninja to use as push tag in form of 'push=[randomid]'</li>
              <li>[userlabel] = replaced with string of format: '[H]-[Last]-[First]-[Title]-[Parish]' (H=Single letter house code)</li>
            </ul>
          </p>
        </div>
        {errors.onSave && (
          <div className="row mt-2 mx-1">
            <div className="alert alert-danger">{errors.onSave}</div>
          </div>
        )}
        <div className="d-flex my-2">
          <div className="px-2 ms-auto">
            <button type="button" disabled={saving} className="btn btn-secondary mx-2" onClick={cancel}>
              Cancel
            </button>
            <button type="submit" disabled={saving} className="btn btn-primary mx-2">
              {saving ? "Saving..." : "Save"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default VideoSettingsEdit;
