import { Session } from "../data/Session";
import { ApiStatus, callApi } from "./ApiUtilities";

export async function getSessions(): Promise<Session[]> {
  const response = await callApi<Session[]>("/api/session", "GET");
  console.log(`Get Sessions response ${response.status}`);
  if (response.status === ApiStatus.Ok) {
    return response.data as Session[];
  } else if (response.status === ApiStatus.Unauthorised) {
    throw Error(`Not logged in, try refreshing page (${response.message}`);
  }
  throw Error(response.message);
}

export async function saveSession(session: Session): Promise<Session> {
  const response = await callApi<Session>("/api/session", "POST", session);
  if (response.status === ApiStatus.Ok) {
    return response.data as Session;
  } else if (response.status === ApiStatus.Unauthorised) {
    throw Error(`Not logged in, try refreshing page (${response.message}`);
  }
  throw Error(response.message);
}
