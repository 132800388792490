import React, { useContext, useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { ApiResponse, ApiStatus } from "../api/ApiUtilities";
import { getUser } from "../api/AuthApi";
import { getMeetingStatus, UnauthorisedError } from "../api/MeetingApi";
import { GetHasSessionInPersonValueBeenSet, SetSessionInPersonValue } from "../api/Session";
import { getStatusMeetingStatus } from "../api/StatusApi";
import { updateMyDetails } from "../api/UserActions";
import { getCookieValue } from "../api/Utilities";
import ContainerCard from "../common/CardContainer";
import ZoomDetails from "../components/dashboard/ZoomDetails";
import { MeetingStatus } from "../data/MeetingStatus";
import { User } from "../data/User";
import { logoutAction, myUserUpdatedAction, updateMeetingStatusAction } from "../state/actions";
import { SLinkContext } from "../state/context";
//import PropTypes from "prop-types";
//ParticipantDashboardView.propTypes = {};
import "./Resources.css";

function ParticipantDashboardView(props: any) {
  const { state, dispatch } = useContext(SLinkContext);
  const [apiUser, setApiUser] = useState<User | null>();
  const [apiError, setApiError] = useState<string>("");
  const [meetingStatus, setMeetingStatus] = useState<MeetingStatus | null>(null);
  const [setIsInPerson, setSetIsInPerson] = useState(true);

  // TODO: move this useEffect to logged in layout so it runs once on login and then only as required by different screens
  useEffect(() => {
    getMeetingStatus()
      .then((status) => {
        dispatch(updateMeetingStatusAction(status));
      })
      .catch((error: Error) => {
        if (error instanceof UnauthorisedError) {
          dispatch(logoutAction());
        }
      });
  }, []);

  useEffect(() => {
    console.log("State session ask if is in person: " + state.meetingStatus?.askIsInPerson + " - has been set: " + GetHasSessionInPersonValueBeenSet());
    if (state.meetingStatus?.askIsInPerson && !GetHasSessionInPersonValueBeenSet()) {
      setSetIsInPerson(false);
    }
  }, [state.meetingStatus, state.meetingStatus?.askIsInPerson]);

  /*function shouldAskForInPersonOrNot() {
    if (state.meetingStatus === null) {
      return false; // don't ask if there is no meeting in progress
    }
    if (state.meetingStatus.askIsInPerson === false) {
      return false; // don't ask if the session indicates not to ask
    }
    if (GetHasSessionInPersonValueBeenSet()) {
      return false; // don't ask if already asked
    }
    console.log("NO HAS SESSION VALUE BEEN SET ***************************");
    return true;
  }
  useEffect(() => {
    shouldAskForInPersonOrNot();
  }, [state.meetingStatus, state.meetingStatus?.askIsInPerson]);
  */

  function handleCloseSetIsInPerson() {
    handleCloseIsInPersonDialog(true);
  }
  function handleCloseIsInPersonDialog(isInPerson: boolean) {
    SetSessionInPersonValue(isInPerson);
    // call update to user here with setting value if not already set that way
    if (state.user?.isInPerson !== isInPerson) {
      // call api to update
      var userModel = {
        ...state.user,
        isInPerson: isInPerson,
      } as User;
      updateMyDetails(userModel)
        .then((response: ApiResponse<User>) => {
          if (response.status === ApiStatus.Ok && response.data != null) {
            dispatch(myUserUpdatedAction(response.data));
          } else if (response.status === ApiStatus.Unauthorised) {
            dispatch(logoutAction());
          }
          toast.success("Updated successfully");
        })
        .catch((error: Error) => {
          toast.error(`Error updating your is in person preference. Details: ${error.message}`);
        });
    }
    setSetIsInPerson(true);
  }
  function handleCloseSetNotIsInPerson() {
    handleCloseIsInPersonDialog(false);
  }

  return (
    <div>
      <ContainerCard heading="Welcome">
        <div className="container mt-2 px-1 pe-1">
          <p>You are signed-in. Welcome to the Diocese of Melbourne Synod website. </p>
          <p>
            A video tutorial of how to navigate this site is available{" "}
            <a href="https://www.youtube.com/watch?v=ThVMmMOC9V0" rel="noreferrer" target="_blank">
              here
            </a>
            .
          </p>
          <p>You may need to click the video to make it play the first time if the video does not auto play on your device.</p>
          <p>During Synod, a tech helpdesk will be available by phoning 9653 4282.</p>
          <p>
            For all matters not pertinent to the active item of business, please contact <a href="mailto:synod@melbourneanglican.org.au">synod@melbourneanglican.org.au</a>
          </p>
        </div>
      </ContainerCard>
      <ContainerCard heading="Synod Papers">
        <div className="container">
          <ul>
            <li>
              <a className="btn btn-link" href="https://adom.synod.link/resources/DailyNoticePaper01.06.24withAttachments.pdf" target="_blank" rel="noreferrer">
                Daily Notice Paper - Saturday 14th October
              </a>
            </li>
          </ul>
        </div>
      </ContainerCard>
      {/*
      <ContainerCard heading="Synod Papers">
        <div className="container">
          <ul>
            <li>
              <a className="btn btn-link" href="https://adom.synod.link/resources/DailyNoticePaperSaturday14thOctober2023Attachments.pdf" target="_blank" rel="noreferrer">
                Daily Notice Paper - Saturday 14th October
              </a>
            </li>
            <li hidden>
              <a className="btn-link" href="https://adom.synod.link/resources/adom2022/Synod2022-DailyNoticePaper-Saturday15thOctober.pdf" target="_blank" rel="noreferrer">
                Daily Notice Paper – Saturday 15th October
              </a>
            </li>
            <li hidden>
              <a className="btn-link" href="https://adom.synod.link/resources/Synod2022_PartA_OrderofBusiness_Legislation_FINALv2.pdf" target="_blank" rel="noreferrer">
                Synod Papers Part A
              </a>
            </li>
            <li hidden>
              <a className="btn-link" href="https://adom.synod.link/resources/BookB_Synod2022_FINALcompressedv2.pdf" target="_blank" rel="noreferrer">
                Synod Papers Part B
              </a>
            </li>
            <li hidden>
              For other Synod information including newsletters, log on to the{" "}
              <a className="btn-link" href="https://parishportal.melbourneanglican.org.au/parish-tools/Melbourne-Diocesan-Synod-2022/Pages/Melbourne-Synod-2022.aspx" target="_blank" rel="noreferrer">
                Synod 2022 page
              </a>{" "}
              of the Parish Portal.
            </li>
          </ul>
        </div>
      </ContainerCard>
      <ContainerCard heading="Leading your Church into Growth - Video Resources">
        <div className="row" id="resources">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <div className="col">
              <a className="btn btn-success" href="https://synod.link/resources/Introduction-JSanderson.mp4" target="_blank" rel="noreferrer">
                Introduction
              </a>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 order-1">
            <div className="col">
              <a className="btn btn-success" href="https://synod.link/resources/Geelong.mp4" target="_blank" rel="noreferrer">
                St Stephen's Anglican Church - Belmont, Geelong
              </a>
              <a className="btn btn-success" href="https://synod.link/resources/Moorabin.mp4" target="_blank" rel="noreferrer">
                St David's Anglican Church - Moorabin
              </a>
              <a className="btn btn-success" href="https://synod.link/resources/SurfcoastAnglicanParish.mp4" target="_blank" rel="noreferrer">
                Surfcoast Anglican Parish
              </a>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 order-2">
            <div className="col">
              <a className="btn btn-success" href="https://synod.link/resources/GlenroyMerlynstone.mp4" target="_blank" rel="noreferrer">
                St Matthew's - Glenroy & St Linus' - Merlynstone
              </a>
              <a className="btn btn-success" href="https://synod.link/resources/LancefieldRomsey.mp4" target="_blank" rel="noreferrer">
                Lancefield & Romsey
              </a>
              <a className="btn btn-success" href="https://synod.link/resources/InverleighBannockburnMeredith.mp4" target="_blank" rel="noreferrer">
                Inverleigh, Bannockburn & Meredith Parishes
              </a>
            </div>
          </div>
        </div>
      </ContainerCard>
      <ContainerCard heading="Virtual Display Tables">
        <div hidden className="row" id="resources">
          <div className="col-lg-6 col-md-6 col-sm-12 order-1">
            <div className="row">
              <h2 className="text-center">Safe Ministry</h2>
            </div>
            <div className="row">
              <div className="col">
                <a href="http://www.bsl.org.au/" target="_blank" rel="noreferrer">
                  <img className="image" src="https://adom.synod.link/resources/bsl_thumb.png" width="250" height="150" />
                </a>
              </div>
              <div className="col">
                <a className="btn btn-success" href="https://www.melbourneanglican.org.au/safe-ministry/" target="_blank" rel="noreferrer">
                  Website
                </a>
                <a className="btn btn-success" href="https://adom.synod.link/resources/adom2022/DISPLAYTABLESafeMinistry.docx" target="_blank" rel="noreferrer">
                  Display Table Safe Ministry
                </a>
                <a className="btn btn-success" href="http://adom.synod.link/resources/adom2022/ChildSafeStandardsSelfAssessmentTemplate.pdf" target="_blank" rel="noreferrer">
                  Child Safe Standards Self Assessment Template
                </a>
              </div>
            </div>
          </div>

          <div className="col-lg-6 col-md-6 col-sm-12 order-4">
            <div className="row">
              <h2 className="text-center">Anglican Board of Mission</h2>
            </div>
            <div className="row">
              <div className="col">
                <a href="http://www.abmission.org/" target="_blank" rel="noreferrer">
                  <img id="abm" src="https://adom.synod.link/resources/adom2022/abm-logo-round-white-bg-rgb-500.png" width="400px" height="190px" />
                </a>
              </div>
              <div className="col">
                <a className="btn btn-success" href="http://www.abmission.org/" target="_blank" rel="noreferrer">
                  Website
                </a>
                <a className="btn btn-success" href="https://www.youtube.com/watch?v=Ef_B6qSfAoM&t=36s" target="_blank" rel="noreferrer">
                  Anglicans in Development Video
                </a>
                <a className="btn btn-success" href="https://adom.synod.link/resources/adom2022/ABM_Project-Book-2022-FA-v2-digital.pdf" target="_blank" rel="noreferrer">
                  Project Book 2022
                </a>
                <a className="btn btn-success" href="https://adom.synod.link/resources/adom2022/ABMandAIDadvert2022.pdf" target="_blank" rel="noreferrer">
                  ABM and AID Advert 2022
                </a>
              </div>
            </div>
          </div>

          <div className="col-lg-6 col-md-6 col-sm-12 order-5">
            <div className="row">
              <h2 className="text-center">Anglicare Victoria</h2>
            </div>
            <div className="row">
              <div className="col">
                <a href="https://www.anglicarevic.org.au/" target="_blank" rel="noreferrer">
                  <img id="abm" src="https://cdn.anglicarevic.org.au/wp-content/themes/default/static/img/logo.png" width="161px" height="56px" />
                </a>
              </div>
              <div className="col">
                <a className="btn btn-success" href="https://www.anglicarevic.org.au/" target="_blank" rel="noreferrer">
                  Website
                </a>
              </div>
            </div>
          </div>

          <div className="col-lg-6 col-md-6 col-sm-12 order-1">
            <div className="row">
              <h2 className="text-center">Brotherhood of St Laurence</h2>
            </div>
            <div className="row">
              <div className="col">
                <a href="http://www.bsl.org.au/" target="_blank" rel="noreferrer">
                  <img className="image" src="https://adom.synod.link/resources/bsl_thumb.png" width="250" height="150" />
                </a>
              </div>
              <div className="col">
                <a className="btn btn-success" href="http://www.bsl.org.au/" target="_blank" rel="noreferrer">
                  Website
                </a>
                <a className="btn btn-success" href="https://adom.synod.link/resources/adom2022/BrotherhoodofStLaurence_DISPLAYTABLE.docx" target="_blank" rel="noreferrer">
                  Display Table
                </a>
                <a className="btn btn-success" href="https://youtu.be/ZUYWLTnhqmU" target="_blank" rel="noreferrer">
                  BSL 2022 Annual Report
                </a>
                <a className="btn btn-success" href="https://www.youtube.com/watch?v=0GAiZt08guY" target="_blank" rel="noreferrer">
                  90th Celebration Video
                </a>
                <a className="btn btn-success" href="https://youtu.be/wM-v7eA19Fk" target="_blank" rel="noreferrer">
                  National Volunteer Week : The Change Makers
                </a>
              </div>
            </div>
          </div>

          <div className="col-lg-6 col-md-6 col-sm-12 order-1">
            <div className="row">
              <h2 className="text-center">Bush Church Aid</h2>
            </div>
            <div className="row">
              <div className="col">
                <a href="https://www.bushchurchaid.com.au/" target="_blank" rel="noreferrer">
                  <img className="image" src="https://www.bushchurchaid.com.au/images/logo-dark.png" width="366" height="155" />
                </a>
              </div>
              <div className="col">
                <a className="btn btn-success" href="https://www.bushchurchaid.com.au/" target="_blank" rel="noreferrer">
                  Website
                </a>
              </div>
            </div>
          </div>

          <div className="col-lg-6 col-md-6 col-sm-12 order-3">
            <div className="row">
              <h2 className="text-center">Melbourne Anglican Foundation</h2>
            </div>
            <div className="row">
              <div className="col">
                <a href="http://www.melbourneanglican.org.au/melbourne-anglican-foundation/" target="_blank" rel="noreferrer">
                  <img className="image" src="https://adom.synod.link/resources/maf_thumb.png" width="250" height="150" />
                </a>
              </div>
              <div className="col">
                <a className="btn btn-success" href="https://www.melbourneanglican.org.au/melbourne-anglican-foundation/" target="_blank" rel="noreferrer">
                  Website
                </a>
                <a className="btn btn-success" href="https://adom.synod.link/resources/adom2022/MelbourneAnglicanFoundation_DISPLAYTABLE.docx" target="_blank" rel="noreferrer">
                  Display Table
                </a>
                <a className="btn btn-success" href="https://adom.synod.link/resources/adom2022/MAF21x21MAFflyer822emailversion.pdf" target="_blank" rel="noreferrer">
                  Flyer
                </a>
                <a className="btn btn-success" href="https://adom.synod.link/resources/adom2022/MAFFullPage_TMAv6FINALEOFY2022.pdf" target="_blank" rel="noreferrer">
                  Lover Never Ends
                </a>
                <a className="btn btn-success" href="https://adom.synod.link/resources/adom2022/MAFA5_TMAv5FINALEOFY2022.pdf" target="_blank" rel="noreferrer">
                  I was a stranger
                </a>
                <a className="btn btn-success" href="https://adom.synod.link/resources/adom2022/MAF2020MAFAnnualReport.pdf" target="_blank" rel="noreferrer">
                  Annual Report
                </a>
              </div>
            </div>
          </div>

          <div className="col-lg-6 col-md-6 col-sm-12 order-3">
            <div className="row">
              <h2 className="text-center">St Peter's Book Room</h2>
            </div>
            <div className="row">
              <div className="col">
                <a href="http://www.bookroom.stpeters.org.au" target="_blank" rel="noreferrer">
                  <img className="image" src="https://adom.synod.link/resources/spbr_thumb.png" width="250" height="150" />
                </a>
              </div>
              <div className="col">
                <a className="btn btn-success" href="http://www.bookroom.stpeters.org.au" target="_blank" rel="noreferrer">
                  Website
                </a>
                <a className="btn btn-success" href="https://adom.synod.link/resources/adom2022/StPetersBookroom_DISPLAYTABLE.docx" target="_blank" rel="noreferrer">
                  Display Table
                </a>
                <a className="btn btn-success" href="https://adom.synod.link/resources/adom2022/StPetersBookroomAdvertisment.pdf" target="_blank" rel="noreferrer">
                  Advertisment
                </a>
              </div>
            </div>
          </div>

          <div className="col-lg-6 col-md-6 col-sm-12 order-2">
            <div className="row">
              <h2 className="text-center">Church Mission Society</h2>
            </div>
            <div className="row">
              <div className="col">
                <a href="https://www.cms.org.au/" target="_blank" rel="noreferrer">
                  <img className="image" src="https://adom.synod.link/resources/adom2022/CMS_Logo-Tagline_RGB.png" width="400px" height="66px" />
                </a>
              </div>
              <div className="col">
                <a className="btn btn-success" href="https://adom.synod.link/resources/adom2022/ChurchMissionSociety_DISPLAYTABLE.docx" target="_blank" rel="noreferrer">
                  Display Table
                </a>
                <a className="btn btn-success" href="https://adom.synod.link/resources/adom2022/CMSCheckpointSpring2022.pdf" target="_blank" rel="noreferrer">
                  Checkpoint Spring 2022
                </a>
                <a className="btn btn-success" href="https://adom.synod.link/resources/adom2022/CMSGodsMissionAndOursBibleStudy.pdf" target="_blank" rel="noreferrer">
                  Gods Mission and Ours Bible Study
                </a>
                <a className="btn btn-success" href="https://adom.synod.link/resources/adom2022/CMSActivitySheetsforKids.pdf" target="_blank" rel="noreferrer">
                  Activity Sheet for Kids
                </a>
              </div>
            </div>
          </div>

          <div className="col-lg-6 col-md-6 col-sm-12 order-1">
            <div className="row">
              <h2 className="text-center">Anglican Development Fund</h2>
            </div>
            <div className="row">
              <div className="col">
                <a href="https://www.melbourneanglican.org.au/agencies-anglican-development-fund/" target="_blank" rel="noreferrer">
                  <img className="image" src="https://www.melbourneanglican.org.au/wp-content/uploads/2019/10/ADF-logo-final-768x256.jpg" width="400" height="133" />
                </a>
              </div>
              <div className="col">
                <a className="btn btn-success" href="https://www.melbourneanglican.org.au/agencies-anglican-development-fund/" target="_blank" rel="noreferrer">
                  Website
                </a>
              </div>
            </div>
          </div>

          <div className="col-lg-6 col-md-6 col-sm-12 order-4">
            <div className="row">
              <h2 className="text-center">Anglican Overseas Aid</h2>
            </div>
            <div className="row">
              <div className="col">
                <a href="https://anglicanoverseasaid.org.au/" target="_blank" rel="noreferrer">
                  <img id="abm" src="https://adom.synod.link/resources/adom2022/AOA_logo_horiz_taglineRGB300.png" width="400px" height="70px" />
                </a>
              </div>
              <div className="col">
                <a className="btn btn-success" href="https://anglicanoverseasaid.org.au/" target="_blank" rel="noreferrer">
                  Website
                </a>
                <a className="btn btn-success" href="https://adom.synod.link/resources/adom2022/AnglicanOverseasAid_DISPLAYTABLE.docx" target="_blank" rel="noreferrer">
                  Display Table
                </a>
                <a className="btn btn-success" href="https://adom.synod.link/resources/adom2022/AOA_AbundanceWinterWeb2022WebVersion.pdf" target="_blank" rel="noreferrer">
                  Abundance Winter 2022
                </a>
                <a className="btn btn-success" href="https://adom.synod.link/resources/adom2022/AOA_Annual-Report-2021-Web-Version.pdf" target="_blank" rel="noreferrer">
                  Annual Report 2021
                </a>
              </div>
            </div>
          </div>

          <div className="col-lg-6 col-md-6 col-sm-12 order-5">
            <div className="row">
              <h2 className="text-center">Australian Anglican Church Calendar</h2>
            </div>
            <div className="row">
              <div className="col">
                <a className="btn btn-success" href="https://adom.synod.link/resources/adom2022/AustralianAnglicanChurchCalendar_DISPLAYTABLE.docx" target="_blank" rel="noreferrer">
                  Display Table
                </a>
                <a className="btn btn-success" href="https://www.youtube.com/watch?v=wgUFvgIledk" target="_blank" rel="noreferrer">
                  Australian Anglican Church Calendar
                </a>
                <a className="btn btn-success" href="https://adom.synod.link/resources/adom2022/AustralianAnglicanChurchCalendarAdvertisement.pdf" target="_blank" rel="noreferrer">
                  Australian Anglican Church Calendar Advertisement
                </a>
              </div>
            </div>
          </div>
        </div>
      </ContainerCard>
      */}
      {/* {state.user ? <ZoomDetails user={state.user} /> : <></>} */}
      {/* <div className="container">
        <h1>Participant Dashboard</h1>
        <p>
          username: {state.user?.username}
          <br />
          loggedin: {state.user ? "True" : "False"}
        </p>
        <p>+ 
          <button onClick={buttonClicked} type="button">
            Get User
          </button>
          <br />
          User: {JSON.stringify(apiUser)}
          <br />
          <button onClick={getMeetingStatus} type="button">
            Query Meeting Status S3
          </button>
          <br />
          Meeting Status: {JSON.stringify(meetingStatus)}
          <br />
          Error:
          <br /> {apiError}
        </p>
        <p>{document.cookie}</p>
        <p>{getCookieValue("SLinkPC")}</p>
      </div> */}
      <Modal show={!setIsInPerson} onHide={handleCloseSetIsInPerson}>
        <Modal.Header>
          <Modal.Title>Attending in Person?</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you attending the meeting in person today?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseSetNotIsInPerson}>
            No
          </Button>
          <Button variant="primary" onClick={handleCloseSetIsInPerson}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ParticipantDashboardView;
