import React, { Dispatch, SyntheticEvent, useState } from "react";
import { toast } from "react-toastify";
import { CallCheckMeetingStatus, setMeetingItem } from "../../api/MeetingApi";
import { MeetingStatuses } from "../../data/MeetingStatus";
import { SLinkActions, updateMeetingStatusAction } from "../../state/actions";
import { SLinkState, UserRoles } from "../../state/state";

export interface MeetingItemNavigationProps {
  onNext: () => void;
  onPrevious: () => void;
  loading: boolean;
  state: SLinkState;
  dispatch: Dispatch<SLinkActions>;
}

function MeetingItemNavigation({ onNext, onPrevious, loading, state, dispatch }: MeetingItemNavigationProps) {
  const [refreshDisabled, setRefreshDisabled] = useState(false);
  const [callingRefresh, setCallingRefresh] = useState(false);
  const [callingClearItem, setCallingClearItem] = useState(false);

  function previous(event: SyntheticEvent) {
    event.preventDefault();
    onPrevious();
  }

  function next(event: SyntheticEvent) {
    event.preventDefault();
    onNext();
  }

  function refresh(event: SyntheticEvent) {
    setRefreshDisabled(true);
    setCallingRefresh(true);
    event.preventDefault();
    CallCheckMeetingStatus()
      .then((status) => {
        let isNewVersion = state.meetingStatus ? state.meetingStatus.version < status.version : true;
        dispatch(updateMeetingStatusAction(status));
        toast.success(`Meeting status refreshed (v${status.version} - ${status.lastUpdate} new ${isNewVersion})`);
      })
      .catch((error: Error) => {
        toast.error(`Error on refreshing state '${error.message}'`);
      })
      .finally(() => {
        setCallingRefresh(false);
        setTimeout(() => {
          setRefreshDisabled(false);
        }, 10000);
      });
  }
  function clear(event: SyntheticEvent) {
    setCallingClearItem(true);
    event.preventDefault();
    setMeetingItem("NONE")
      .then((status) => {
        dispatch(updateMeetingStatusAction(status));
        toast.success("Item of business cleared");
      })
      .catch((error: Error) => {
        toast.error(`Error on clearing item '${error.message}'`);
      })
      .finally(() => {
        setCallingClearItem(false);
      });
  }

  return (
    <div className="row align-items-start mb-2">
      {/* Navigation Bar for previous/next items */}
      <div className="d-flex col-md-8 px-2">
        <div className="p1 me-auto">
          <button className="btn btn-light" onClick={previous} disabled={state.meetingStatus?.status === MeetingStatuses.Voting}>
            Previous Item
          </button>
          {state.user?.userRoles.includes(UserRoles.Admin) ? (
            <button className="btn btn-light mx-2" onClick={clear} disabled={callingClearItem || state.meetingStatus?.status === MeetingStatuses.Voting}>
              Clear Item of Business
            </button>
          ) : (
            <></>
          )}
        </div>
        <div className="p1 m-auto">
          <div className="text-dark fs-3">Meeting {state.meetingStatus?.status}</div>
          {loading ? (
            <>
              <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
              <span className="mx-2 align-middle fs-3">Loading...</span>
            </>
          ) : (
            <></>
          )}
        </div>
        <div className="p1 ms-auto">
          {/* // TODO: consider adding disabled state during edit mode */}
          <button className="btn btn-light mx-2" onClick={refresh} disabled={callingRefresh || refreshDisabled}>
            Refresh
          </button>
          <button className="btn btn-light" onClick={next} disabled={state.meetingStatus?.status === MeetingStatuses.Voting}>
            Next Item
          </button>
        </div>
      </div>
      <div className="col-md-4 px-1">
        Users: {state.statUserCount?.totalUsers} <br />
        Lay: {state.statUserCount?.usersLay} Clergy: {state.statUserCount?.usersClergy} Bishops: {state.statUserCount?.usersBishops}
      </div>
    </div>
  );
}

export default MeetingItemNavigation;
