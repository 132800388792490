import React from "react";

const LoadingSpinner = () => {
  return (
    <div className="modal">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Loading</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
            <p>Loading... please wait</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoadingSpinner;
