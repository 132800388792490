import { ApiStatus, callApi } from "./ApiUtilities";

export async function getAttendanceFile(): Promise<string> {
  const response = await callApi<string>("/api/system/csv/attendance", "GET");
  if (response.status === ApiStatus.Ok) {
    return response.data ?? "";
  } else if (response.status === ApiStatus.Unauthorised) {
    throw Error(`Not logged in, try refreshing page (${response.message}`);
  }
  throw Error(response.message);
}

export async function getBusinessItemsFile(): Promise<string> {
  const response = await callApi<string>("/api/system/csv/businessitems", "GET");
  if (response.status === ApiStatus.Ok) {
    return response.data ?? "";
  } else if (response.status === ApiStatus.Unauthorised) {
    throw Error(`Not logged in, try refreshing page (${response.message}`);
  }
  throw Error(response.message);
}

export async function getVoteAuditFile(): Promise<string> {
  const response = await callApi<string>("/api/system/csv/voteaudit", "GET");
  if (response.status === ApiStatus.Ok) {
    return response.data ?? "";
  } else if (response.status === ApiStatus.Unauthorised) {
    throw Error(`Not logged in, try refreshing page (${response.message}`);
  }
  throw Error(response.message);
}
