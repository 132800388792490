import { MeetingAction, MeetingActionTypes } from "../data/MeetinAction";
import { StatUserCount } from "../data/StatUserCount";
import { ActionType, SLinkActions } from "./actions";
import { SLinkState } from "./state";

export function sLinkReducer(state: SLinkState, action: SLinkActions): SLinkState {
  console.info(`reducer message ${action.type} payload ${JSON.stringify(action)}`);
  switch (action.type) {
    case ActionType.Login:
      return { ...state, user: action.payload };
    // Logout
    case ActionType.Logout:
      return { ...state, user: null };

    case ActionType.MyDetailsUpdated:
      return { ...state, user: action.payload };

    // Update My Meeting Action  *****************************************************************************
    case ActionType.UpdateMyMeetinAction:
      if (action.payload) {
        switch (action.payload.type) {
          case MeetingActionTypes.AskToSpeak:
            return { ...state, myAskToSpeak: { ...action.payload } as MeetingAction };
          case MeetingActionTypes.SuggestAmendment:
            return { ...state, mySuggestAmendment: { ...action.payload } as MeetingAction };
          case MeetingActionTypes.Objection:
            return { ...state, myObjection: { ...action.payload } as MeetingAction };
          case MeetingActionTypes.PointOfOrder:
            return { ...state, myPointOfOrder: { ...action.payload } as MeetingAction };
        }
      }
      return { ...state, myAskToSpeak: null };
    case ActionType.ClearMyMeetingAction:
      if (action.payload) {
        switch (action.payload) {
          case MeetingActionTypes.AskToSpeak:
            return { ...state, myAskToSpeak: null };
          case MeetingActionTypes.SuggestAmendment:
            return { ...state, mySuggestAmendment: null };
          case MeetingActionTypes.Objection:
            return { ...state, myObjection: null };
          case MeetingActionTypes.PointOfOrder:
            return { ...state, myPointOfOrder: null };
        }
      }
      return { ...state, myAskToSpeak: null };

    // Meeting State Updates ***************************************************************************
    case ActionType.UpdateLiveStream:
      return { ...state, youTubeVideoId: action.payload.youTubeVideoId };

    case ActionType.UpdateStatUserCount:
      return { ...state, statUserCount: { ...action.payload } as StatUserCount };

    case ActionType.UpdateMeetingStatus:
      if (action.payload) {
        if (state.meetingStatus && action.payload.version <= state.meetingStatus.version) {
          return state;
        }
        var newMeetingState = {
          ...action.payload,
          currentItem: action.payload.currentItem ? { ...action.payload.currentItem } : null,
          currentAmendment: action.payload.currentAmendment ? { ...action.payload.currentAmendment } : null,
          previewAmendment: action.payload.previewAmendment ? { ...action.payload.previewAmendment } : null,
        };
        return { ...state, meetingStatus: newMeetingState };
      } else {
        return { ...state, meetingStatus: null };
      }
    case ActionType.MeetingActionRaised:
      // TODO:
      var found = false;
      var newList = state.meetingActions.map((a) => {
        if (a.subId === action.payload.subId) {
          found = true;
          return action.payload;
        }
        return a;
      });
      if (!found) {
        newList = [...newList, action.payload];
      }
      return { ...state, meetingActions: newList };

    case ActionType.MeetingActionUpdated:
      var newState = { ...state, meetingActions: state.meetingActions.map((a) => (a.subId === action.payload.subId ? { ...action.payload } : a)) };
      if (state.myPointOfOrder && action.payload.subId === state.myPointOfOrder.subId) {
        newState.myPointOfOrder = action.payload;
      }
      if (state.myObjection && action.payload.subId === state.myObjection.subId) {
        newState.myObjection = action.payload;
      }
      if (state.mySuggestAmendment && action.payload.subId === state.mySuggestAmendment.subId) {
        newState.mySuggestAmendment = action.payload;
      }
      if (state.myAskToSpeak && action.payload.subId === state.myAskToSpeak.subId) {
        newState.myAskToSpeak = action.payload;
      }
      return newState;

    case ActionType.MeetingActionClosed:
      var newState = { ...state, meetingActions: state.meetingActions.filter((a) => a.subId !== action.payload.subId) };
      if (state.myPointOfOrder && action.payload.subId === state.myPointOfOrder.subId) {
        newState.myPointOfOrder = action.payload;
      }
      if (state.myObjection && action.payload.subId === state.myObjection.subId) {
        newState.myObjection = action.payload;
      }
      if (state.mySuggestAmendment && action.payload.subId === state.mySuggestAmendment.subId) {
        newState.mySuggestAmendment = action.payload;
      }
      if (state.myAskToSpeak && action.payload.subId === state.myAskToSpeak.subId) {
        newState.myAskToSpeak = action.payload;
      }
      return newState;

    case ActionType.UpdateMeetingActions:
      return { ...state, meetingActions: action.payload };

    case ActionType.WebSocketStateUpdate:
      return { ...state, connectionState: action.payload };
    case ActionType.WebSocketIdentityUpdated:
      return { ...state, socketConnectionId: action.payload };
    default:
      return state;
  }
}
