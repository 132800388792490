export interface Session {
  id: string;
  subId: string;
  type: string;
  dateTimeStart: string;
  dateTimeEnd: string;
  name: string;
  status: string;
  enableParticipantView: boolean;
  enableParticipantButtons: boolean;
  askIsInPerson: boolean;
  currentItemId: string;
}

export enum SessionStatuses {
  // expected ok/successful response
  Setup = "Setup",
  InSession = "In Session",
  Voting = "Voting",
  Completed = "Completed",
}
