import React from "react";
import { Item, ItemStatuses, ItemTypes } from "../../data/ItemOfBusiness";

export interface ItemsListProps {
  list: Item[];
  onEdit: (item: Item) => void;
  onAmendItem: (item: Item) => void;
  setCurrentMeetingItem: (item: Item) => void;
  isAdmin: boolean;
}

function ItemsList({ list, onEdit, onAmendItem, setCurrentMeetingItem, isAdmin }: ItemsListProps) {
  return (
    <table className="table">
      <thead>
        <tr>
          <th>Code</th>
          {isAdmin ? <td>Order</td> : <></>}
          <th>Type</th>
          <th>Status</th>
          <th>Name</th>
          <th>Tot|For|Agst|Abstn</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {list ? (
          list.map((item) => {
            let votes = item.voteCounts.length === 4 ? item.voteCounts[3] : null;
            return (
              <tr key={item.subId}>
                <td>{item.code}</td>
                {isAdmin ? <td>{item.order}</td> : <></>}
                <td>{item.type}</td>
                <td>{item.status}</td>
                {/* <td>{FormatHelper.getDateString(session.dateTimeStart)}</td> */}
                <td style={{ maxWidth: "300px" }} title={item.code + "\n\n" + item.text}>
                  {item.heading}
                </td>
                <td>{votes ? "(" + votes.total + ") | +" + votes?.votesFor + " | -" + votes?.votesAgainst + " | =" + votes?.votesAbstain : ""}</td>
                <td>
                  {(item.type === ItemTypes.OriginalMotion && item.status === ItemStatuses.New) || isAdmin ? (
                    <button className="btn btn-primary" onClick={() => onEdit(item)}>
                      Edit
                    </button>
                  ) : (
                    <></>
                  )}
                  &nbsp;
                  <button className="btn btn-warning" onClick={() => setCurrentMeetingItem(item)}>
                    Set as Meeting Item
                  </button>
                  &nbsp;
                  <button className="btn btn-secondary" onClick={() => onAmendItem(item)}>
                    Create Amendment
                  </button>
                </td>
              </tr>
            );
          })
        ) : (
          <tr>
            <td colSpan={3}>Could not request Items list</td>
          </tr>
        )}
        {list && list.length === 0 ? (
          <tr>
            <td colSpan={3}>No Items found</td>
          </tr>
        ) : (
          <></>
        )}
      </tbody>
    </table>
  );
}

export default ItemsList;
