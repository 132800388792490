import React from "react";
import PropTypes from "prop-types";

interface CheckboxInputProps {
  name: string;
  label: string;
  onChange: (value: boolean) => void;
  checked: boolean;
  error: string | undefined;
  helpText?: string | undefined;
}

const CheckboxInput = ({ name, label, checked, onChange, error, helpText }: CheckboxInputProps) => {
  return (
    <div className="form-group">
      <label htmlFor={name}>{label}</label>
      <div className="field">
        <span>
          <input type="checkbox" name={name} onChange={() => onChange(!checked)} checked={checked} />{" "}
          <label htmlFor={name} onClick={() => onChange(!checked)}>
            {label}
          </label>
          <br />
        </span>
        {error && <div className="alert alert-danger">{error}</div>}
        {helpText && (
          <div id={name + "-help"} className="form-text mx-2 text-muted text-end">
            {helpText}
          </div>
        )}
      </div>
    </div>
  );
};

CheckboxInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
  error: PropTypes.string,
};

export default CheckboxInput;
