import { stringify } from "querystring";
import React, { useContext, useEffect, useState } from "react";
import { callApi, uploadFile } from "../../api/ApiUtilities";
import { getAttendanceFile, getBusinessItemsFile, getVoteAuditFile } from "../../api/SystemApi";
import { callDeleteUser, getUsers, saveUser } from "../../api/UserActions";
import ContainerCard from "../../common/CardContainer";
import ErrorDisplay from "../../common/ErrorDisplay";
import { SLinkContext } from "../../state/context";

const SystemAdmin = () => {
  const { state, dispatch } = useContext(SLinkContext);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  async function downloadAttendance() {
    console.log("set loading...");
    setLoading(true);
    getAttendanceFile()
      .then((csvContent: string) => {
        console.log("csv content", csvContent);
        var filename = `${new Date().toISOString()}-AttendanceRecord.csv`;
        console.log("filename: " + filename);
        var blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
        var link = document.createElement("a");
        var url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", filename);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((err: any) => {
        setError(err);
      })
      .then(() => {
        setLoading(false);
      });
  }
  async function downloadItemsRecord() {
    console.log("set loading...");
    setLoading(true);
    getBusinessItemsFile()
      .then((csvContent: string) => {
        console.log("csv content", csvContent);
        var filename = `${new Date().toISOString()}-ItemsRecord.csv`;
        console.log("filename: " + filename);
        var blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
        var link = document.createElement("a");
        var url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", filename);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((err: any) => {
        setError(err);
      })
      .then(() => {
        setLoading(false);
      });
  }
  async function downloadVoteAudit() {
    console.log("set loading...");
    setLoading(true);
    getVoteAuditFile()
      .then((csvContent: string) => {
        console.log("csv content", csvContent);
        var filename = `${new Date().toISOString()}-VoteAudit.csv`;
        console.log("filename: " + filename);
        var blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
        var link = document.createElement("a");
        var url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", filename);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((err: any) => {
        setError(err);
      })
      .then(() => {
        setLoading(false);
      });
  }

  return (
    <>
      <ContainerCard heading="System Admin">
        <div>
          <p>Export System Files</p>
          <p>Session ID: {state.meetingStatus?.id ?? "No active session"}</p>
          {error ? <p className="error">{error}</p> : <></>}
        </div>
        <div className="m-2">
          <button onClick={downloadAttendance} disabled={loading} className="btn btn-secondary">
            Download Attendance
          </button>
        </div>
        <div className="m-2">
          <button onClick={downloadItemsRecord} disabled={loading} className="btn btn-secondary">
            Download Items Record
          </button>
        </div>
        <div className="m-2">
          <button onClick={downloadVoteAudit} disabled={loading} className="btn btn-secondary">
            Download Vote Audit
          </button>
        </div>
      </ContainerCard>
    </>
  );
};

export default SystemAdmin;
