import PropTypes from "prop-types";
import { SyntheticEvent } from "react";

interface TextInputProps {
  name: string;
  type: string;
  label: string;
  onChange: (value: string) => void;
  onEnter?: () => void;
  placeholder?: string | undefined;
  value: string | undefined;
  error: string | undefined;
  helpText?: string | undefined;
  pattern?: string | undefined;
}

const TextInput = ({ name, type, label, onChange, onEnter, placeholder, value, error, helpText, pattern }: TextInputProps) => {
  let wrapperClass = "form-group";
  if (error && error.length > 0) {
    wrapperClass = `${wrapperClass} has-error`;
  }
  // DEBUGGING UPDATE ISSUES
  //console.log(name + " - " + value + " - " + onChange);
  function handleKeyDown(event: any) {
    if (event.key === "Enter" && onEnter) {
      onEnter();
    }
  }

  return (
    <div className={wrapperClass}>
      <label htmlFor={name} className="form-label">
        {label}
      </label>
      <div className="field">
        <input
          type={type}
          name={name}
          className="form-control"
          placeholder={placeholder}
          value={value}
          onChange={(e) => onChange(e.target.value)}
          onKeyDown={handleKeyDown}
          aria-describedby={name + "help"}
          pattern={pattern}
        />
        {error && <div className="alert alert-danger">{error}</div>}
        {helpText && (
          <div id={name + "-help"} className="form-text mx-2 text-muted text-end">
            {helpText}
          </div>
        )}
      </div>
    </div>
  );
};

TextInput.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  error: PropTypes.string,
  helpText: PropTypes.string,
  pattern: PropTypes.string,
};

export default TextInput;
