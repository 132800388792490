import React from "react";

interface ContainerCardProps {
  heading?: string;
  children: any;
}

function ContainerCard({ children, heading }: ContainerCardProps) {
  return (
    <>
      {/* <div className="container mt-2"> */}
      <div className="card mb-2">
        {heading ? <h3 className="card-title px-1 py-2 bg-light">{heading}</h3> : <></>}
        <div className="mt-1">{children}</div>
      </div>
      {/* </div> */}
    </>
  );
}

export default ContainerCard;
