import { LiveStreamDetails } from "../data/LiveStreamDetails";
import { MeetingAction } from "../data/MeetinAction";
import { MeetingStatus } from "../data/MeetingStatus";
import { StatUserCount } from "../data/StatUserCount";
import { User } from "../data/User";
import { WebSocketState } from "./state";

export enum ActionType {
  Login,
  Logout,
  MyDetailsUpdated,
  UpdateMyMeetinAction,
  ClearMyMeetingAction,
  UpdateSessionState,
  UpdateMeetingStatus,
  UpdateMeetingActions,
  UpdateLiveStream,
  UpdateStatUserCount,
  MeetingActionUpdated,
  MeetingActionRaised,
  MeetingActionClosed,
  WebSocketStateUpdate,
  WebSocketIdentityUpdated,
}
// LOGIN ACTION
export interface LoginAction {
  type: ActionType.Login;
  payload: User;
}
export const loginAction = (user: User): LoginAction => ({
  type: ActionType.Login,
  payload: user,
});

// MY USER UPDATED ACTION
export interface MyUserUpdatedAction {
  type: ActionType.MyDetailsUpdated;
  payload: User;
}
export const myUserUpdatedAction = (user: User): MyUserUpdatedAction => ({
  type: ActionType.MyDetailsUpdated,
  payload: user,
});

// LOGOUT ACTION
export interface LogoutAction {
  type: ActionType.Logout;
}
export const logoutAction = (): LogoutAction => ({
  type: ActionType.Logout,
});

/// Set Meeting Actions   ##########################################################################
export interface UpdateMyMeetingAction {
  type: ActionType.UpdateMyMeetinAction;
  payload: MeetingAction | null;
}
export const updateMyMeetingAction = (myMeetingAction: MeetingAction | null): UpdateMyMeetingAction => ({
  type: ActionType.UpdateMyMeetinAction,
  payload: myMeetingAction,
});
export interface ClearMyMeetingAction {
  type: ActionType.ClearMyMeetingAction;
  payload: string;
}
export const clearMyMeetingAction = (meetingActionType: string): ClearMyMeetingAction => ({
  type: ActionType.ClearMyMeetingAction,
  payload: meetingActionType,
});

/// Update Live Stream    ##########################################################################
export interface UpdateLiveStreamAction {
  type: ActionType.UpdateLiveStream;
  payload: LiveStreamDetails;
}
export const updateLiveStreamAction = (liveStreamDetails: LiveStreamDetails): UpdateLiveStreamAction => ({
  type: ActionType.UpdateLiveStream,
  payload: liveStreamDetails,
});

/// Update Stat User Count    ##########################################################################
export interface UpdateStatUserCountAction {
  type: ActionType.UpdateStatUserCount;
  payload: StatUserCount;
}
export const updateStatUserCountAction = (statUserCount: StatUserCount): UpdateStatUserCountAction => ({
  type: ActionType.UpdateStatUserCount,
  payload: statUserCount,
});

/// Update Meeting Status ##########################################################################
export interface UpdateMeetingStatusAction {
  type: ActionType.UpdateMeetingStatus;
  payload: MeetingStatus | null;
}
export const updateMeetingStatusAction = (meetingStatus: MeetingStatus | null): UpdateMeetingStatusAction => ({
  type: ActionType.UpdateMeetingStatus,
  payload: meetingStatus,
});

/// Meeting Action Updated ##########################################################################
export interface MeetingActionUpdatedAction {
  type: ActionType.MeetingActionUpdated;
  payload: MeetingAction;
}
export const meetingActionUpdated = (meetingAction: MeetingAction): MeetingActionUpdatedAction => ({
  type: ActionType.MeetingActionUpdated,
  payload: meetingAction,
});
/// Meeting Action Raised ##########################################################################
export interface MeetingActionRaisedAction {
  type: ActionType.MeetingActionRaised;
  payload: MeetingAction;
}
export const meetingActionRaised = (meetingAction: MeetingAction): MeetingActionRaisedAction => ({
  type: ActionType.MeetingActionRaised,
  payload: meetingAction,
});
/// Meeting Action Removed ##########################################################################
export interface MeetingActionClosedAction {
  type: ActionType.MeetingActionClosed;
  payload: MeetingAction;
}
export const meetingActionClosed = (meetingAction: MeetingAction): MeetingActionClosedAction => ({
  type: ActionType.MeetingActionClosed,
  payload: meetingAction,
});
// Update Meeting Actions ##########################################################################
export interface UpdateMeetingActionsAction {
  type: ActionType.UpdateMeetingActions;
  payload: MeetingAction[];
}
export const updateMeetingActionsAction = (list: MeetingAction[]): UpdateMeetingActionsAction => ({
  type: ActionType.UpdateMeetingActions,
  payload: list,
});

// Update Web Socket State ##########################################################################
export interface UpdateWebSocketStateAction {
  type: ActionType.WebSocketStateUpdate;
  payload: WebSocketState;
}
export const updateWebSocketStateAction = (state: WebSocketState): UpdateWebSocketStateAction => ({
  type: ActionType.WebSocketStateUpdate,
  payload: state,
});

// Update Web Socket Identity ##########################################################################
export interface UpdateWebSocketIdentityAction {
  type: ActionType.WebSocketIdentityUpdated;
  payload: string;
}
export const updateWebSocketIdentityAction = (connectionId: string): UpdateWebSocketIdentityAction => ({
  type: ActionType.WebSocketIdentityUpdated,
  payload: connectionId,
});

export type SLinkActions =
  | LoginAction
  | LogoutAction
  | MyUserUpdatedAction
  | UpdateMyMeetingAction
  | ClearMyMeetingAction
  | UpdateLiveStreamAction
  | UpdateStatUserCountAction
  | UpdateMeetingStatusAction
  | MeetingActionRaisedAction
  | MeetingActionUpdatedAction
  | MeetingActionClosedAction
  | UpdateMeetingActionsAction
  | UpdateWebSocketStateAction
  | UpdateWebSocketIdentityAction;
