import { apiCallError } from "../redux/actions/apiStatusActions";
import { getCookieValue } from "./Utilities";

export interface ApiResponse<T> {
  status: ApiStatus;
  message: string;
  data: T | null;
}
interface RawApiResponse<T> {
  message: string | null;
  data: T | null;
}
export enum ApiStatus {
  // expected ok/successful response
  Ok = 200,
  // bad input or unrecognised input, or validation error
  BadRequest = 400,
  NotFound = 404,
  // Unauthenticated user error
  Unauthorised = 401,
  // Authenticated User Authorisation failure
  Forbidden = 403,
  // Server side error
  UnknownError = 500,
}
export class ApiError extends Error {
  status: ApiStatus;
  constructor(sts: ApiStatus, msg?: string) {
    super(msg);
    this.status = sts;
  }
}

export async function callApi<T>(url: string, method: string, requestData?: object): Promise<ApiResponse<T>> {
  console.info("API Call: " + method + " " + url);
  try {
    const authId = getCookieValue("SLinkPC");
    const response = await fetch(url, {
      method: method,
      headers: { "content-type": "application/json", Authorization: `Bearer ${authId}` },
      body: JSON.stringify(requestData),
    });
    // return with data or null depeding on successful ok response (200)
    //console.log(response);
    //console.log(await response.json());
    var responseData = (await response.json()) as RawApiResponse<T>;
    //console.log("API Response Data: " + response.status + " body: " + JSON.stringify(responseData));
    var result = {
      status: response.status as ApiStatus,
      message: responseData.message ?? JSON.stringify(responseData),
      data: responseData.data,
      //data: response.ok ? ((await response.json()) as T) : null,
    } as ApiResponse<T>;
    console.log("API Response Result: " + JSON.stringify(result));
    return result;
  } catch (error: any) {
    console.error("API Error: ", error);
    // return unknown error with error message and null data
    return {
      status: ApiStatus.UnknownError,
      message: error.message,
      data: null,
    };
  }
}

export async function uploadFile<T>(url: string, method: string, formData: FormData): Promise<ApiResponse<T>> {
  console.info("Upload File API Call: " + method + " " + url);
  try {
    const authId = getCookieValue("SLinkPC");
    const response = await fetch(url, {
      method: method,
      headers: { Authorization: `Bearer ${authId}` },
      body: formData,
    });
    // return with data or null depeding on successful ok response (200)
    //console.log(response);
    //console.log(await response.json());
    var responseData = (await response.json()) as RawApiResponse<T>;
    //console.log("API Response Data: " + response.status + " body: " + JSON.stringify(responseData));
    var result = {
      status: response.status as ApiStatus,
      message: responseData.message ?? JSON.stringify(responseData),
      data: responseData.data,
      //data: response.ok ? ((await response.json()) as T) : null,
    } as ApiResponse<T>;
    console.log("Upload File API Response Result: " + JSON.stringify(result));
    return result;
  } catch (error: any) {
    console.error("Upload File API Error: ", error);
    // return unknown error with error message and null data
    return {
      status: ApiStatus.UnknownError,
      message: error.message,
      data: null,
    };
  }
}

// export async function handleResponse(response) {
//   let json = response.json();

//   if (response.ok) {
//     console.log("API request OK");
//     return json;
//   } else if (response.status === 401) {
//     console.log("Unauthorised response.... logging out");
//     throw new Error(json.message ?? "An unknown error occured");
//   } else if (response.status === 400) {
//     // So, a server-side client input validation error occurred.
//     // Server side validation should return an object with a 'message' property on it
//     throw new Error(json.message ?? "An unknown error occured");
//   }
//   throw new Error("A network error occured, please try again.");
// }

// // In a real app, would likely call an error logging service.
// export function handleError(error) {
//   // eslint-disable-next-line no-console
//   console.error("API call failed. " + error);
//   throw error;
// }
