import { Item } from "../data/ItemOfBusiness";
import { MeetingAction } from "../data/MeetinAction";
import { MeetingStatus } from "../data/MeetingStatus";
import { StatUserCount } from "../data/StatUserCount";
import { ApiResponse, ApiStatus, callApi } from "./ApiUtilities";

export async function CallCheckMeetingStatus(): Promise<MeetingStatus> {
  const response = await callApi<MeetingStatus>("/statusapi/meetingstatus", "GET");
  console.log(`Call Check Meeting Status response ${response.status}`);
  return checkResponse<MeetingStatus>(response);
}

export async function CallCheckVoteCount(): Promise<MeetingStatus> {
  const response = await callApi<MeetingStatus>("/api/meeting/checkvotecount", "POST");
  console.log(`Call Check Meeting Status response ${response.status}`);
  return checkResponse<MeetingStatus>(response);
}

export async function getUserCount(): Promise<StatUserCount> {
  const response = await callApi<StatUserCount>("/api/meeting/usercount", "GET");
  console.log(`Get Stat User Count response ${response.status}`);
  return checkResponse<StatUserCount>(response);
}

export async function getMeetingStatus(): Promise<MeetingStatus> {
  const response = await callApi<MeetingStatus>("/api/meeting", "GET");
  console.log(`Get Sessions response ${response.status}`);
  return checkResponse<MeetingStatus>(response);
}

export async function setMeetingNextItem(): Promise<MeetingStatus> {
  const response = await callApi<MeetingStatus>("/api/meeting/next", "POST");
  console.log(`Get Sessions response ${response.status}`);
  return checkResponse<MeetingStatus>(response);
}

export async function setMeetingItem(subId: string): Promise<MeetingStatus> {
  const response = await callApi<MeetingStatus>(`/api/meeting/next/${subId}`, "POST");
  console.log(`Get Sessions response ${response.status}`);
  return checkResponse<MeetingStatus>(response);
}

export async function setMeetingPreviousItem(): Promise<MeetingStatus> {
  const response = await callApi<MeetingStatus>("/api/meeting/previous", "POST");
  console.log(`Get Sessions response ${response.status}`);
  return checkResponse<MeetingStatus>(response);
}

export async function previewItemUpdate(item: Item): Promise<MeetingStatus> {
  const response = await callApi<MeetingStatus>("/api/meeting/preview", "POST", item);
  console.log(`Get Sessions response ${response.status}`);
  return checkResponse<MeetingStatus>(response);
}

export async function publishItemUpdate(item: Item): Promise<MeetingStatus> {
  const response = await callApi<MeetingStatus>("/api/meeting/publish", "POST", item);
  console.log(`Get Sessions response ${response.status}`);
  return checkResponse<MeetingStatus>(response);
}

export async function putToVote(type: string): Promise<MeetingStatus> {
  const response = await callApi<MeetingStatus>(`/api/meeting/puttovote/${type}`, "POST");
  console.log(`Put to Vote response ${response.status}`);
  return checkResponse<MeetingStatus>(response);
}

export async function putToVoteContinue(): Promise<MeetingStatus> {
  const response = await callApi<MeetingStatus>("/api/meeting/puttovote/Sequential", "POST");
  console.log(`Put to Vote continue response ${response.status}`);
  return checkResponse<MeetingStatus>(response);
}

export async function cancelVote(): Promise<MeetingStatus> {
  const response = await callApi<MeetingStatus>("/api/meeting/cancelvote", "POST");
  console.log(`Cancel Vote response ${response.status}`);
  return checkResponse<MeetingStatus>(response);
}

export async function finaliseVote(): Promise<MeetingStatus> {
  const response = await callApi<MeetingStatus>("/api/meeting/finalisevote", "POST");
  console.log(`Finalise Vote response ${response.status}`);
  return checkResponse<MeetingStatus>(response);
}

export async function CallAcceptVotedItem(): Promise<MeetingStatus> {
  const response = await callApi<MeetingStatus>("/api/meeting/acceptvoteditem", "POST");
  console.log(`Accept Voted Item response ${response.status}`);
  return checkResponse<MeetingStatus>(response);
}

export async function CallAbandonVotedItem(): Promise<MeetingStatus> {
  const response = await callApi<MeetingStatus>("/api/meeting/abandonvoteditem", "POST");
  console.log(`Abandon Voted Item response ${response.status}`);
  return checkResponse<MeetingStatus>(response);
}

export async function CallRevoteVotedItem(): Promise<MeetingStatus> {
  const response = await callApi<MeetingStatus>("/api/meeting/revotevoteditem", "POST");
  console.log(`Revote Voted Item response ${response.status}`);
  return checkResponse<MeetingStatus>(response);
}

export async function CallReopenAmendItem(item: Item): Promise<MeetingStatus> {
  const response = await callApi<MeetingStatus>("/api/meeting/reopenamend", "POST", item);
  console.log(`Accept Voted Item response ${response.status}`);
  return checkResponse<MeetingStatus>(response);
}

export async function CallReopenSubmotionItem(item: Item): Promise<MeetingStatus> {
  const response = await callApi<MeetingStatus>("/api/meeting/reopensubmotion", "POST", item);
  console.log(`Accept Voted Item response ${response.status}`);
  return checkResponse<MeetingStatus>(response);
}

export async function submitVote(vote: string): Promise<ApiResponse<string>> {
  let i = 0;
  while (true) {
    i++;
    try {
      const response = await callApi<string>("/voteapi/vote", "POST", { vote: vote });
      console.log(`Finalise Vote response ${response.status}`);
      if (response.status === ApiStatus.Unauthorised || response.status === ApiStatus.Ok || i > 6) {
        return response;
      }
    } catch (error: any) {
      console.log("error submitting vote: " + JSON.stringify(error));
    }
    await sleep(i * 1000);
  }
}

function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export async function getMeetinActions(): Promise<MeetingAction[]> {
  const response = await callApi<MeetingAction[]>("/api/meeting/actions", "GET");
  console.log(`Get MeetingAction response ${response.status}`);
  return checkResponse<MeetingAction[]>(response);
}

export async function CallRaiseMyMeetingAction(forAgainst: string, text: string, type: string): Promise<MeetingAction> {
  const response = await callApi<MeetingAction>("/api/meeting/meetingaction", "POST", { forAgainst: forAgainst, text: text, type: type });
  console.log(`Raise MeetingObjection response ${response.status}`);
  return checkResponse<MeetingAction>(response);
}

export async function CallGetMeetingAction(subId: string): Promise<MeetingAction> {
  const response = await callApi<MeetingAction>(`/api/meeting/action/${subId}`, "GET");
  console.log(`Call Get MeetingObjection response ${response.status}`);
  return checkResponse<MeetingAction>(response);
}

export async function CallUpdateMeetingActionSpeakingStatus(subId: string, newSpeakingStatus: string): Promise<MeetingAction> {
  const response = await callApi<MeetingAction>(`/api/meeting/action/${subId}/${newSpeakingStatus}`, "PATCH");
  console.log(`Raise MeetingObjection response ${response.status}`);
  return checkResponse<MeetingAction>(response);
}

export async function removeMyMeetingAction(action: MeetingAction): Promise<MeetingAction> {
  const response = await callApi<MeetingAction>(`/api/meeting/myaction/${action.subId}`, "DELETE");
  console.log(`Remove My Meeting Action response ${response.status}`);
  // delete doesn't return it, the assumption is if it is sucess it was a valid action to close
  response.data = action;
  return checkResponse<MeetingAction>(response);
}

export async function closeMeetingAction(action: MeetingAction): Promise<MeetingAction> {
  const response = await callApi<MeetingAction>(`/api/meeting/action/${action.subId}`, "DELETE");
  console.log(`Close MeetingObjection response ${response.status}`);
  // delete doesn't return it, the assumption is if it is sucess it was a valid action to close
  response.data = action;
  return checkResponse<MeetingAction>(response);
}

export class UnauthorisedError extends Error {
  constructor(msg: string) {
    super(msg);
    Object.setPrototypeOf(this, UnauthorisedError.prototype);
  }
}

// TODO: make this function more generic and move to ApiUtilities
export function checkResponse<Type>(response: ApiResponse<Type>): Type {
  if (response.status === ApiStatus.Ok && response.data != null && response.data) {
    return response.data as Type;
  } else if (response.status === ApiStatus.Unauthorised) {
    throw new UnauthorisedError(`Not logged in, try refreshing page (${response.message})`);
  } else if (response.status === ApiStatus.Forbidden) {
    throw Error(`Permission denied (${response.message})`);
  } else if (response.status === ApiStatus.BadRequest) {
    throw Error(response.message);
  } else if (response.status === ApiStatus.NotFound) {
    // MIGHT NEED THIS BACK: || !response.data) {
    throw Error("There is no active meeting, please check back later");
  } else {
    throw Error("An Unknown API error occured. Response " + response.status + " - " + response.message);
  }
}

// export async function updateMyDetails(user: User): Promise<User> {
//   const response = await callApi<User>("/api/user", "PATCH", user);
//   console.log(`API response ${response.status}`);
//   if (response.status === ApiStatus.Ok) {
//     return response.data as User;
//   } else if (response.status === ApiStatus.Unauthorised) {
//     throw Error(`Not logged in, try refreshing page (${response.message}`);
//   } else {
//     throw Error("An Unknown API error occured. Response " + response.status + "- " + response.message);
//   }
// }

// export async function getUsers(): Promise<User[]> {
//   const response = await callApi<User[]>("/api/useradmin", "GET");
//   console.log(`Get Sessions response ${response.status}`);
//   if (response.status === ApiStatus.Ok) {
//     return response.data as User[];
//   } else if (response.status === ApiStatus.Unauthorised) {
//     throw Error(`Not logged in, try refreshing page (${response.message}`);
//   } else {
//     throw Error("An Unknown API error occured. Response " + response.status + "- " + response.message);
//   }
// }

// export async function saveUser(session: User): Promise<User> {
//   const response = await callApi<User>("/api/useradmin", "POST", session);
//   if (response.status === ApiStatus.Ok) {
//     return response.data as User;
//   } else if (response.status === ApiStatus.Unauthorised) {
//     throw Error(`Not logged in, try refreshing page (${response.message}`);
//   } else {
//     throw Error("An Unknown API error occured. Response " + response.status + "- " + response.message);
//   }
// }
