import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import YouTube, { YouTubeProps } from "react-youtube";
// TODO: remove Options, PlayerVars
import { CallGetMeetingAction, removeMyMeetingAction, UnauthorisedError } from "../../api/MeetingApi";
import { CallGetLiveStreamDetails } from "../../api/VideoApi";
import { AutoClearMyMeetingActions } from "../../common/Constants";
import { MeetingActionStatuses, MeetingActionTypes } from "../../data/MeetinAction";
import { clearMyMeetingAction, logoutAction, updateLiveStreamAction, updateMyMeetingAction } from "../../state/actions";
import { SLinkContext } from "../../state/context";
import ObsInstructions from "./ObsInstructions";
import "./LivestreamView.css";
import { useLocation } from "react-router";
import { UserRoles } from "../../state/state";

const defaultOpts: YouTubeProps["opts"] = {
  height: "390",
  width: "640",
  playerVars: {
    // https://developers.google.com/youtube/player_parameters
    autoplay: 1,
    controls: 1,
    enablejsapi: 1,
    modestbranding: 1,
    rel: 0,
    fs: 1,
  },
};

function LivestreamView() {
  const { state, dispatch } = useContext(SLinkContext);
  const [opts, setOpts] = useState(defaultOpts);
  const location = useLocation();
  const [showLivestream, setShowLivestream] = useState(!state.user?.userRoles.includes(UserRoles.Organiser));

  useEffect(() => {
    CallGetLiveStreamDetails()
      .catch((err) => {
        toast.error("Error getting livestream details, please refresh your browser window " + err.message);
      })
      .then((response) => {
        console.log(`Livestream details received ${response} `);
        console.log(`Livestream details received ${response?.youTubeVideoId} `);
        if (response) {
          dispatch(updateLiveStreamAction(response));
        }
      });
  }, [dispatch]);

  function switchVideoSize() {
    if (opts.height === "390") {
      setOpts({ ...defaultOpts, height: "720", width: "1280" });
    } else {
      setOpts({ ...defaultOpts });
    }
  }

  // *****************************************************************************
  // Point of Order Refresh Function
  useEffect(() => {
    function callRefresh() {
      if (state.myPointOfOrder) {
        CallGetMeetingAction(state.myPointOfOrder?.subId)
          .then((data) => {
            if (data.speakingStatus !== state.myPointOfOrder?.speakingStatus) {
              dispatch(updateMyMeetingAction(data));
            }
            // increment counter to eventually auto clear my point of order
            if (data.status === MeetingActionStatuses.Closed) {
              // clear the existing timer
              clearInterval(id);
              // create new one off timer to clear the point of order
              id = setTimeout(() => {
                if (state.myPointOfOrder) {
                  dispatch(clearMyMeetingAction(MeetingActionTypes.PointOfOrder));
                }
              }, AutoClearMyMeetingActions);
            }
          })
          .catch((err) => {
            if (err instanceof UnauthorisedError) {
              dispatch(logoutAction());
            }
            console.error(`Error refreshing my meeting action point of order ${err.message}`);
          });
      }
    }
    let id: NodeJS.Timer;
    if (state.myPointOfOrder) {
      id = setInterval(() => {
        callRefresh();
      }, 5000);
    }
    return () => clearInterval(id);
  }, [state.myPointOfOrder, dispatch]);

  // *****************************************************************************
  // Ask to Speak Refresh Function
  useEffect(() => {
    function callRefresh() {
      if (state.myAskToSpeak) {
        CallGetMeetingAction(state.myAskToSpeak?.subId)
          .then((data) => {
            if (data.speakingStatus !== state.myAskToSpeak?.speakingStatus) {
              dispatch(updateMyMeetingAction(data));
            }
            // increment counter to eventually auto clear my point of order
            if (data.status === MeetingActionStatuses.Closed) {
              // clear the existing timer
              clearInterval(id);
              // create new one off timer to clear the point of order
              id = setTimeout(() => {
                if (state.myAskToSpeak) {
                  dispatch(clearMyMeetingAction(MeetingActionTypes.AskToSpeak));
                }
              }, AutoClearMyMeetingActions);
            }
          })
          .catch((err) => {
            if (err instanceof UnauthorisedError) {
              dispatch(logoutAction());
            }
            console.error(`Error refreshing my meeting action ask to speak ${err.message}`);
          });
      }
    }
    let id: NodeJS.Timer;
    if (state.myAskToSpeak) {
      id = setInterval(() => {
        callRefresh();
      }, 5000);
    }
    return () => clearInterval(id);
  }, [state.myAskToSpeak, dispatch]);

  // *****************************************************************************
  // Ask to Speak Refresh Function
  useEffect(() => {
    function callRefresh() {
      if (state.mySuggestAmendment) {
        CallGetMeetingAction(state.mySuggestAmendment?.subId)
          .then((data) => {
            if (data.speakingStatus !== state.mySuggestAmendment?.speakingStatus) {
              dispatch(updateMyMeetingAction(data));
            }
            // increment counter to eventually auto clear my point of order
            if (data.status === MeetingActionStatuses.Closed) {
              // clear the existing timer
              clearInterval(id);
              // create new one off timer to clear the point of order
              id = setTimeout(() => {
                if (state.mySuggestAmendment) {
                  dispatch(clearMyMeetingAction(MeetingActionTypes.SuggestAmendment));
                }
              }, AutoClearMyMeetingActions);
            }
          })
          .catch((err) => {
            if (err instanceof UnauthorisedError) {
              dispatch(logoutAction());
            }
            console.error(`Error refreshing my meeting action ask to speak ${err.message}`);
          });
      }
    }
    let id: NodeJS.Timer;
    if (state.mySuggestAmendment) {
      id = setInterval(() => {
        callRefresh();
      }, 5000);
    }
    return () => clearInterval(id);
  }, [state.mySuggestAmendment, dispatch]);

  // *****************************************************************************
  // LOCAL FUNCTIONS
  function closeMyPointOfOrder() {
    if (state.myPointOfOrder) {
      removeMyMeetingAction(state.myPointOfOrder)
        .then(() => {
          dispatch(clearMyMeetingAction(MeetingActionTypes.PointOfOrder));
        })
        .catch((error: Error) => {
          if (error instanceof UnauthorisedError) {
            dispatch(logoutAction());
          }
          toast.error(`Call to close my point of order failed with error ${error.message}`);
        });
    }
  }
  function closeMyAskToSpeak() {
    if (state.myAskToSpeak) {
      removeMyMeetingAction(state.myAskToSpeak)
        .then(() => {
          dispatch(clearMyMeetingAction(MeetingActionTypes.AskToSpeak));
        })
        .catch((error: Error) => {
          if (error instanceof UnauthorisedError) {
            dispatch(logoutAction());
          }
          toast.error(`Call to close my ask to speak failed with error ${error.message}`);
        });
    }
  }
  function closeMySuggestAmendment() {
    if (state.mySuggestAmendment) {
      removeMyMeetingAction(state.mySuggestAmendment)
        .then(() => {
          dispatch(clearMyMeetingAction(MeetingActionTypes.SuggestAmendment));
        })
        .catch((error: Error) => {
          if (error instanceof UnauthorisedError) {
            dispatch(logoutAction());
          }
          toast.error(`Call to close my suggested amendment failed with error ${error.message}`);
        });
    }
  }

  function onReady(event: any) {
    // access to player in all event handlers via event.target
    event.target.playVideo();
  }
  function obsLink() {
    return state.myPointOfOrder?.obsGuestLink ?? state.myAskToSpeak?.obsGuestLink ?? "";
  }

  if (state.youTubeVideoId === "") {
    return <></>;
  }

  return (
    <div className="m-0 p-0">
      <div className="row m-0 p-0">
        {state.myPointOfOrder?.obsGuestLink || state.mySuggestAmendment?.obsGuestLink || state.myAskToSpeak?.obsGuestLink ? (
          <div className="col-md m-0 p-0">
            <iframe
              title="particpantCapture"
              allow="autoplay;camera;microphone;fullscreen;picture-in-picture;"
              src={state.myPointOfOrder?.obsGuestLink || state.mySuggestAmendment?.obsGuestLink || state.myAskToSpeak?.obsGuestLink}
              height={390}
              width={640}
            ></iframe>
          </div>
        ) : state.youTubeVideoId && showLivestream ? (
          !state.meetingStatus?.askIsInPerson || !state.user?.isInPerson ? (
            <div className="col-md m-0 p-0">
              <YouTube videoId={state.youTubeVideoId} onReady={onReady} opts={opts} />
            </div>
          ) : (
            <div className="col-md m-0 p-0">Livestream disabled when in person</div>
          )
        ) : (
          <></>
        )}
        <div className="col-lg m-2">
          {!state.meetingStatus?.askIsInPerson || !state.user?.isInPerson ? (
            <div className="row m-0 p-0">
              <div className="col">
                <button type="button" className="btn btn-light m-1 p-1 float-end" onClick={() => setShowLivestream(!showLivestream)}>
                  {showLivestream ? "Disable Livestream" : "Show Livestream"}
                </button>
                <button type="button" className="btn btn-light m-1 p-1 float-end" onClick={switchVideoSize}>
                  Change Video Size
                </button>
              </div>
            </div>
          ) : (
            <></>
          )}
          {state.myAskToSpeak || state.myPointOfOrder || state.mySuggestAmendment ? (
            <>
              <div className="row">
                {state.myPointOfOrder ? (
                  <div className="">
                    <h6 title={`${state.myPointOfOrder.subId} - ${state.myPointOfOrder.status}`}>
                      Point of order raised - ({state.myPointOfOrder.speakingStatus})
                      <button type="button" className="btn btn-light ms-3 me-1 mb-1 px-2" onClick={closeMyPointOfOrder}>
                        Remove Request
                      </button>
                    </h6>
                    <ObsInstructions speakingStatus={state.myPointOfOrder.speakingStatus} />
                  </div>
                ) : (
                  <></>
                )}
              </div>
              <div className="row">
                {state.myAskToSpeak ? (
                  <div className="">
                    <h5 title={`${state.myAskToSpeak.subId} - ${state.myAskToSpeak.status}`}>
                      Ask to Speak raised ({state.myAskToSpeak.speakingStatus})
                      <button type="button" className="btn btn-light ms-3 me-1 mb-1 px-2" onClick={closeMyAskToSpeak}>
                        Remove Request
                      </button>
                    </h5>
                    <ObsInstructions speakingStatus={state.myAskToSpeak.speakingStatus} />
                  </div>
                ) : (
                  <></>
                )}
              </div>
              <div className="row">
                {state.mySuggestAmendment ? (
                  <div className="">
                    <h5 title={`${state.mySuggestAmendment.subId} - ${state.mySuggestAmendment.status}`}>
                      Suggest Amendment raised ({state.mySuggestAmendment.speakingStatus})
                      <button type="button" className="btn btn-light ms-3 me-1 mb-1 px-2" onClick={closeMySuggestAmendment}>
                        Remove Request
                      </button>
                    </h5>
                    <ObsInstructions speakingStatus={state.mySuggestAmendment.speakingStatus} />
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </>
          ) : location.pathname !== "/dashboard/meeting" && location.pathname !== "/dashboard/meetingadmin" ? (
            <>
              <div>
                <p>If a meeting of Synod is in session, click the Meeting tab above to participate in the meeting and view the current item of business.</p>
                <p>You may need to click the video to make it play the first time if the video does not auto play on your device.</p>
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
}

export default LivestreamView;
