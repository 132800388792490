import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { isatty } from "tty";
import { FormatHelper } from "../../api/FormatHelper";
import { deleteItem, getItems, saveItem } from "../../api/ItemsApi";
import { setMeetingItem } from "../../api/MeetingApi";
import ContainerCard from "../../common/CardContainer";
import ErrorDisplay from "../../common/ErrorDisplay";
import { Item, ItemStatuses, ItemTypes } from "../../data/ItemOfBusiness";
import { updateMeetingStatusAction } from "../../state/actions";
import { SLinkContext } from "../../state/context";
import { UserRoles } from "../../state/state";
import ItemEdit from "./ItemEdit";
import ItemsList from "./ItemsList";

function ItemsAdmin() {
  const { state, dispatch } = useContext(SLinkContext);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [items, setItems] = useState([] as Item[]);
  const [item, setItem] = useState(null as Item | null);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    getItems()
      .then((list) => {
        setItems(list);
      })
      .catch((error) => {
        console.log("Error " + JSON.stringify(error));
        setError("Error: " + error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (state.user) {
      setIsAdmin(state.user.userRoles.includes(UserRoles.Admin));
    }
  }, [state.user]);

  function setCurrentMeetingItem(item: Item) {
    setMeetingItem(item.subId)
      .then((status) => {
        dispatch(updateMeetingStatusAction(status));
      })
      .catch((error: Error) => {
        toast.error("Error setting current meeting item " + error.message);
      });
  }

  function onEdit(item: Item) {
    console.log("Set Editing item");
    setItem(null);
    setItem(item);
  }

  function onCreateAmendment(item: Item) {
    console.log("Set Editing item");
    setItem(null);
    var n = item.code + 0.01;
    const num = Math.round((Number(item.code) + 0.01) * 100) / 100;
    const ord = Math.round((item.order + 0.01) * 100) / 100;
    setItem({ ...item, id: "", subId: "", previousAmendmentId: item.subId, type: ItemTypes.Amendment, status: ItemStatuses.New, code: num.toString(), order: ord });
  }

  function onNew() {
    let orderValue = 1;
    if (items && items.length > 0) {
      orderValue =
        1 +
        Math.floor(
          Math.max.apply(
            Math,
            items.map(function (i) {
              return i.order;
            })
          )
        );
    }
    setItem({
      id: "",
      subId: "",
      code: `${orderValue}.0`,
      order: orderValue,
      heading: "New Item of Business",
      text: "",
      originalText: "",
      diffMode: "Line",
      type: ItemTypes.OriginalMotion,
      status: ItemStatuses.New,
      votes: 0,
      voteCounts: [],
      endTime: FormatHelper.getDateNow(),
      startTime: FormatHelper.getDateNow(),
      voteRequestedTime: FormatHelper.getDateNow(),
      voteClosedTime: FormatHelper.getDateNow(),
      votingHouse: "",
      nextAmendmentId: "",
      previousAmendmentId: "",
      moverUserId: "",
      seconderUserId: "",
    });
  }

  function onCancel() {
    setItem(null);
  }

  async function onDelete(): Promise<string> {
    if (!item) {
      return "No item to delete";
    }
    try {
      await deleteItem(item);
      var newList = items.filter((i) => i.subId !== item.subId);
      setItems(newList);
      setItem(null);
      return "";
    } catch (error: any) {
      return error.message ?? "An Unknown API error occured.";
    }
  }

  async function onSave(
    heading: string,
    text: string,
    code: number,
    order: number,
    type: string,
    originalText: string,
    diffMode: string,
    moverUserId: string,
    seconderUserId: string
  ): Promise<string> {
    if (!item) {
      return "No Item to save";
    }
    console.log(`Saving item: ${item.subId} heading: ${item.heading}`);
    var itemToSave = {
      ...item,
      type: type,
      text: text,
      originalText: originalText,
      diffMode: diffMode,
      heading: heading,
      code: code.toString(),
      order: order,
      moverUserId: moverUserId,
      seconderUserId: seconderUserId,
    } as Item;
    try {
      var returnItem = await saveItem(itemToSave);

      console.log("Returned data: " + returnItem);
      var newList = items.map((item) => {
        return item.subId === returnItem.subId ? returnItem : item;
      });
      if (item.id === "") {
        newList.push(returnItem);
      }
      setItem(null);
      setItems(newList);
      return "";
    } catch (error) {
      return error instanceof Error && error.message ? error.message : "An Unknown API error occured.";
    }
  }

  return (
    <ContainerCard heading={item ? "Edit Item of Business" : "Items of Business"}>
      <ErrorDisplay error={error} />
      {item === null ? (
        <>
          <div className="mt-3 mb-2">
            <button onClick={onNew} className="btn btn-success">
              New Item
            </button>
          </div>
          <ItemsList list={items} onEdit={onEdit} onAmendItem={onCreateAmendment} setCurrentMeetingItem={setCurrentMeetingItem} isAdmin={isAdmin} />
          <div className="mt-3 mb-2">
            <button onClick={onNew} className="btn btn-success">
              New Item
            </button>
          </div>
        </>
      ) : (
        <></>
      )}
      {item ? <ItemEdit item={item} onSave={onSave} onCancel={onCancel} onDelete={onDelete} isAdmin={isAdmin} /> : <></>}
    </ContainerCard>
  );
}

export default ItemsAdmin;
