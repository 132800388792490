import { User } from "../data/User";
import { ApiResponse, ApiStatus, callApi } from "./ApiUtilities";
import { GetSessionInPersonValue, SetSessionInPersonValue } from "./Session";

export async function updateMyDetails(user: User): Promise<ApiResponse<User>> {
  var sessionIsInPerson = GetSessionInPersonValue();
  if (user.isInPerson !== sessionIsInPerson) {
    SetSessionInPersonValue(user.isInPerson);
  }
  const response = await callApi<User>("/api/user", "PATCH", user);
  console.log(`API response ${response.status}`);
  if (response.status === ApiStatus.Ok) {
    return response;
  } else if (response.status === ApiStatus.Unauthorised) {
    return response;
  } else {
    throw Error("An Unknown API error occured. Response " + response.status + "- " + response.message);
  }
}

export async function getUsers(): Promise<User[]> {
  const response = await callApi<User[]>("/api/useradmin", "GET");
  console.log(`Get Sessions response ${response.status}`);
  if (response.status === ApiStatus.Ok) {
    return response.data as User[];
  } else if (response.status === ApiStatus.Unauthorised) {
    throw Error(`Not logged in, try refreshing page (${response.message}`);
  } else {
    throw Error("An Unknown API error occured. Response " + response.status + "- " + response.message);
  }
}

export async function saveUser(user: User): Promise<ApiResponse<User>> {
  const response = await callApi<User>("/api/useradmin", "POST", user);
  if (response.status === ApiStatus.Ok) {
    return response;
  } else if (response.status === ApiStatus.BadRequest) {
    return response;
  } else if (response.status === ApiStatus.Unauthorised) {
    throw Error(`Not logged in, try refreshing page (${response.message}`);
  } else {
    throw Error("An Unknown API error occured. Response " + response.status + "- " + response.message);
  }
}

export async function callDeleteUser(user: User): Promise<ApiResponse<User>> {
  const response = await callApi<User>("/api/useradmin", "DELETE", user);
  if (response.status === ApiStatus.Ok) {
    return response;
  } else if (response.status === ApiStatus.BadRequest) {
    return response;
  } else if (response.status === ApiStatus.Unauthorised) {
    throw Error(`Not logged in, try refreshing page (${response.message}`);
  } else {
    throw Error("An Unknown API error occured. Response " + response.status + "- " + response.message);
  }
}
