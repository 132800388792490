import React, { ChangeEvent, SyntheticEvent, useContext, useEffect, useState } from "react";
import MDEditor from "@uiw/react-md-editor";
import { Button, Modal, ToggleButton } from "react-bootstrap";
import { Navigate } from "react-router-dom";
import { CallRaiseMyMeetingAction, CallCheckMeetingStatus, getMeetingStatus, UnauthorisedError } from "../../api/MeetingApi";
import ErrorDisplay from "../../common/ErrorDisplay";
import LoadingSpinner from "../../common/LoadingSpinner";
import { Item, ItemTypes } from "../../data/ItemOfBusiness";
import { clearMyMeetingAction, logoutAction, updateMeetingStatusAction, updateMyMeetingAction } from "../../state/actions";
import { SLinkContext } from "../../state/context";
import VoteDisplay from "../meetingadmin/VoteDisplay";
import { GetItemMarkdown } from "../meetingcommon/MeetingFunctions";
import "./diffstyles.css";
import { toast } from "react-toastify";
import { AutoClearMyMeetingActions } from "../../common/Constants";
import CardContainer from "../../common/CardContainer";
import { MeetingAction, MeetingActionTypes } from "../../data/MeetinAction";
import BusinessItemCard from "../meetingcommon/BusinessItemCard";

function MeetingView() {
  const { state, dispatch } = useContext(SLinkContext);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  // meeting action state
  const [showMeetingActionDialog, setShowMeetingActionDialog] = useState(false);
  const [askToSpeakText, setAskToSpeakText] = useState("");
  const [askToSpeakForAgainst, setAskToSpeakForAgainst] = useState("");
  const [meetingActionType, setMeetingActionType] = useState("");
  const [refreshDisabled, setRefreshDisabled] = useState(false);
  const [callingRefresh, setCallingRefresh] = useState(false);

  // TODO: move this useEffect to logged in layout so it runs once on login and then only as required by different screens
  useEffect(() => {
    getMeetingStatus()
      .then((status) => {
        dispatch(updateMeetingStatusAction(status));
      })
      .catch((error: Error) => {
        if (error instanceof UnauthorisedError) {
          dispatch(logoutAction());
        }
        setError(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    let id: NodeJS.Timer;
    if (state.myObjection) {
      id = setTimeout(() => {
        if (state.myObjection) {
          dispatch(clearMyMeetingAction(MeetingActionTypes.Objection));
        }
      }, AutoClearMyMeetingActions);
    }
    return () => clearInterval(id);
  }, [state.myObjection]);

  /// ********************************************
  function RaiseMyMeetingAction(type: string) {
    setMeetingActionType(type);
    setShowMeetingActionDialog(true);
  }
  function CancelMyMeetingAction() {
    setAskToSpeakText("");
    setMeetingActionType("");
    setAskToSpeakForAgainst("");
    setShowMeetingActionDialog(false);
  }
  function ConfirmMyMeetingAction() {
    setLoading(true);
    // TODO: !!! update to include type
    CallRaiseMyMeetingAction(askToSpeakForAgainst, askToSpeakText, meetingActionType)
      .then((responseAction: MeetingAction) => {
        // TODO: save to local storage
        setShowMeetingActionDialog(false);
        dispatch(updateMyMeetingAction(responseAction));
      })
      .catch((error: Error) => {
        toast.error(`Error raising ask to speak request (${error.message})`);
      })
      .finally(() => {
        setLoading(false);
        setAskToSpeakText("");
        setAskToSpeakForAgainst("");
      });
  }
  function setForAgainst(ev: ChangeEvent<HTMLInputElement>) {
    if (ev.target.checked) {
      setAskToSpeakForAgainst(ev.target.value);
    } else {
      setAskToSpeakForAgainst("");
    }
  }
  /// ********************************************
  function CloseMyObjection() {
    dispatch(clearMyMeetingAction(MeetingActionTypes.Objection));
  }
  /// ********************************************
  function refresh(event: SyntheticEvent) {
    setRefreshDisabled(true);
    setCallingRefresh(true);
    event.preventDefault();
    CallCheckMeetingStatus()
      .then((status) => {
        let isNewVersion = state.meetingStatus ? state.meetingStatus.version < status.version : true;
        dispatch(updateMeetingStatusAction(status));
        toast.success(`Meeting status refreshed (v${status.version} - ${status.lastUpdate} new ${isNewVersion})`);
      })
      .catch((error: Error) => {
        toast.error(`Error on refreshing state '${error.message}'`);
      })
      .finally(() => {
        setCallingRefresh(false);
        setTimeout(() => {
          setRefreshDisabled(false);
        }, 10000);
      });
  }
  function GetDisplayItem(): Item | null {
    return state.meetingStatus?.currentItem ?? null;
  }

  if (!loading && state.meetingStatus === null) {
    return <Navigate to="/dashboard" />;
  }
  if (!loading && !state.meetingStatus?.enableParticipantView) {
    return (
      <CardContainer heading="No Meeting in Progress">
        <div>There is no meeting in progress, please check back later.</div>
      </CardContainer>
    );
  }

  if (loading && state.meetingStatus === null) {
    return <p>Loading, please wait...</p>;
  }

  console.log("Rendering Meeting View");

  return (
    <div>
      <div className="row align-items-start">
        <div className="col px-1">
          {loading ? <LoadingSpinner /> : <></>}
          <ErrorDisplay error={error} />

          <BusinessItemCard item={GetDisplayItem()}>
            <div className="mt-1">
              <div className="row p-2">
                {state.meetingStatus?.currentItem?.voteCounts && state.meetingStatus.currentItem.voteCounts.length > 0 ? (
                  <div className="col-lg-4 p-1">
                    <VoteDisplay item={GetDisplayItem()} />
                  </div>
                ) : (
                  <></>
                )}
                <div className="col p-1 px-2 m-1">
                  <MDEditor.Markdown source={GetItemMarkdown(state.meetingStatus, false)} />
                </div>
                <div className="col-lg-2 col-md-2 col-sm-4 p-1">
                  <div>
                    <button type="button" className="btn btn-light m-1 px-2" style={{ width: "170px" }} onClick={refresh} disabled={callingRefresh || refreshDisabled}>
                      Refresh
                    </button>
                  </div>
                  {state.meetingStatus?.enableParticipantButtons ? (
                    <>
                      {!state.meetingStatus?.askIsInPerson || !state.user?.isInPerson ? (
                        <div>
                          <button
                            type="button"
                            style={{ width: "170px" }}
                            className="btn btn-primary m-1 px-2"
                            onClick={(e) => {
                              RaiseMyMeetingAction(MeetingActionTypes.PointOfOrder);
                              e.preventDefault();
                            }}
                          >
                            Point of Order
                          </button>
                        </div>
                      ) : (
                        <></>
                      )}
                      <div>
                        <button
                          type="button"
                          style={{ width: "170px" }}
                          className="btn btn-primary m-1 px-2"
                          onClick={(e) => {
                            RaiseMyMeetingAction(MeetingActionTypes.Objection);
                            e.preventDefault();
                          }}
                        >
                          Raise Objection
                        </button>
                      </div>
                      {!state.meetingStatus?.askIsInPerson || !state.user?.isInPerson ? (
                        <div>
                          <button
                            type="button"
                            style={{ width: "170px" }}
                            className="btn btn-primary m-1 px-2"
                            onClick={(e) => {
                              RaiseMyMeetingAction(MeetingActionTypes.SuggestAmendment);
                              e.preventDefault();
                            }}
                          >
                            Suggest Amendment
                          </button>
                        </div>
                      ) : (
                        <></>
                      )}
                      {!state.meetingStatus?.askIsInPerson || !state.user?.isInPerson ? (
                        <div>
                          <button
                            type="button"
                            style={{ width: "170px" }}
                            className="btn btn-primary m-1 px-2"
                            onClick={(e) => {
                              RaiseMyMeetingAction(MeetingActionTypes.AskToSpeak);
                              e.preventDefault();
                            }}
                          >
                            Ask to Speak
                          </button>
                        </div>
                      ) : (
                        <></>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                  {state.myObjection ? (
                    <>
                      <div>
                        You have raised an <br /> objection{" "}
                        <button type="button" className="btn btn-link m-0 p-0" onClick={CloseMyObjection}>
                          Hide
                        </button>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </BusinessItemCard>
        </div>
      </div>
      {showMeetingActionDialog ? (
        <Modal show={showMeetingActionDialog} onHide={CancelMyMeetingAction}>
          <Modal.Header>
            <Modal.Title>
              {meetingActionType === MeetingActionTypes.AskToSpeak
                ? "Ask to Speak"
                : meetingActionType === MeetingActionTypes.PointOfOrder
                ? "Raise Point of Order"
                : meetingActionType === MeetingActionTypes.Objection
                ? "Submit Objection"
                : meetingActionType === MeetingActionTypes.SuggestAmendment
                ? "Suggest Amendment"
                : "Unknown"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              {meetingActionType === MeetingActionTypes.AskToSpeak
                ? "If you click submit it will put you in a queue, and you will have the oportunity to speak."
                : meetingActionType === MeetingActionTypes.PointOfOrder
                ? "If you click submit it will take you to the live room to raise your point of order."
                : meetingActionType === MeetingActionTypes.Objection
                ? "Are you sure you wish to submit an objection?"
                : meetingActionType === MeetingActionTypes.SuggestAmendment
                ? "If you click submit, it will put you in a queue to speak to your suggested amendment."
                : "Unknown"}
            </p>
            {meetingActionType === MeetingActionTypes.AskToSpeak ? (
              <div>
                <ToggleButton variant="success" type="checkbox" id="For" value="For" onChange={setForAgainst} checked={askToSpeakForAgainst === "For"} className="me-2">
                  For
                </ToggleButton>
                <ToggleButton variant="danger" type="checkbox" id="Against" value="Against" onChange={setForAgainst} checked={askToSpeakForAgainst === "Against"} className="ms-2">
                  Against
                </ToggleButton>
              </div>
            ) : (
              <></>
            )}
            {meetingActionType === MeetingActionTypes.SuggestAmendment ? (
              <>
                <div>
                  <label>{meetingActionType === MeetingActionTypes.SuggestAmendment ? "Suggested amendment text" : "Supporting text"}</label>
                </div>
                <div>
                  <textarea onChange={(e) => setAskToSpeakText(e.target.value)} rows={5} cols={46}>
                    {askToSpeakText}
                  </textarea>
                </div>
              </>
            ) : (
              <></>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={CancelMyMeetingAction}>
              Close
            </Button>
            <Button variant="primary" onClick={ConfirmMyMeetingAction}>
              Submit
            </Button>
          </Modal.Footer>
        </Modal>
      ) : (
        <></>
      )}
    </div>
  );
}

export default MeetingView;
