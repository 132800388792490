import React from "react";
import { Item } from "../../data/ItemOfBusiness";

export interface VoteDisplayProps {
  item: Item | null;
}

function VoteDisplay({ item }: VoteDisplayProps) {
  if (item == null || item.votes === 0 || item.voteCounts == null) {
    return <></>;
  }

  return (
    <div className="table-responsive-md">
      {/* {item.votes > 0 ? `Total Votes: ${item.votes}` : ""} */}
      <table className="table" width="450">
        <thead>
          <tr>
            <th>House&nbsp;&nbsp;&nbsp;&nbsp;</th>
            <th className="text-end">Total</th>
            <th className="text-end">For</th>
            <th className="text-end">Against</th>
            <th className="text-end">Abstain</th>
          </tr>
        </thead>
        <tbody>
          {item.voteCounts.map((count) => {
            return count.house === "Bishops" ? (
              <></>
            ) : (
              <tr key={`${item.subId}.${count.house}`} className={count.house === "Total" ? "table-light fw-bold" : ""}>
                <td>{count.house}</td>
                <td className="text-end">
                  {/* {count.total}/{count.usersAtTime} */}
                  {count.total}
                </td>
                <td className="text-end">{count.votesFor}</td>
                <td className="text-end">{count.votesAgainst}</td>
                <td className="text-end">{count.votesAbstain}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default VoteDisplay;
