import { Generator } from "./generator";

export function markdownDiff(oldStr: string, newStr: string, diffMode: string = "Word"): string {
  //console.log("old str: " + oldStr);
  //console.log("new str: " + newStr);
  return new Generator().exec(getWrappedString(oldStr), getWrappedString(newStr), diffMode);
}

function getWrappedString(inStr: string): string {
  return inStr;
  return `----------\n ${inStr} \n\n`;
}
