export interface SocketMessage<T> {
  type: string;
  data: T | null;
}
export interface SocketIdentity {
  securityToken: string;
  userId: string;
}
export interface SendSocketMessage<T> {
  message: string;
  type: string;
  data: T | null;
}

export enum SocketMessageTypes {
  MeetingStatusUpdate = "MeetingStatusUpdate",
  MeetingActionRaised = "MeetingActionRaised",
  MeetingActionClosed = "MeetingActionClosed",
  MeetingActionUpdated = "MeetingActionUpdated",
  LiveStreamUpdate = "LiveStreamUpdate",
  UserCountUpdate = "UserCountUpdate",
  SocketIdentityIn = "SocketIdentity",
  Identity = "Identity",
  Pong = "Pong",
}
