import "./App.css";
import { Navigate, Route, Routes } from "react-router-dom";
import LoggedInLayout from "./layout/LoggedInLayout";
import LoggedOutLayout from "./layout/LoggedOutLayout";
import PageNotFound from "./views/PageNotFound";
import { SLinkContext } from "./state/context";
import { useEffect, useReducer, useState } from "react";
import { initialSlState } from "./state/state";
import { sLinkReducer } from "./state/reducer";
import { initaliseLoggedInUser } from "./api/AuthApi";
import ParticipantDashboardView from "./views/ParticipantDashboardView";
import MyDetailsView from "./views/MyDetailsView";
import SessionAdmin from "./components/sessionadmin/SessionAdmin";
import ItemsAdmin from "./components/items/ItemsAdmin";
import UserAdmin from "./components/useradmin/UserAdmin";
//import SystemSettings from "./views/SystemSettings";
import MeetingAdminView from "./components/meetingadmin/MeetingAdminView";
import MeetingView from "./components/meetingview/MeetingView";
import VideoSettingsAdmin from "./components/videoadmin/VideoSettingsAdmin";
import LoginView from "./views/LoginView";
import ForgotView from "./views/ForgotView";
import ResetPasswordView from "./views/ResetPasswordView";
import SystemAdmin from "./components/system/SystemAdmin";

function App() {
  const [state, dispatch] = useReducer(sLinkReducer, initialSlState);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function checkAuthenticated() {
      console.log("initalise Authorisation State (USER)");
      await initaliseLoggedInUser(state.user, dispatch);
      setLoading(false);
    }

    checkAuthenticated();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  console.log("loading: " + loading);
  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <SLinkContext.Provider value={{ state, dispatch }}>
      <Routes>
        <Route path="/login" element={<LoggedOutLayout />}>
          <Route index element={<LoginView />} />
          <Route path="forgot" element={<ForgotView />} />
          <Route path="reset/:token1/:token2" element={<ResetPasswordView />} />
          <Route path="*" element={<PageNotFound />} />
        </Route>
        <Route path="/dashboard" element={<LoggedInLayout />}>
          <Route index element={<ParticipantDashboardView />} />
          <Route path="meetingadmin" element={<MeetingAdminView />} />
          <Route path="meeting" element={<MeetingView />} />
          <Route path="my-details" element={<MyDetailsView />} />
          <Route path="*" element={<PageNotFound />} />
        </Route>
        <Route path="/admin" element={<LoggedInLayout />}>
          <Route path="items" element={<ItemsAdmin />} />
          <Route path="sessions" element={<SessionAdmin />} />
          {/* <Route path="system" element={<SystemSettings />} /> */}
          <Route path="video-settings" element={<VideoSettingsAdmin />} />
          <Route path="users" element={<UserAdmin />} />
          <Route path="/admin/system" element={<SystemAdmin />} />
          <Route path="*" element={<PageNotFound />} />
        </Route>
        {/* Redirect Routes */}
        <Route path="/logout" element={<Navigate to="/login" />} />
        <Route path="/" element={<Navigate to="/login" />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </SLinkContext.Provider>
  );
}

export default App;
