import React from "react";
import PropTypes from "prop-types";

interface IProps {
  error: string;
}

function ErrorDisplay({ error }: IProps) {
  if (error) {
    return (
      <>
        <div className="checkbox mb-3" style={{ color: "red" }}>
          {error}
        </div>
      </>
    );
  } else {
    return <></>;
  }
}

ErrorDisplay.propTypes = {
  error: PropTypes.string.isRequired,
};

export default ErrorDisplay;
