import { LiveStreamDetails } from "../data/LiveStreamDetails";
import { VideoSettings } from "../data/VideoSettings";
import { callApi } from "./ApiUtilities";
import { checkResponse } from "./StatusApi";

export async function CallGetVideoSettings(): Promise<VideoSettings> {
  const response = await callApi<VideoSettings>("/api/videoSettings", "GET");
  console.log(`Get video settings response ${response.status}`);
  return checkResponse<VideoSettings>(response);
}

export async function CallSaveVideoSettings(settings: VideoSettings): Promise<VideoSettings> {
  const response = await callApi<VideoSettings>("/api/videoSettings", "POST", settings);
  console.log(`Save video settings response ${response.status}`);
  return checkResponse<VideoSettings>(response);
}

export async function CallGetLiveStreamDetails(): Promise<LiveStreamDetails> {
  const response = await callApi<LiveStreamDetails>("/api/videosettings/livestream", "GET");
  console.log(`Get LiveStream details response ${response.status}`);
  return checkResponse<LiveStreamDetails>(response);
}
