import React, { SyntheticEvent, useState } from "react";
import { FormatHelper } from "../../api/FormatHelper";
import ContainerCard from "../../common/CardContainer";
import CheckboxInput from "../../common/CheckboxInput";
import MultiSelectInput from "../../common/MultiSelectInput";
import SelectInput, { SelectOption } from "../../common/SelectInput";
import TextInput from "../../common/TextInput";
import { Session, SessionStatuses } from "../../data/Session";

export interface SessionEditProps {
  session: Session;
  onSave: (name: string, status: string, enableParticipantView: boolean, enableParticipantButtons: boolean, askIsInPerson: boolean, dateStart: string, dateEnd: string) => Promise<string>;
  onCancel: () => void;
}

function SessionEdit({ session, onSave, onCancel }: SessionEditProps) {
  const [name, setName] = useState(session.name);
  const [status, setStatus] = useState(session.status);
  const [enableParticipantView, setEnableParticipantView] = useState(session.enableParticipantView);
  const [enableParticipantButtons, setEnableParticipantButtons] = useState(session.enableParticipantButtons);
  const [askIsInPerson, setAskIsInPerson] = useState(session.askIsInPerson);
  const [dateStart, setDateStart] = useState(FormatHelper.getDateFieldValue(session.dateTimeStart));
  const [dateEnd, setDateEnd] = useState(FormatHelper.getDateFieldValue(session.dateTimeEnd));
  const [errors, setErrors] = useState({} as Errors);
  const [saving, setSaving] = useState(false);

  function setDateWrapper(value: string, set: React.Dispatch<React.SetStateAction<string>>) {
    console.log("Set Date Value: " + value);
    console.log("Example vlaue:  " + session.dateTimeStart);
    console.log("Example vlaue:  " + typeof session.dateTimeStart);
    set(value);
    console.log("Example saved value: " + FormatHelper.getDateFromFieldValue(value, session.dateTimeStart));
  }

  interface Errors {
    name?: string;
    status?: string;
    enableParticipantView?: string;
    enableParticipantButtons?: string;
    askIsInPerson?: string;
    dateStart?: string;
    dateEnd?: string;
    onSave?: string;
  }

  function save(event: SyntheticEvent) {
    setSaving(true);
    event.preventDefault();
    onSave(
      name,
      status,
      enableParticipantView,
      enableParticipantButtons,
      askIsInPerson,
      FormatHelper.getDateFromFieldValue(dateStart, session.dateTimeStart),
      FormatHelper.getDateFromFieldValue(dateEnd, session.dateTimeEnd)
    )
      .catch((error: Error) => {
        setErrors({ onSave: error.message });
        setSaving(false);
      })
      .finally(() => {
        setSaving(false);
      });
  }
  function getSessionStatusesList(): SelectOption[] {
    return [
      { value: SessionStatuses.Setup, text: SessionStatuses.Setup },
      { value: SessionStatuses.InSession, text: SessionStatuses.InSession },
      { value: SessionStatuses.Completed, text: SessionStatuses.Completed },
    ];
  }

  return (
    <ContainerCard heading="Session Details">
      <form onSubmit={save}>
        <div className="row mb-2">
          <div className="col">Session Id</div>
          <div className="col">{session.id}</div>
        </div>
        <div className="row">
          <TextInput name="Name" label="Session Name" type="text" placeholder="name" value={name} error={errors.name} onChange={setName} />
        </div>
        <div className="row">
          <SelectInput name="Status" label="Status" value={status} defaultOption={SessionStatuses.Setup} error={errors.status} onChange={setStatus} options={getSessionStatusesList()} />
        </div>
        <div className="row">
          <CheckboxInput name="EnableParticipantView" label="Enable Participant View" checked={enableParticipantView} error={errors.enableParticipantView} onChange={setEnableParticipantView} />
        </div>
        <div className="row">
          <CheckboxInput
            name="EnableParticipantButtons"
            label="Enable Participant Buttons"
            checked={enableParticipantButtons}
            error={errors.enableParticipantButtons}
            onChange={setEnableParticipantButtons}
          />
        </div>
        <div className="row">
          <CheckboxInput name="AskIsInPerson" label="Ask Participants if they are in person during the session" checked={askIsInPerson} error={errors.askIsInPerson} onChange={setAskIsInPerson} />
        </div>
        <div className="row">
          <TextInput name="DateStart" label="Start date" type="date" placeholder="" value={dateStart} error={errors.dateStart} onChange={(value) => setDateWrapper(value, setDateStart)} />
        </div>
        <div className="row">
          <TextInput name="DateEnd" label="End Date" type="date" placeholder="" value={dateEnd} error={errors.dateEnd} onChange={(value) => setDateWrapper(value, setDateEnd)} />
        </div>
        {errors.onSave && (
          <div className="row mt-2 mx-1">
            <div className="alert alert-danger">{errors.onSave}</div>
          </div>
        )}
        <div className="d-flex my-2">
          <div className="px-2 ms-auto">
            <button type="submit" disabled={saving} className="btn btn-primary mx-2">
              {saving ? "Saving..." : "Save"}
            </button>
          </div>
        </div>
      </form>
    </ContainerCard>
  );
}

export default SessionEdit;
