import React, { SyntheticEvent, useContext, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { getMeetinActions, closeMeetingAction, CallUpdateMeetingActionSpeakingStatus } from "../../api/MeetingApi";
import ContainerCard from "../../common/CardContainer";
import { MeetingAction, MeetingActionSpeakingStatuses, MeetingActionStatuses, MeetingActionTypes } from "../../data/MeetinAction";
import { User } from "../../data/User";
import { meetingActionClosed, meetingActionUpdated, updateMeetingActionsAction } from "../../state/actions";
import { SLinkContext } from "../../state/context";
import { UserRoles } from "../../state/state";
import UserNameDisplay from "../meetingcommon/UserNameDisplay";

export interface MeetingActionProps {
  setError: (error: string) => void;
}

function MeetingActionList({ setError }: MeetingActionProps) {
  const { state, dispatch } = useContext(SLinkContext);
  const [loading, setLoading] = useState(true);
  const [userRoles, setUserRoles] = useState(state.user?.userRoles.split(","));
  const [popupActionItem, setPopupActionItem] = useState(null as MeetingAction | null);

  useEffect(() => {
    getMeetinActions()
      .then((list) => {
        dispatch(updateMeetingActionsAction(list));
      })
      .catch((error: Error) => {
        console.error("Error refreshing meeting actions");
      })
      .finally(() => {
        setLoading(false);
      });
    function CallRefresh() {
      console.log("Automated Refresh Meeting Actions List");
      getMeetinActions()
        .then((list) => {
          dispatch(updateMeetingActionsAction(list));
        })
        .catch((error: Error) => {
          console.error("Error refreshing meeting actions");
        });
    }
    let id: NodeJS.Timer;
    id = setInterval(() => {
      CallRefresh();
    }, 2500);
    return () => clearInterval(id);
  }, [dispatch, setError]);

  function updateActionStatus(subId: string, newSpeakingStatus: string) {
    CallUpdateMeetingActionSpeakingStatus(subId, newSpeakingStatus)
      .then((data) => {
        if (data.status === MeetingActionStatuses.Closed) {
          dispatch(meetingActionClosed(data));
        } else {
          dispatch(meetingActionUpdated(data));
        }
      })
      .catch((err) => {
        toast.error(`Updating meeting action ${subId} to speaking status ${newSpeakingStatus} failed with error: ${err.message}`);
      });
  }

  function removeMeetingAction(action: MeetingAction) {
    // call api then call dispatch if successful
    setLoading(true);
    closeMeetingAction(action)
      .then((action) => {
        dispatch(meetingActionClosed(action));
      })
      .catch((error: Error) => {
        setError(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function ClearAllObjections(e: SyntheticEvent) {
    e.preventDefault();
    if (state.meetingActions) {
      state.meetingActions.forEach((a) => {
        if (a.type === MeetingActionTypes.Objection) {
          closeMeetingAction(a).then((a) => {
            dispatch(meetingActionClosed(a));
          });
        }
      });
    }
  }

  function onClickCopyText(a: MeetingAction) {
    navigator.clipboard.writeText(a.text + `\n\n${a.title} ${a.preferredName !== "" ? a.preferredName : a.firstname} ${a.lastname}`);
    toast.info("Copied text to the clipboard", { delay: 1500 });
  }
  function touchItemNote(a: MeetingAction, e: SyntheticEvent) {
    if (popupActionItem?.subId !== a.subId) {
      setPopupActionItem({ ...a } as MeetingAction);
    }
  }

  return (
    <>
      <ContainerCard heading="Point of Order">
        <table className="table table-sm p-0 m-0" width="100%">
          <thead>
            <tr>
              <td className="">Name</td>
              {userRoles?.includes(UserRoles.Presenter) ? <td className="">Action</td> : <></>}
              <td className="text-end"></td>
            </tr>
          </thead>
          <tbody>
            {state.meetingActions.map((a) => {
              if (a.type === MeetingActionTypes.PointOfOrder) {
                return (
                  <tr key={`${a.subId}-${a.speakingStatus}`}>
                    <td onTouchStart={(e) => touchItemNote(a, e)} onClick={(e) => touchItemNote(a, e)}>
                      <UserNameDisplay action={a} />
                    </td>
                    {userRoles?.includes(UserRoles.Presenter) ? (
                      <td>
                        {a.speakingStatus === MeetingActionSpeakingStatuses.InQueue ? (
                          <Button variant="link" className="m-0 p-0" onClickCapture={(e) => updateActionStatus(a.subId, MeetingActionSpeakingStatuses.InQuestionPool)}>
                            Question Pool
                          </Button>
                        ) : a.speakingStatus === MeetingActionSpeakingStatuses.InQuestionPool ? (
                          // <Button variant="link" className="m-0 p-0" onClickCapture={(e) => updateActionStatus(a.subId, MeetingActionSpeakingStatuses.Completed)}>
                          //   Kick out
                          // </Button>
                          <>In Pool</>
                        ) : (
                          <>{a.speakingStatus}</>
                        )}
                      </td>
                    ) : (
                      <td>{a.speakingStatus === MeetingActionSpeakingStatuses.InQuestionPool ? "Question Pool" : ""}</td>
                    )}
                    <td className="text-end">
                      {userRoles?.includes(UserRoles.Presenter) ? (
                        <Button variant="link" className="m-0 p-0" onClickCapture={(e) => removeMeetingAction(a)}>
                          X
                        </Button>
                      ) : (
                        <></>
                      )}
                    </td>
                  </tr>
                );
              }
              return "";
            })}
          </tbody>
        </table>
      </ContainerCard>
      {/* TODO: put it back to ContainerCard and enable a button top right of container card */}
      <div className="card mb-2">
        <h3 className="card-title px-1 py-2 bg-light">
          Objections{" "}
          <button className="float-end btn btn-link" onClick={ClearAllObjections}>
            Clear All
          </button>
        </h3>
        <div className="mt-1">
          <table className="table table-sm p-0 m-0" width="100%">
            <thead>
              <tr>
                <td className="">Name ({state.meetingActions.filter((n) => n.type === MeetingActionTypes.Objection).length})</td>
                <td className="text-end"></td>
              </tr>
            </thead>
            <tbody>
              {state.meetingActions.map((a) => {
                if (a.type === MeetingActionTypes.Objection) {
                  return (
                    <tr key={a.subId} title={`${a.title} ${a.preferredName} ${a.lastname} (${a.parish})`}>
                      <td onTouchStart={(e) => touchItemNote(a, e)} onClick={(e) => touchItemNote(a, e)}>
                        <UserNameDisplay action={a} />
                      </td>
                      <td className="text-end">
                        {userRoles?.includes(UserRoles.Presenter) ? (
                          <Button variant="link" className="m-0 p-0" onClickCapture={(e) => removeMeetingAction(a)}>
                            X
                          </Button>
                        ) : (
                          <></>
                        )}
                      </td>
                    </tr>
                  );
                }
                return "";
              })}
            </tbody>
          </table>
        </div>
      </div>
      <ContainerCard heading="Suggesting Amendments">
        <table className="table table-sm p-0 m-0" width="100%">
          <thead>
            <tr>
              <td className="">Name</td>
              <td className="">Info</td>
              <td className="">{userRoles?.includes(UserRoles.Presenter) ? "Action" : "Status"}</td>
              {userRoles?.includes(UserRoles.Presenter) ? <td className="text-end"></td> : <></>}
            </tr>
          </thead>
          <tbody>
            {state.meetingActions.map((a) => {
              if (a.type === MeetingActionTypes.SuggestAmendment) {
                return (
                  <tr key={`${a.subId}-${a.speakingStatus}`}>
                    <td title={`${a.title} ${a.preferredName} ${a.lastname} (${a.parish})`} onTouchStart={(e) => touchItemNote(a, e)} onClick={(e) => touchItemNote(a, e)}>
                      <UserNameDisplay action={a} />
                    </td>
                    <td onTouchStart={(e) => touchItemNote(a, e)}>
                      {a.text ? (
                        <button className="btn" onClick={() => onClickCopyText(a)}>
                          <img src="/imgs/note.png" width="25" height="25" alt={a.text} title={a.text} />
                        </button>
                      ) : (
                        ""
                      )}
                    </td>
                    {userRoles?.includes(UserRoles.Presenter) ? (
                      <td>
                        {a.speakingStatus === MeetingActionSpeakingStatuses.InQueue ? (
                          <Button variant="link" className="m-0 p-0" onClickCapture={(e) => updateActionStatus(a.subId, MeetingActionSpeakingStatuses.InQuestionPool)}>
                            Question Pool
                          </Button>
                        ) : a.speakingStatus === MeetingActionSpeakingStatuses.InQuestionPool ? (
                          // <Button variant="link" className="m-0 p-0" onClickCapture={(e) => updateActionStatus(a.subId, MeetingActionSpeakingStatuses.Completed)}>
                          //   Kick out
                          // </Button>
                          <>In Pool</>
                        ) : (
                          <>{a.speakingStatus}</>
                        )}
                      </td>
                    ) : (
                      <td>{a.speakingStatus === MeetingActionSpeakingStatuses.InQuestionPool ? "Question Pool" : ""}</td>
                    )}
                    {userRoles?.includes(UserRoles.Presenter) ? (
                      <td className="text-end">
                        <Button variant="link" className="m-0 p-0" onClickCapture={(e) => removeMeetingAction(a)}>
                          X
                        </Button>
                      </td>
                    ) : (
                      <></>
                    )}
                  </tr>
                );
              }
              return "";
            })}
          </tbody>
        </table>
      </ContainerCard>
      <ContainerCard heading="Asking to Speak">
        <table className="table table-sm p-0 m-0" width="100%">
          <thead>
            <tr>
              <td className="">Name</td>
              <td className="">Info</td>
              {userRoles?.includes(UserRoles.Presenter) ? <td className="">Action</td> : <></>}
              <td className="text-end"></td>
            </tr>
          </thead>
          <tbody>
            {state.meetingActions.map((a) => {
              if (a.type === MeetingActionTypes.AskToSpeak) {
                return (
                  <tr key={`${a.subId}-${a.speakingStatus}`}>
                    <td title={`${a.title} ${a.preferredName} ${a.lastname} (${a.parish})`} onTouchStart={(e) => touchItemNote(a, e)} onClick={(e) => touchItemNote(a, e)}>
                      <UserNameDisplay action={a} />
                    </td>
                    <td onTouchStart={(e) => touchItemNote(a, e)}>
                      {a.forAgainst !== "" ? <img src={a.forAgainst === "For" ? "/imgs/yes.png" : "/imgs/no.png"} width="22" height="22" alt={a.forAgainst} title={a.forAgainst} /> : ""}{" "}
                      {a.userId === state.meetingStatus?.currentItem?.moverUserId ? <img src="/imgs/IconLetterM.png" width="22" height="22" alt="Mover of Motion" title="Mover of Motion" /> : <></>}
                      {a.userId === state.meetingStatus?.currentItem?.seconderUserId ? (
                        <img src="/imgs/IconLetterS.png" width="22" height="22" alt="Seconder of Motion" title="Seconder of Motion" />
                      ) : (
                        <></>
                      )}
                      {a.text ? (
                        <button className="btn" onClick={() => onClickCopyText(a)}>
                          <img src="/imgs/note.png" width="25" height="25" alt={a.text} title={a.text} />
                        </button>
                      ) : (
                        ""
                      )}
                    </td>
                    {userRoles?.includes(UserRoles.Presenter) ? (
                      <td>
                        {a.speakingStatus === MeetingActionSpeakingStatuses.InQueue ? (
                          <Button variant="link" className="m-0 p-0" onClickCapture={(e) => updateActionStatus(a.subId, MeetingActionSpeakingStatuses.InQuestionPool)}>
                            Question Pool
                          </Button>
                        ) : a.speakingStatus === MeetingActionSpeakingStatuses.InQuestionPool ? (
                          // <Button variant="link" className="m-0 p-0" onClickCapture={(e) => updateActionStatus(a.subId, MeetingActionSpeakingStatuses.Completed)}>
                          //   Kick out
                          // </Button>
                          <>In Pool</>
                        ) : (
                          <>{a.speakingStatus}</>
                        )}
                      </td>
                    ) : (
                      <td>{a.speakingStatus === MeetingActionSpeakingStatuses.InQuestionPool ? "Question Pool" : ""}</td>
                    )}
                    <td className="text-end">
                      {userRoles?.includes(UserRoles.Presenter) ? (
                        <Button variant="link" className="m-0 p-0" onClickCapture={(e) => removeMeetingAction(a)}>
                          X
                        </Button>
                      ) : (
                        <></>
                      )}
                    </td>
                  </tr>
                );
              }
              return "";
            })}
          </tbody>
        </table>
      </ContainerCard>
      {popupActionItem ? (
        <Modal show={popupActionItem !== null}>
          <Modal.Header>
            <Modal.Title>{popupActionItem.type} Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>{popupActionItem.text}</p>
            <p>
              {popupActionItem.title} {popupActionItem.preferredName ? popupActionItem.preferredName : popupActionItem.firstname} {popupActionItem.lastname}
              <br />
              {popupActionItem.parish}
              <br />
              {popupActionItem.house}
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setPopupActionItem(null)}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      ) : (
        <></>
      )}
    </>
  );
}

export default MeetingActionList;
