import { Dispatch } from "react";
import { User } from "../data/User";
import { loginAction, logoutAction, SLinkActions } from "../state/actions";
import { SLinkState } from "../state/state";
import { ApiStatus, callApi } from "./ApiUtilities";
import { ClearSession } from "./Session";
import { getCookieValue } from "./Utilities";
// const baseUrl = process.env.API_URL + "/auth";

export async function login(username: string, password: string): Promise<User> {
  const response = await callApi<User>("/api/auth", "POST", { username: username, password: password });
  if (response.status === ApiStatus.Ok) {
    return response.data as User;
  }
  throw Error(response.message);
}

export async function initaliseLoggedInUser(stateLoggedInUser: User | null, dispatch: Dispatch<SLinkActions>) {
  try {
    if (hasAuthenticationToken()) {
      console.log("INIT USER:: have token, getting user to confirm");
      const response = await callApi<User>("/api/auth", "GET");
      // if response ok then continue checking user set on state
      if (response.status === ApiStatus.Ok) {
        const user = response.data as User;
        // check if user already set on state
        console.log("INIT USER:: login - get user success, dispatch login as state indicates not logged in");
        dispatch(loginAction(user));
        // if (!stateLoggedInValue) {
        //   console.log("INIT USER:: login - get user success, dispatch login as state indicates not logged in");
        //   dispatch(loginAction(user));
        // } else {
        //   console.log("INIT USER:: already logged in");
        // }
      } else {
        // get user faild so need to check logged out
        // if (stateLoggedInValue) {
        console.log("INIT USER:: logout - get user failed");
        //   dispatch(logoutAction());
        // } else {
        //   console.log("INIT USER:: already logged out");
        // }
      }
    } else {
      console.log("INIT USER:: no token");
      // we do NOT have an auth token, so log out if state suggest we are logged in
      // if (stateLoggedInValue) {
      //   console.log("INIT USER:: logout (as has user in state)");
      //   dispatch(logoutAction());
      // }
    }
  } catch (err) {
    // if (hasAuthenticationToken()) {
    //   dispatch(logoutAction());
    // }
  }
}

export function hasAuthenticationToken(): boolean {
  const token = getCookieValue("SLinkPC");
  if (token.length === 36) {
    return true;
  }
  return false;
}

export async function getUser(): Promise<User> {
  const response = await callApi<User>("/api/auth", "GET");
  if (response.status === ApiStatus.Ok) {
    return response.data as User;
  }
  throw Error(response.message);
}

export async function CallLogout(dispatch: Dispatch<SLinkActions>) {
  await callApi("/api/auth", "DELETE");
  // clear session storage of local settings
  ClearSession();
  // TODO: remove JWT from session storage
  dispatch(logoutAction());
}
