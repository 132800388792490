import React, { SyntheticEvent, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { User } from "../../data/User";
import TextInput from "../../common/TextInput";
import { Button } from "react-bootstrap";

export interface UserListProps {
  list: User[];
  onEdit: (session: User) => void;
}

function UserList({ list, onEdit }: UserListProps) {
  const [searchText, setSearchText] = useState("");
  const [users, setUsers] = useState([] as User[]);
  list.filter(d => d.house !== "" || d.house !== null);
  list.sort((a,b) => a.house > b.house ?1:-1 || a.lastname > b.lastname ?-1:1 || a.firstname > b.firstname ?-1:1);

  useEffect(() => {
    setUsers(list);
  }, [list]);

  function search() {
    if (searchText == "") {
      setUsers(list);
    } else {
      const str = searchText.toLowerCase();
      const filteredUsers = list.filter((u) => {
        return u.firstname.toLowerCase().includes(str) || u.lastname.toLowerCase().includes(str) || u.preferredName.toLowerCase().includes(str) || u.email.includes(str);
      });
      setUsers(filteredUsers);
    }
  }

  return (
    <>
      <div className="row">
        <div className="col">
          <TextInput
            label="Search"
            name="Search"
            error=""
            value={searchText}
            onEnter={() => search()}
            onChange={setSearchText}
            type="text"
            helpText="Search by part of name or email"
            placeholder="name or email"
          />
        </div>
        <div className="col-6 col-md-6 col-sm-12 pt-4">
          <button className="btn btn-primary m-2" onClick={search}>
            Search
          </button>
          <button
            className="btn btn-light m-2"
            onClick={() => {
              setSearchText("");
              search();
            }}
          >
            Clear
          </button>
          <span className="m-2 pt-2">
            Showing {users.length} of {list.length} users
          </span>
        </div>
      </div>
      <table className="table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>House</th>
            <th>Roles</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {users.map((item) => {
            return (
              <tr key={item.userId} title={item.alternateId}>
                <td>
                  {item.enabled ? (
                    <>
                      {item.lastname}, {item.preferredName == "" ? <>{item.firstname}</> : <>{item.preferredName}</>}
                    </>
                  ) : (
                    <del>
                      {item.lastname}, {item.preferredName == "" ? <>{item.firstname}</> : <>{item.preferredName}</>}
                    </del>
                  )}
                </td>
                <td>{item.enabled ? <>{item.email}</> : <del>{item.email}</del>}</td>
                <td>{item.house}</td>
                <td>{item.userRoles}</td>
                <td>
                  <button className="btn btn-light" onClick={() => onEdit(item)}>
                    Edit
                  </button>
                </td>
              </tr>
            );
          })}
          {list.length === 0 ? (
            <tr>
              <td colSpan={3}>No Users found</td>
            </tr>
          ) : (
            <></>
          )}
        </tbody>
      </table>
    </>
  );
}

UserList.propTypes = {
  list: PropTypes.array.isRequired,
};

export default UserList;
