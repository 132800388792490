import React from "react";
import { Item, ItemStatuses, ItemTypes } from "../../data/ItemOfBusiness";

interface BusinessItemCardProps {
  item: Item | null;
  children: any;
}

function BusinessItemCard({ item, children }: BusinessItemCardProps) {
  function GetItemHeading() {
    if (item) {
      var type = item.type === ItemTypes.Amendment && item.status === ItemStatuses.New ? "Marked-up text under debate" : item.type;
      return `${item.code} - ${item.heading} (${type} - ${item.status})`;
    }
    return "No Current Item of Business";
  }

  return (
    <>
      <div className="card mb-2">
        <h3 className="card-title px-1 py-2 bg-light">{GetItemHeading()}</h3>
        {children}
      </div>
    </>
  );
}

export default BusinessItemCard;
