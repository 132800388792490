import { Item } from "../data/ItemOfBusiness";
import { ApiStatus, callApi } from "./ApiUtilities";

export async function getItems(): Promise<Item[]> {
  const response = await callApi<Item[]>("/api/items", "GET");
  console.log(`Get Items response ${response.status}`);
  if (response.status === ApiStatus.Ok) {
    return response.data as Item[];
  } else if (response.status === ApiStatus.Unauthorised) {
    throw Error(`Not logged in, try refreshing page (${response.message}`);
  } else {
    throw Error("An Unknown API error occured. Response " + response.status + "- " + response.message);
  }
}

export async function saveItem(item: Item): Promise<Item> {
  const response = await callApi<Item>("/api/items", "POST", item);
  if (response.status === ApiStatus.Ok) {
    return response.data as Item;
  } else if (response.status === ApiStatus.Unauthorised) {
    throw Error(`Not logged in, try refreshing page (${response.message}`);
  } else {
    throw Error("An Unknown API error occured. Response " + response.status + "- " + response.message);
  }
}

export async function deleteItem(item: Item): Promise<void> {
  const response = await callApi<Item>(`/api/items/${encodeURI(item.subId)}`, "DELETE", item);
  if (response.status === ApiStatus.Ok) {
    return;
  } else if (response.status === ApiStatus.Unauthorised) {
    throw Error(`Not logged in, try refreshing page (${response.message}`);
  } else {
    throw Error("An Unknown API error occured. Response " + response.status + "- " + response.message);
  }
}
